import axios from 'axios'

const DEV_HOST = "http://localhost:8000";
const PRODUCTION_HOST = "https://admin.pnuvspace.com";
// export const API_HOST = process.env.NODE_ENV === 'development' ? DEV_HOST : PRODUCTION_HOST;
export const API_HOST = PRODUCTION_HOST;

const axiosInstance = axios.create({
	baseURL: API_HOST,
	headers: {
		Authorization: localStorage.getItem('access_token')
			? 'JWT ' + localStorage.getItem('access_token')
			: null,
		'Content-Type': 'application/json',
		accept: 'application/json',
	},
});


export default axiosInstance;

export const updateInstanceWithToken = (token) => {
    if(token && window) {
        window.localStorage.setItem("access_token", token);
    }

    axiosInstance.interceptors.request.use(function (config) {
        const accessToken = window.localStorage.getItem("access_token");
        config.headers.Authorization =  accessToken ? `Bearer ${accessToken}` : '';
        return config;
      });
}

export  const axiosFormInstance = axios.create({
	baseURL: API_HOST,
	headers: {
		Authorization: localStorage.getItem('access_token')
			? 'JWT ' + localStorage.getItem('access_token')
			: null,
        "Content-Type": "multipart/form-data",
		accept: 'application/json',
	},
}); 