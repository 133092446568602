import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import axiosInstance, { API_HOST } from "../../../axios/axiosInstance";
import PageHelmet from "../../../component/common/Helmet";
import BreadcrumbGray from "../../../elements/common/BreadcrumbGray";
import { useDelData, useGetUserId } from "../../../hooks/useApi";
import Breadcrumb from "../../../elements/common/Breadcrumb";
function BoardDetail() {
  const [content, setContent] = useState([]);
  const { id } = useParams();
  const [userId, isAdmin, writer] = useGetUserId();

  async function getContent() {
    // const { data } = await axios.get(`${API_HOST}/board/notices/${id}/`);
    const data = await axiosInstance.get(`/board/notices/${id}/`);
    console.log(data);
    setContent(data.data);
  }
  // console.log(isAdmin);
  const [contents, setContents, delContent] = useDelData("/board/notices", id, "/notice");
  useEffect(() => {
    getContent();
  }, []);

  console.log(content);
  //const navigate = useNavigate();
  return (
    <React.Fragment>
      <PageHelmet pageTitle="Notice" />
      <Breadcrumb title="공지사항" />

      <div className="board-container pt--50 pb--120">
        <div className="boardDetail-wrap">
          {content && (
            <table className="boardDetail">
              <thead>
                <tr>
                  <td colSpan={4}>
                    <strong>{content.title}</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={3}>작성자 : {content.user}</td>
                  <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>작성일 :{content.created_at?.slice(2, 10)}</td>
                </tr>
                <tr>
                  <td>
                    첨부파일{" "}
                    {content.upfile ? (
                      <a href={content.upfile} target="_blank" rel="noopener noreferrer" className="downfile">
                        다운
                      </a>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <div className="ql-editor" dangerouslySetInnerHTML={{ __html: content.content }} />
                  </td>
                </tr>
                {/* <tr>
                  <td style={{ backgroundColor: "e9e9e9" }}>
                    이전글 안녕하세요
                  </td>
                </tr>
                <tr>
                  <td>다음글 다음글은 없습니다</td>
                </tr> */}
              </tbody>
            </table>
          )}
          <div className="boardBtn-wrap">
            <div className="boardBtn-wrap">
              {isAdmin === "관리자" ? (
                <>
                  {" "}
                  <Link to={`/notice/update/${id}`}>
                    <button type="button" className="list">
                      수정
                    </button>
                  </Link>
                  <button type="button" className="del" onClick={delContent}>
                    삭제
                  </button>
                </>
              ) : (
                <></>
              )}
              <Link to="/notice">
                <button type="button" className="list">
                  목록
                </button>
              </Link>
            </div>
          </div>
        </div>
        {/* <>    <h2>공지사항</h2></>
    
         {content.map((con, key) => {
          {
            return (
              <table className="boardDetail" key={key}>
                <thead>
                  <tr>
                    <td>SUBJECT</td>
                    <td>
                      <strong>{con.title}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>WRITER</td>
                    <td>shoes shop</td>
                  </tr>
                  <tr>
                    <td>DATE</td>
                    <td style={{ color: "grey" }}>{con.regdate}</td>
                    <td>VIEW</td>
                    <td style={{ color: "grey" }}>8207</td>
                  </tr>
                </thead>
                <tbody>
                  <td
                    colSpan={"4"}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <p style={{ marginTop: "20px", marginBottom: "20px" }}>
                      {con.content}
                    </p>
                  </td>
                </tbody> 
              </table>
            );
          }
        })}

        <button
          onClick={() => {
            navigate(-1);
          }}
          className="listBox"
        >
          LIST
        </button>*/}
      </div>
    </React.Fragment>
  );
}

export default BoardDetail;
