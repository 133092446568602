import React from "react";
import PageHelmet from "../../component/common/Helmet";
import Breadcrumb from "../common/Breadcrumb";
import ScrollTop from "../common/ScrollTop";

const EquipmentUtilize = () => {
  return (
    <React.Fragment>
      <PageHelmet pageTitle="Eduction Details" />
      <Breadcrumb title={"장비 이용 시"} />

      {/* Start Portfolio Details */}
      <div className="rn-portfolio-details ptb--100 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-details">
                <div className="row row--35 mb--50">
                  <div className="col-lg-12">
                    <div className="inner plr_sm--10">
                      <div className="section-title ">
                        <h3 className="title">장비 이용 방법</h3>
                        <div className="portfolio-details mt--50 mt_md--40 mt_sm--40 ">
                          <div className="inner">
                            <div className="section-title">
                              <div className="text-center">
                              <img
                                src={require("../../../public/assets/images/about/equip-use.jpg")}
                               className="ptb--10"
                              />
                              </div>
                              <p className="description">
                                회원가입 - 사용하고자 하는 장비 선정 - 해당 장비
                                장비교육 이수 (온라인 교육시 : 상단메뉴바 온라인
                                장비교육 진행-설문지 작성) - 관리자 확인 후
                                장비예약 권한 부여 - 상단메뉴바 장비예약 진행 -
                                장비예약 양식 작성 - 관리자 승인 - 해당건 QR코드
                                생성 - 현장 확인 (입장시 장비예약건의 QR코드가
                                확인되어야 입장이 가능합니다.)
                              </p>
                              <div>
                                <h5 className="mb--5 ">장비 이용 완료 시</h5>
                              </div>
                              <p className="description">
                                로그인 - 마이페이지 - 회원 장비 및 공간 대여
                                정보 - 이전예약건 관리 - 최근 장비 예약건 결과
                                사진 업로드 - 완료<br/> (마지막 예약건의 완료 사진이
                                등록되어야 새로운 장비 예약 가능)
                              </p>
                              <div>
                                <h5 className="mb--5">장비 예약 안내</h5>
                              </div>
                              <p className="description">
                                -장비당 1일 1인 최대 연속2타임 (1타임=30분) 예약 가능
                                <br />
                                (예 : 사용자는 3D프린터, 레이저가공기를 각각
                                2타임 예약 가능)
                                <br /> -장비 사용 후 완료사진 미제출시 이후 해당
                                장비 예약 불가
                                <br /> -예약건 QR코드 지참시 입장가능
                                <br /> -대량 출력 또는 생산이 필요할시
                                관리자에게 전화문의
                              </p>
                              <div>
                                <h5 className="mb--5">현장 공구 사용 시</h5>
                              </div>
                              <p className="description">
                                사용하고자 하는 공구 선정 - 로그인 - 마이페이지
                                - 나의 QR코드 현장제시 - 공구대여건 생성 -
                                공구대여 완료 - 대여한 공구는 V-SPACE내에서
                                사용가능하며, 외부 반출은 관리자 허가 후
                                가능합니다. -운영시간내 미반납시 안내 문자가
                                발송됩니다.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Details */}

      {/* Start Back To Top */}
       <ScrollTop/>
      {/* End Back To Top */}
    </React.Fragment>
  );
};

export default EquipmentUtilize;
