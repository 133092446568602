import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp, FiMail, FiSmile ,FiSpeaker} from "react-icons/fi";
import ScrollTop from "./common/ScrollTop";



const ServiceList = [
    {
        icon: <FiCast />,
        title: 'About',
        description: 
        <div>
        V-SPACE란 부산대학교 기계관에 위치한 부산의 대표적인 메이커스페이스입니다. 
        <br/><br/> 남녀노소 누구나 아이디어 생성부터 시제품제작까지 한 공간에서 진행할 수 있습니다. 
        <br/><br/>서로의 아이디어와 능력이 융합되어 한단계 발전된 제품을 제작할 수 있는 메이커스들을 위한 공간입니다.
        <br/><br/>- 브레인스토밍을 위한 공간 구축 
        <br/> Another Campus, C-SPACE, 화상강의실
        <br/>- 시제품 제작을 위한 장비와 공간 구축
        <br/>프린터실, 웰컴라운지, 3D프린터실, 목공실, 미니팩토리, 금속제작실, 도색실
        <br/>- 창의적 발상을 위한 신기술 체험
        <br/> 로봇실, AI라운지, Another Campus
        <br/>- 창업자와 기창업자들을 위한 공간
        <br/>창업지원실, C-SPACE, A-SPACE
        <br/>- 제품 홍보 및 촬영을 위한 장비와 공간
        <br/>촬영실
        <br/>- 매주 다양한 장비 워크샵 진행
        <br/>- 100종 이상의 장비 보윺
        </div>
    },
    {
        icon: <FiSmile />,
        title: '개인을 위한 창작공간',
        description:  
        <div>
        뚜렷한 목표가 없어도, 개인의 취미 생활을 위한 창작도 모두 가능한 공간
        <br/><br/>
        처음 시작하는 단계의 메이커를 위한 기초교육 및 눈높이 멘토링 상시 진행
        <br/><br/><br/><br/>
        <br/><br/>- 장비 및 시설 지원
        <br/>- 기초 장비 교육 및 제작에 관련된 교육 진행
        <br/>- 제품제작에 필요한 눈높이 멘토링
        </div>
    },
    {
        icon: <FiLayers />,
        title: '올타임 메이커스를 위한 공간',
        description: 
        <div>
         한 단계 발전된 창작 활동을 하고 싶은 분들을 위한 심화 교육 및 워크샵 진행
        <br/><br/>다양한 장비를 배우고 활용하며 제작의 다성 및 전문성 증진
        <br/><br/><br/><br/>
        <br/><br/>- 장비 및 시설 지원
        <br/>- 워크샵 참가 및 제품 제작 지원
        <br/>&nbsp;* 워크샵 내용: 3D 모델링 & 설계, 2D, 3D 프린터, 아두이노, 드론 제작 등 매 월 진행
        <br/>- 장비를 활용한 심화 교육 진행
        <br/>- 2가지 이상의 장비를 바탕으로 한 융합제작교육 진행
        </div>
    },
    {
        icon: <FiMonitor />,
        title: '단체를 위한 창작 공간',
        description: 
        <div>
         제작과정의 규모가 개인에서 단체로 발전하는 단계, 개인에 머물러있던 아이디어를 제품과 서비스로 발전시키는 공간
        <br/><br/>그룹단위의 교육 활동을 통해 아이디어 구체화 및 제작을 지원하며, 단체 멘토링 및 시제품 제작 제공
        <br/><br/>- 아이디어 구상과 시제품 제작을 위한 그룹 구성 지원
        <br/>- 여름 / 겨울방학 공대학장 인증프로그램 참여 지원
        <br/>- 브레인스토밍을 위한 공간 제공
        <br/>- 워크샵 및 제품 제작 지원
        <br/>&nbsp;* 워크샵 내용: 3D 모델링 & 설계, 2D, 3D 프린터, 아두이노, 드론 제작 등 매 월 진행
        </div>
    },
    {
        icon: <FiSpeaker/>,
        title: '예비 창업자를 위한 창작 공간',
        description: 
        <div className="dcText">
        IoT, 기계기술, 제조 분야 등 창업에 관심있는 V-SPACE 회원에게 벤처 창업부터 성장까지 모두 지원
        <br/><br/><br/><br/>
        <br/><br/>- 장비 및 시설 지원
        <br/>- 시제품 제작에서 완제품 출시까지 다방면의 실질적 교육 및 지원
        <br/>- 분야별 전문가 1:1 맞춤형 멘토링 지원
        <br/>- 공모전 지원 및 투자 프레젠테이션 지원
        <br/>- 창업지원실 운영 및 창업 관련 교육 진행
        </div>
    },
    {
        icon: <FiUsers />,
        title: '기창업자를 위한 공간',
        description: 
        <div>
        제품 양산 연계 지원 등 산업체와의 협력을 통한 파트너쉽 형성 제공
        <br/><br/>분야별 창업자 매칭 및 전문가 교육 진행
        <br/><br/><br/><br/>
        <br/><br/>- 장비 및 시설 지원
        <br/>- 워크샵 및 제품 제작 지원
        <br/>- 제품 양산 연계 지원
        <br/>- 산학협력단 및 창업진흥원을 통한 지원 사업 매칭 진행
        <br/>- 뷴야별 전문가 1:1 맞춤형 멘토링 지원
        </div>
    },
]


class VspaceAbout extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='VSPACE-ABOUT' />
                {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'V-SPACE 소개'} />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--100 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <span className="subtitle">Our Awesome Story</span>
                                    <h2>V-SPACE</h2>
                                    <h4>함께 모여 만드는 즐거움이 있는 공간! 브레인스토밍부터 창업까지 누구나</h4>
                                </div>
                            </div>
                        </div>

                        <div className="row service-main-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    {/* <a className="text-center" href="/service-details"> */}
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <div className="description">{val.description}</div>
                                            </div>
                                        </div>
                                    {/* </a> */}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Back To Top */}
                 <ScrollTop/>
                {/* End Back To Top */}
        

            </React.Fragment>
        )
    }
}
export default VspaceAbout;