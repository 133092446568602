import React from 'react'

const TabSelect = ({items, onItemSelect, selectedItem}) => {
   {console.log(items)}
    return (
        <div className='timetables'>
            {items.map(item => (
                <button 
                    key={item.id} 
                    className={`tab-btn ${selectedItem?.name === item.name && "activeTab"}`}
                    // style={{...styles.tab, ...(selectedItem.value === item.value && styles.activeTab)}}
                    onClick={() => onItemSelect(item)}
                >
                  {item.name} 
                </button>
            ))}
        </div>
    )
}


export default TabSelect