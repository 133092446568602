import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";
import { Outlet } from "react-router-dom";
import Footer from "../footer/Footer";
import { useRecoilValue } from "recoil";
import { isLoginSelector } from "../../recoil/User";
import Accordion from "react-bootstrap/Accordion";
import axiosInstance from "../../axios/axiosInstance";
import { FaUserCircle } from "react-icons/fa";
import {ReactComponent as Vspacelogo} from "../../../public/assets/images/logo/V-SPACE.svg"
function Navbar(props) {
  const isLogin = useRecoilValue(isLoginSelector);
  const [mypageData, setMypageData] = useState("");
  // const login = window.localStorage.getItem("access_token");
  async function getMypageData() {
  const accesstoken = window.localStorage.getItem("access_token");
    await axiosInstance.get("/users/auth/check/", {
      headers: {
        Authorization: `Baerer ${accesstoken}`
      }
    }).then((res)=>{
      if(res.status===200){setMypageData(res.data);}}
    ).catch((error) =>{
      console.log(error)
      localStorage.clear();
      window.alert("자동 로그아웃되었습니다.")
      window.location = "/";
    })
  }

  const menuTrigger = () => {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  };
  const CLoseMenuTrigger = () => {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  };
  const handleLogout = () => {
    localStorage.clear();
    window.location = "/";
  };

  const { logo, color = "default-color" } = props;
  let logoUrl;
  if (logo === "light") {
    logoUrl = <img src="/assets/images/logo/logo-light.png" alt="Vspace" />;
  } else if (logo === "dark") {
    logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="Vspace" />;
  } else if (logo === "symbol-dark") {
    logoUrl = <img src="/assets/images/logo/logo-symbol-dark.png" alt="Vspace" />;
  } else if (logo === "symbol-light") {
    logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="Vspace" />;
  } else {
    logoUrl = <img src="/assets/images/logo/vspace.png" alt="Vspace-Logo" style={{width: 150}}/>;
  }

  useEffect(() => {
    const Submenu = () => {
      var elements = document.querySelectorAll(".has-droupdown > a");
      for (var i in elements) {
        if (elements.hasOwnProperty(i)) {
          elements[i].onclick = function () {
            this.parentElement.querySelector(".submenu").classList.toggle("active");
            this.classList.toggle("open");
          };
        }
      }
    };
    Submenu();
    // getMypageData();
  }, []);

  useEffect(() => {
    if(isLogin){
      getMypageData();
    }
  }, [isLogin]);
  return (
    <>
      <div className={`header-area header--fixed formobile-menu header--transparent`}>
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <a href="/">{logoUrl}</a>
            </div>
          </div>
          <div className="header-middle">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li className="has-droupdown">
                  <Link to="/vspaceAbout">V-SPACE</Link>
                  <ul className="submenu">
                    <li>
                      <Link to="/vspaceAbout">V-SPACE 소개</Link>
                    </li>
                    <li>
                      <Link to="/vspaceTeam">V-SPACE 팀</Link>
                    </li>
                    <li>
                      <Link to="/map">오시는 길</Link>
                    </li>
                  </ul>
                </li>
                <li className="has-droupdown">
                  <Link to="/vspaceFloor">공간안내</Link>
                  <ul className="submenu">
                    <li>
                      <Link to="/vspaceFloor">층별안내</Link>
                    </li>
                    <Accordion defaultActiveKey={["0", "1"]}>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>장비소개</Accordion.Header>
                        <Accordion.Body>
                          <li>
                            <Link to="/equipstatus">장비현황</Link>
                          </li>
                          <li>
                            <Link to="/tools">공구현황</Link>
                          </li>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header style={{ backgroundColor: "white" }}>시설이용안내</Accordion.Header>
                        <Accordion.Body>
                          <li>
                            <Link to="/equip-utilize">장비이용시</Link>
                          </li>
                          <li>
                            <Link to="/facility-utilize">공간이용시</Link>
                          </li>
                          <li>
                            <Link to="/program-utilize">프로그램예약시</Link>
                          </li>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </ul>
                </li>
                <li className="has-droupdown">
                  <Link to="/online-equip">신청 및 예약</Link>
                  <ul className="submenu">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>프로그램 신청</Accordion.Header>
                        <Accordion.Body>
                          <li>
                            <Link to="/online-equip">온라인 장비 교육</Link>
                          </li>
                          <li>
                            <Link to="/education">교육프로그램</Link>
                          </li>
                          <li>
                            <Link to="/event-participate">행사 참가</Link>
                          </li>
                          <li>
                            <Link to="/mentoring">멘토링 및 제작지원</Link>
                          </li>
                          <li>
                            <Link to="/tour">투어</Link>
                          </li>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <li>
                      <Link to="/space">시설 예약</Link>
                    </li>
                    <li>
                      <Link to="/equipments">장비 예약</Link>
                    </li>
                  </ul>
                </li>
                <li className="has-droupdown">
                  <Link to="/notice">커뮤니티</Link>
                  <ul className="submenu">
                    <li>
                      <Link to="/notice">공지사항</Link>
                    </li>
                    <li>
                      <Link to="/reference">자료실</Link>
                    </li>
                    <li>
                      <Link to="/gallery">작품갤러리</Link>
                    </li>
                    <li>
                      <Link to="/qnaboard">Q&A</Link>
                    </li>
                  </ul>
                </li>

                {isLogin ? (
                  <div className="header-login d-lg-none">
                    <li>
                      <Link>
                        {/* <span style={{marginRight: 5,fontSize:15}}>님</span> */}
                        <FaUserCircle size="28" />
                        <span style={{ marginLeft: 5 }}>{mypageData?.user_id}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/mypage">
                        <span>마이페이지</span>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={handleLogout}>
                        <span>로그아웃</span>
                      </Link>
                    </li>
                  </div>
                ) : (
                  <div className="header-login d-lg-none">
                    <li>
                      <Link to="/login">로그인</Link>
                    </li>
                    <li>
                      <Link to="/registerconfirm">회원가입</Link>
                    </li>
                  </div>
                )}
              </ul>
            </nav>
          </div>

          <div className="header-right">
            {isLogin ? (
              <div className="header_login d-lg-flex d-lg-block d-md-none d-sm-none d-none">
                <li>
                  <Link>
                    <span style={{ marginRight: 5 }}>{mypageData?.user_id}</span>
                    {/* <span style={{marginRight: 5,fontSize:15}}>님</span> */}
                    <FaUserCircle size="28" />
                  </Link>
                </li>
                <li>
                  <Link to="/mypage">
                    <span style={{ marginRight: 5 }}>마이페이지</span>
                  </Link>
                </li>
                <li>
                  <Link onClick={handleLogout}>로그아웃</Link>
                </li>
              </div>
            ) : (
              <div className="header_login d-lg-flex d-lg-block d-md-none d-sm-none d-none">
                <li>
                  <Link to="/login">로그인</Link>
                </li>
                <li>
                  <Link to="/registerconfirm">회원가입</Link>
                </li>
              </div>
            )}
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
              <span onClick={menuTrigger} className="menutrigger text-white">
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </div>
      <section>
        <Outlet />
        <Footer />
      </section>
    </>
  );
}

export default Navbar;
