import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleRight } from "react-icons/fa";
                                // setCurrentPage, currentPage
export default function Pagination({totalPosts, postsPerPage, paginate, currentPage}) {

    const pages = [];
    for(let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++){
        pages.push(i);
    }
    console.log(pages);

    return (
        <div className="rn-pagination text-center mt--40">
            <ul className="page-list">
                {/* 
                 {
                    pages.map((page,index) =>{
                      return 
                      <button key={index} onClick={()=>{setCurrentPage(page)}}
                       className={page === currentPage? 'active' : ''} >
                      {page}</button>
                    })
                 }
                */}
                {
                    pages.map((page,index) =>{
                        return <li key={index} onClick={()=>{paginate(page)}}
                        className={page === currentPage? 'active' : ''} >
                        <Link to="#">{page}</Link></li>
                    }
                    )
                }
            </ul>
        </div>

        // css .pagination button.active{background: yellow}
    )
}