import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiChevronUp} from "react-icons/fi";
import useResize from "../hooks/useResize";
import ScrollTop from "../elements/common/ScrollTop";




const VspaceTeam = () =>{ 

    const windowWidth = useResize();
        return(
            <React.Fragment>
                <PageHelmet pageTitle='VSPACE-ABOUT' />
                {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'V-SPACE 팀'}  />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--0 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* {windowWidth >500 ?<div className="section-title text-center mb--30" >
                                    <img src={require("../../public/assets/images/about/vspaceteam.png")}/>
                                </div>: */}
                                
                                <div className="section-title text-center mb--30 mt--30" >
                                    <img src={require("../../public/assets/images/about/vspaceteam-mobile.png")}/>
                                </div>
                               {/*  } */}
                            </div>
                        </div>

                        <div className="row service-main-wrapper">
                        
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Back To Top */}
              <ScrollTop/>
                {/* End Back To Top */}
                


            </React.Fragment>
        )
    }

export default VspaceTeam;
