import React, { Fragment, useEffect, useState } from "react";
import SliderOne from "../component/slider/SliderOne";
import PortfolioSlide from "../elements/PortfolioSlide";
import Helmet from "../component/common/Helmet";
import ServiceOne from "../elements/service/ServiceOne";
import { FaFacebook } from "react-icons/fa";
import Brand from "../elements/common/Brand";
import axios from "axios";
import { Link } from "react-router-dom";
import { useGetListDatas } from "../hooks/useApi";
import ScrollTop from "../elements/common/ScrollTop";
import bgImg from ".././../public/assets/images/bg/mainbanner3.jpeg";
import icon1 from ".././../public/assets/images/about/mainicon1.png";
import icon2 from ".././../public/assets/images/about/mainicon2.png";
import icon3 from ".././../public/assets/images/about/mainicon3.png";
import Iframe from "react-iframe";
import useResize from "../hooks/useResize";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";


const MainDemo = () => {
  const [instaImg, setInstaImg] = useState([]);
  const [ScrollY, setScrollY] = useState(0);
  const [BtnStatus, setBtnStatus] = useState(false); // 버튼 상태
  const [hoveredBox, setHoveredBox] = useState(null);
  const [noticeModal, setNoticeModal] = useState(false);
  const [noticeImg, setNoticeImg] = useState(null);

  const data = useGetListDatas("/board/notices");

  useEffect(() => {
    axios.get("https://feeds.behold.so/kvakV87yNIwFXWaKDQy6").then((res) => {
      setInstaImg(res.data);
    });
    
  }, []);

  useEffect(() => {
    axios.get("https://admin.pnuvspace.com/infomation/").then((res) => {
        if (res.data) {
          setNoticeImg(res.data[0].image);
          setNoticeModal(true);
        }
      }
    );
    
  }, []);

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(calculateHeight(window.innerWidth));

  function calculateHeight(width) {
    // 원하는 비율로 height를 계산 (예: 16:9 비율)
    const aspectRatio = 1;
    if (width >= 1500) {
      // 990 이상인 경우, window.innerWidth의 절반을 사용
      return (width = 500);
    }
    if (width >= 990 && width < 1500) {
      // 990 이상인 경우, window.innerWidth의 절반을 사용
      return width * 0.415;
    }
    if (width <= 990) {
      // 990 이상인 경우, window.innerWidth의 절반을 사용
      return width * 0.99;
    }
    return width * aspectRatio;
  }
  const windowWidth = useResize();

  // useEffect(() => {
  //   // 창 크기가 변경될 때 마다 width와 height 업데이트
  //   const handleResize = () => {
  //     const newWidth = window.innerWidth;
  //     setWidth(newWidth);
  //     setHeight(calculateHeight(newWidth));
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  //   const itemsPerRow = 3;
  //   const rows = Math.ceil(instaImg.length / itemsPerRow);
  //  console.log(data)

  const mainStyle = {
    backgroundImage: `url(${bgImg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
  };

  const boxStyle = (boxNumber) => {
    const isHovered = hoveredBox === boxNumber;
    return {
      backgroundColor: "#F9F9F9",
      height: "330px",
      borderRadius: "20px",
      padding: "20px",
      position: "relative",
      transform: isHovered ? "scale(1.1)" : "scale(1)",
      transition: "transform 0.3s ease",
    };
  };

  const handleMouseEnter = (boxNumber) => {
    setHoveredBox(boxNumber);
  };

  const handleMouseLeave = () => {
    setHoveredBox(null);
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0, // 맨 위로 스크롤
      behavior: "smooth", // 부드러운 스크롤 효과 사용
    });
  };
  return (
    <Fragment>
      <Helmet pageTitle="Main Demo" />

      <div className="main-container">
        {/* main banner  */}
        <div className="main-section-01" style={mainStyle}>
          <div className="slider-wrapper">
            <SliderOne />
          </div>

          {/* 장비예약, 공간예약, 온라인장비교육 */}
          <div className="service-wrapper service-white">
            <ServiceOne />
          </div>
        </div>

        {/* about v-space */}
        {/* <div className="portfolio-related-work pt--90 pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="mainboard-container col-lg-12 col-md-12 col-12">
                <div className="section-title text-center">
                  <span className="color-title subtitle">About V-SPACE</span>
                  <p className="title-des">
                    함께 모여 만드는 즐거움이 있는 공간!
                  </p>

                  <div className="container plr--80 plr_sm--30 plr_md--30 ">
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt--30">
                        <Link to="/vspaceAbout" onClick={handleScrollToTop}>
                          <div
                            style={boxStyle(1)}
                            onMouseEnter={() => handleMouseEnter(1)}
                            onMouseLeave={handleMouseLeave}
                          >
                            <div>
                              <h2
                                style={{
                                  marginTop: "10px",
                                  marginBottom: 0,
                                  fontWeight: 800,
                                }}
                              >
                                About
                              </h2>
                              <p>부산의 대표적인 메이커스페이스</p>
                            </div>
                            <img
                              src={icon1}
                              style={{ height: "150px", marginTop: "30px" }}
                            />
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt--30 ">
                        <Link to="/vspaceAbout" onClick={handleScrollToTop}>
                          <div
                            style={boxStyle(2)}
                            onMouseEnter={() => handleMouseEnter(2)}
                            onMouseLeave={handleMouseLeave}
                          >
                            <div>
                              <h2
                                style={{ marginTop: "10px", marginBottom: 0 }}
                              >
                                For
                              </h2>
                              <p>개인을 위한 창작공간</p>
                            </div>
                            <img
                              src={icon2}
                              style={{ height: "150px", marginTop: "30px" }}
                            />
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt--30">
                        <Link to="/vspaceAbout" onClick={handleScrollToTop}>
                          <div
                            style={boxStyle(3)}
                            onMouseEnter={() => handleMouseEnter(3)}
                            onMouseLeave={handleMouseLeave}
                          >
                            <div>
                              <h2
                                style={{ marginTop: "10px", marginBottom: 0 }}
                              >
                                Space
                              </h2>
                              <p>올타임 메이커스를 위한 공간</p>
                            </div>
                            <img
                              src={icon3}
                              style={{ height: "150px", marginTop: "30px" }}
                            />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* board, SNS */}
        <div className="portfolio-related-work ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="mainboard-container col-lg-6 col-md-12 col-12">
                <div className="section-title text-center">
                  <span className="color-title subtitle">PNU V-SPACE</span>
                  <p className="title-des">
                    V-SPACE의 새로운 정보를 빠르게 알아보세요.
                  </p>
                  {/* <h2 className="title">Boards</h2> */}
                  <div className="announcement">
                    <div className="announcement-title d-flex justify-content-between align-items-center">
                      <h4 style={{ fontWeight: 900, margin: 0 }}>공지사항</h4>
                      <Link to="/notice">
                        <span style={{ fontWeight: 700 }}>+ 더보기</span>
                      </Link>
                    </div>

                    {data &&
                      data?.slice(0, 5).map((notice, index) => {
                        return (
                          <div
                            className="d-flex justify-content-between flex-row"
                            key={index}
                          >
                            <div
                              style={{
                                flexShrink: 1,
                                overflow: "hidden",
                                flexWrap: "nowrap",
                                height: "33px",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <Link to={`/notice/detail/${notice.id}`}>
                                <span style={{}}>{notice.title}</span>
                              </Link>
                            </div>
                            <div style={{ flexShrink: 0 }}>
                              <span>{notice.created_at?.slice(0, 10)}</span>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>

              <div className="mainboard-container col-lg-6 col-md-12 col-12 ">
                <div className="section-title text-center">
                  <span className="color-title subtitle">V-SPACE Facebook</span>{" "}
                  {/*  Instagram */}
                  <p className="title-des">
                    페이스북으로 소식을 확인해 보세요.
                  </p>
                  {/* <h2 className="title">SNS</h2> */}
                  <div className="related-work text-center mt--30">
                <div className="thumb">
                   {windowWidth >450 ?    
                
                      <Iframe
                        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100041825067083&tabs=timeline&width=450&height=500&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId=609190078054826"
                        width="450"
                        height="400"
                        style="border:none;overflow:hidden"
                        scrolling="no"
                        frameborder="0"
                        allowfullscreen="true"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      ></Iframe>:
                      <Iframe
                        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100041825067083&tabs=timeline&width=300&height=300&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId=756060493203294"
                        width="300"
                        height="300"
                        style="border:none;overflow:hidden"
                        scrolling="no"
                        frameborder="0"
                        allowfullscreen="true"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      ></Iframe>} 
                    </div>
                    {/* <div
                      className="thumb"
                      style={{
                        width: `${width > 1500 ? 390 : width > 991 ? width / 3.1 : width / 1.3}px`,
                        height: `${height / 1.25}px`,
                      }}
                    >
                      <div className="scrollable-container">
                        {instaImg.map((insta, index) => (
                          <div key={index} className="mt--15 insta-wrap">
                            <a href={insta.permalink} target="_blank">
                              <img src={insta.mediaUrl} alt={`Image ${index + 1}`} className="img-fluid instagram-image" />
                            </a>
                          </div>
                        ))}
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 보유장비 */}
        <PortfolioSlide />

        {/* 부산대학교 기계공학부, 중소벤처기업부, 부산광역시 */}
        <div className="rn-brand-area pb--35  bg_color--1">
          <div className="row">
            <div className="col-lg-12 mt--10">
              <Brand />
            </div>
          </div>
        </div>

        {/* End Brand Area */}
        <ScrollTop />
        {/* Start Back To Top */}
      </div>
      <div className="first-open">
      <Modal show={noticeModal} className="open-modal">
            <Modal.Body>
              <img src={noticeImg}/>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>setNoticeModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          </div>
    </Fragment>
  );
};

export default MainDemo;
