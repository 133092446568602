import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import PageHelmet from "../../../component/common/Helmet";
import { API_HOST } from "../../../axios/axiosInstance";
import useResize from "../../../hooks/useResize";
import { useGetUserId } from "../../../hooks/useApi";
import Pagination from "../../../elements/blog/Pagination";
import Search from "../../../elements/blog/Search";
import BreadcrumbGray from "../../../elements/common/BreadcrumbGray";
import { isLoginSelector } from "../../../recoil/User";
import { useRecoilValue } from "recoil";
import ScrollTop from "../../../elements/common/ScrollTop";
import Breadcrumb from "../../../elements/common/Breadcrumb";

function Notice() {
  const location = useLocation();
  const base_url = `${API_HOST}/board/notices/`;
  const isLogin = useRecoilValue(isLoginSelector);
  const [notices, setNotices] = useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const [baseurl, setbaseurl] = useState(base_url);
  const [currentpage, setcurrentpage] = useState(1);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [loading, setLoading] = useState(true);
  const perpage = 20;
  const [userId, isAdmin, writer] = useGetUserId();
  const windowWidth = useResize();
  const [search, setSearch] = useState("");

  function changeurl(event) {
    setbaseurl(event);
  }
  async function getNotices() {
    setLoading(true);
    const { data } = await axios.get(baseurl);
    setNotices(data.results);
    setTotalCount(data.count);
    setNext(data.next);
    setPrevious(data.previous);
    setLoading(false);
  }
  useEffect(() => {
    getNotices(baseurl);
  }, [baseurl]);

  return (
    <React.Fragment>
      <PageHelmet pageTitle="Notice" />
      <Breadcrumb title="공지사항" />
      <div className="board-container pt--50 pb--120">
        <div className="board-wrap">
          {isAdmin === "관리자" ? (
            <div className="boardwrite">
              <Link to="/notice/insert">
                <button className="boardwrite-btn" type="button">
                  글쓰기
                </button>
              </Link>
            </div>
          ) : (
            <></>
          )}
          {windowWidth > 768 ? (
            <>
              <table className="board">
                <thead>
                  <tr>
                    <td>
                      <strong>번호</strong>
                    </td>
                    <td>
                      <strong>제목</strong>
                    </td>
                    <td>
                      <strong>작성자</strong>
                    </td>
                    <td>
                      <strong>등록일</strong>
                    </td>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={5}>Loading...</td>
                    </tr>
                  ) : (
                    <>
                      {notices && notices.length ? (
                        notices.map((notice, key) => {
                          return (
                            <tr key={key}>
                              <td>
                                {notice.row_number}
                                {/* {totalcount - perpage * (currentpage - 1) - key} */}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                <Link to={`detail/${notice.id}`}>{notice.title}</Link>
                              </td>
                              <td>admin</td>
                              {/* <td>
                              {notice.upfile ? (
                                <span>
                                  <FaPaperclip />
                                </span>
                              ) : (
                                ""
                              )}
                            </td> */}
                              <td>{notice.created_at.slice(2, 10)}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={5}>등록된 글이 없습니다</td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
              <div className="boardBottom">
                <Pagination
                  notices={notices}
                  previous={previous}
                  base_url={base_url}
                  changeurl={changeurl}
                  setcurrentpage={setcurrentpage}
                  currentpage={currentpage}
                  totalcount={totalcount}
                  perpage={perpage}
                  next={next}
                />
                <Search baseurl={baseurl} setNotices={setNotices} setTotalCount={setTotalCount} setNext={setNext} setPrevious={setPrevious} onChange={setSearch} />
              </div>
            </>
          ) : (
            <>
              <table className="board">
                <thead>
                  <tr>
                    <td>
                      <strong>번호</strong>
                    </td>
                    <td>
                      <strong>제목</strong>
                    </td>
                    <td>
                      <strong>작성자</strong>
                    </td>
                    <td>
                      <strong>등록일</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {notices && notices.length > 0 ? (
                    notices.map((notice, key) => {
                      return (
                        <tr key={key}>
                          <td>{totalcount - perpage * (currentpage - 1) - key}</td>
                          <td style={{ textAlign: "left" }}>
                            <Link to={`detail/${notice.id}`}>{notice.title}</Link>
                          </td>
                          <td>admin</td>
                          <td>{notice.created_at.slice(5, 10)}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5}>등록된 글이 없습니다</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="boardBottom">
                <div className="d-colum w-100">
                  <div className="bottompagination">
                    <Pagination
                      notices={notices}
                      previous={previous}
                      base_url={base_url}
                      changeurl={changeurl}
                      setcurrentpage={setcurrentpage}
                      currentpage={currentpage}
                      totalcount={totalcount}
                      perpage={perpage}
                      next={next}
                    />
                  </div>
                  <div className="searchSBox">
                    <Search baseurl={baseurl} setNotices={setNotices} setTotalCount={setTotalCount} setNext={setNext} setPrevious={setPrevious} onChange={setSearch} />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <ScrollTop />
      </div>
    </React.Fragment>
  );
}

export default Notice;
