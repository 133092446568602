import React, { Component } from "react";
import { FiClipboard, FiCalendar, FiBookmark } from "react-icons/fi";
const ServiceList = [
  {
    icon: <FiCalendar />,
    title: "장비예약",
    linkurl: "/equipments",
  },
  {
    icon: <FiClipboard />,
    title: "공간예약",
    linkurl: "/space",
    // description: 'Passages there are many variations variations  of of Lorem Ipsum available, but the majority have suffered.'
  },
  {
    icon: <FiBookmark />,
    title: "온라인장비교육",
    linkurl: "/online-equip",
    // description: 'Variations There are many variations  of passages of Lorem Ipsum available, but the majority have suffered.'
  },
];
class ServiceOne extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="d-flex justify-content-around service-reservation">
          {ServiceList.map((val, i) => (
            <div className="service service__style--1" key={i}>
              <a href={val.linkurl}>
                <div className="content">
                  <h4 className="title">{val.title}</h4>
                  {/* <p>{val.description}</p> */}
                </div>
                <div className="icon">{val.icon}</div>
              </a>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceOne;
