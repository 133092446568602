import React, { useEffect } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollTop from "../elements/common/ScrollTop";

const Map = () => {
  useEffect(() => {
    const daum = window.daum;
    new daum.roughmap.Lander({
      timestamp: "1687621550128",
      key: "2fb4v",
      mapWidth: "100%",
      mapHeight: "280",
    }).render();
  }, []);

  return (
    <>
      <PageHelmet pageTitle="Kakao Map" />

      {/* Start Breadcrump Area */}
      <Breadcrumb title={"오시는 길"} />
      {/* End Breadcrump Area */}
      <div className="map-container ptb--100 bg_color--1">
        <div className="row wrapper plr--80 plr_sm--10 plr_md--30">
     
          <div className="col-lg-6 col-sm-12  map-left">
            <div
              id="daumRoughmapContainer1687621550128"
              className="root_daum_roughmap root_daum_roughmap_landing"
              style={{ width: "95%", marginBottom: 20 }}
            ></div>
          </div>
          <div className="col-lg-6 col-sm-12 ">
            <h3>주소</h3>
            <div className="map-right">
            <p className="addressp">부산대학교 V-Space</p>
            <p className="addressp">부산광역시 금정구 부산대학로 63번길 2</p>
            <p className="addressp">부산대학교 기계관(303동) 208호 V-SPACE</p>
            <p style={{marginTop: 20}}>T. 051-510-3261</p> 
            <p>F. 051-510-0787</p>
            </div>
          </div>
          <div className="col-lg-12 mt--20">
            <h4>대중교통안내</h4>
            <h5>지하철 이용시</h5>
            <p>- 1호선 부산대학역 3번 출구로 나와 도보이동(약 10~15분 소요)</p>
            <h5>버스 이용시</h5>
            <p>- 부산대학교 정문 하차</p>
            <p>일반 : 16, 29, 49, 51, 80, 100, 131, 144, 301, 100-1, 121, 8(갑분마을.관찰사.구포역) 148-1, 183, 183(심야)</p>
            <p>급행 : 1002(심야), 1008, 1008(심야)</p>
            <p>마을 : 금정구7(교내 운영)</p>
            <br/>
            <h4>교내주차안내</h4>
            <h5>V-SPACE 이용자들의 주차는 부산대학교 주차규정을 준수합니다.</h5>
            <h5>주차요금</h5>
            <p>- 최초 30분까지 1000원, 이후 매 10분마다 400원씩 가산</p>
            <p>- 2시간 초과시 매 10분당 500원 가산</p>
            <h5>무료주차가능차량</h5>
            <p>- 학교공무수행차량</p>
            <p>- 영업용 차량, 쓰레기 수거 차량</p>
            <p>- 지체 장애인 학생 차량</p>
            <p>- 학교의 공식행사 참석 차량</p>
            <p>- 민원 증명서 발급 차량</p>
            <p>- 안론사 취재차량(언론사 로고 표시)</p>
            <p>- 20분 이내 출입차량</p>
            <p>(단, 입차시 사용한 문과 동일한 문으로 출차하는 경우에 적용)</p>
            <h5>주차료할인차량</h5>
            <p>- 초빙강사 및 공동연구 수행차량</p>
            <p>- 학회, 세미나 등 참석차량</p>
            <p>- 주차할인 요금</p>
            <p>1일 4시간까지 2,000원, 4시간 초과 6시간까지 3,000원</p>
            <p>6시간 초과 8시간까지 5,000원, 8시간 초과시 정상주차료 징수</p>
            <p>- 경차(배기량 1,000cc미만)는 정상요금의 50% 감면</p>
            {/* <table>
              <thead>
              <tr>
                <th>버스종류</th>
                <th>타는곳</th>
                <th>운행시간</th>
                <th>운행간격</th>
                <th>승차요금</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>금정7번 마을버스(유료)</td>
                <td>지하철 부산대역 1번 또는 3번 출입구 앞</td>
                <td>06:00 ~ 20:00</td>
                <td>5분 (단, 08:20~10:00의 등교 시간에는 수시출발)</td>
                <td>현금승차 750원 (교통카드 이용시 700원)</td>
              </tr>
              </tbody>
            </table> */}
         </div>
        </div>
        {/* Start Back To Top */}
         <ScrollTop/>
        {/* End Back To Top */}
      </div>
    </>
  );
};

export default Map;
