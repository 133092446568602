import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Registerconfirm = () => {
  const navigate = useNavigate();
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [useCheck, setUseCheck] = useState(false);

  const registerHandleForm = async(e) => {
    e.preventDefault()
    if (!privacyCheck) {
      alert("개인정보취급방침에 동의해주실 바랍니다.");
      return null
    } else if (!useCheck) {
      alert("회원가입약관에 동의해주실 바랍니다.");
    } else {
      navigate("/register");
    }
  };
  return (
    <div className="registerconfirm">
      <div className="registerconfirm-container">
        <h2>회원가입신청</h2>
        <form className="contract" method="post" onSubmit={registerHandleForm}>
          <h3 className="contract-title">개인정보취급방침</h3>
          <div className="privacyStatement">
            <p>
              :::: V-SPACE 개인정보취급방침 ::::
              <br /> <br />
              V-SPACE은(는) 이용자들의 개인정보보호를 매우 중요시하며, 이용자가
              회사의 서비스를 이용함과 동시에 온라인상에서 회사에 제공한
              개인정보가 보호 받을 수 있도록 최선을 다하고 있습니다.<br/> 이에
              V-SPACE는 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및
              정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할
              관련 법규상의 개인정보보호 규정 및 정보통신부가 제정한
              개인정보보호지침을 준수하고 있습니다. <br/>V-SPACE는 개인정보
              취급방침을 통하여 이용자들이 제공하는 개인정보가 어떠한 용도와
              방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가
              취해지고 있는지 알려 드립니다. <br/><br/>V-SPACE는 개인정보 취급방침을
              홈페이지 첫 화면에 공개함으로써 이용자들이 언제나 용이하게 보실 수
              있도록 조치하고 있습니다. <br/><br/>회사의 개인정보 취급방침은 정부의 법률
              및 지침 변경이나 회사의 내부 방침 변경 등으로 인하여 수시로 변경될
              수 있고, 이에 따른 개인정보 취급방침의 지속적인 개선을 위하여
              필요한 절차를 정하고 있습니다.<br/> 그리고 개인정보 취급방침을 개정하는
              경우나 개인정보 취급방침 변경될 경우 V-SPACE 홈페이지의 첫페이지의
              개인정보취급방침을 통해 고지하고 있습니다.<br/> 이용자들께서는 사이트
              방문 시 수시로 확인하시기 바랍니다. <br/><br/>1. 개인정보의 수집목적 및
              이용목적<br/><br/> "개인정보"라 함은 생존하는 개인에 관한 정보로서 당해
              정보에 포함되어 있는 성명, 주민등록번호 등의 사항에 의하여 당해
              개인을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수
              없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)를
              말합니다. <br/><br/>대부분의 서비스는 별도의 사용자 등록이 없이 언제든지
              사용할 수 있습니다.<br/> 그러나 V-SPACE는 회원서비스를 통하여
              이용자들에게 맞춤식 서비스를 비롯한 보다 더 향상된 양질의 서비스를
              제공하기 위하여 이용자 개인의 정보를 수집하고 있습니다.<br/><br/> V-SPACE는
              이용자의 사전 동의 없이는 이용자의 개인 정보를 공개하지 않으며,
              수집된 정보는 아래와같이 이용하고 있습니다. <br/><br/>첫째, 이용자들이
              제공한 개인정보를 바탕으로 보다 더 유용한 서비스를 개발할 수
              있습니다. <br/>V-SPACE는 신규 서비스개발이나 컨텐츠의 확충 시에 기존
              이용자들이 회사에 제공한 개인정보를 바탕으로 개발해야 할 서비스의
              우선 순위를 보다 더 효율적으로 정하고, V-SPACE는 이용자들이 필요로
              할 컨텐츠를 합리적으로 선택하여 제공할 수 있습니다.<br/><br/> 둘째, 수집하는
              개인정보 항목과 수집 및 이용목적은 다음과 같습니다. <br/>- 성명 ,
              아이디, 비밀번호, 주민등록번호 : 회원제 서비스 이용에 따른 본인
              확인 절차에 이용 <br/>- 이메일주소, 전화번호 : 고지사항 전달, 불만처리
              등을 위한 원활한 의사소통 경로의 확보, 새로운 서비스 및 신상품이나
              이벤트 정보 등의 안내 <br/>- 은행계좌정보, 신용카드정보 : 서비스 및 부가
              서비스 이용에 대한 요금 결제 <br/>- 주소, 전화번호 : 청구서, 물품배송시
              정확한 배송지의 확보<br/> - 기타 선택항목 : 개인맞춤 서비스를 제공하기
              위한 자료 <br/>- IP Address, : 불량회원의 부정 이용 방지와 비인가 사용
              방지 <br/><br/>2. 수집하는 개인정보 항목 및 수집방법 V-SPACE는 이용자들이
              회원서비스를 이용하기 위해 회원으로 가입하실 때 서비스 제공을 위한
              필수적인 정보들을 온라인상에서 입력 받고 있습니다.<br/><br/> 회원 가입 시에
              받는 필수적인 정보는 이름, 주민등록번호, 이메일 주소 등입니다.<br/>
              또한 양질의 서비스 제공을 위하여 이용자들이 선택적으로 입력할 수
              있는 사항으로서 전화번호 등을 입력 받고 있습니다. <br/><br/>또한 V-SPACE
              홈페이지 내에서의 설문조사나 이벤트 행사 시 통계분석이나 경품제공
              등을 위해 선별적으로 개인정보 입력을 요청할 수 있습니다.<br/> 그러나,
              이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및
              민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록,
              건강상태 및 성생활 등)는 수집하지 않으며 부득이하게 수집해야 할
              경우 이용자들의 사전동의를 반드시 구할 것입니다. <br/>그리고, 어떤
              경우에라도 입력하신 정보를 이용자들에게 사전에 밝힌 목적 이외에
              다른 목적으로는사용하지 않으며 외부로 유출하지 않습니다. <br/><br/>3.
              수집하는 개인정보의 보유 및 이용기간 <br/><br/>이용자가 V-SPACE 홈페이지
              회원으로서 회사에 제공하는 서비스를 이용하는 동안 V-SPACE는
              이용자들의 개인정보를 계속적으로 보유하며 서비스 제공 등을 위해
              이용합니다. <br/>다만, 홈페이지 하단 개인정보 취급방침의 "6. 이용자
              자신의 개인정보관리(열람,정정,삭제 등)에 관한 사항" 에서 설명한
              절차와 방법에 따라 회원 본인이 직접 삭제하거나 수정한 정보,
              가입해지를 요청한 경우에는 재생할 수 없는 방법에 의하여
              디스크에서완전히 삭제하며 추후 열람이나 이용이 불가능한 상태로
              처리됩니다. <br/><br/>그리고 "1. 수집하는 개인정보 항목 및 수집방법"에서와
              같이 일시적인 목적 (설문조사, 이벤트,본인확인 등)으로 입력 받은
              개인정보는 그 목적이 달성된 이후에는 동일한 방법으로 사후 재생이
              불가능한 상태로 처리됩니다.<br/><br/> 귀하의 개인정보는 다음과 같이
              개인정보의 수집목적 또는 제공받은 목적이 달성되면 파기하는 것을
              원칙으로 합니다. <br/>다만, V-SPACE는 불량 회원의 부정한 이용의 재발을
              방지하기 위해, 이용계약 해지일로부터 1년간 해당 회원의
              주민등록번호를 보유할 수 있습니다. <br/>그리고 상법, 전자상거래
              등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할
              필요가 있는 경우 V-SPACE는 관계법령에서 정한 일정한 기간 동안
              회원정보를 보관합니다. <br/>이 경우 V-SPACE는 V-SPACE는 보관하는 정보를
              그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.<br/><br/> - 계약
              또는 청약철회 등에 관한 기록 : 5년 <br/>- 대금결제 및 재화 등의 공급에
              관한 기록 : 5년<br/> - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년<br/><br/>
              V-SPACE는 귀중한 회원의 개인정보를 안전하게 처리하며, 유출의
              방지를 위하여 다음과 같은 방법을통하여 개인정보를 파기합니다. <br/><br/>-
              종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
              파기합니다. <br/>- 전자적 파일 형태로 저장된 개인정보는 기록을 재생할
              수 없는 기술적 방법을 사용하여 삭제합니다
            </p>
          </div>
          <div className="agreebox">
            <input
              type="checkbox"
              className="chk"
              onChange={(e) => setPrivacyCheck(e.target.checked)}
            />
             위 개인정보취급방침에 동의합니다.
          </div>
          <div>
            <h3 className="contract-title">회원가입약관</h3>
            <div className="privacyStatement">
              <p>
                :::: V-SPACE 회원가입약관 ::::
                <br /> <br />
                제 1 장 총칙<br /><br /> 제1조 (목적)<br /><br />
                이 약관은 전기통신사업법령 및
                정보통신망이용촉진 등에 관한 법령에 의하여 V-SPACE(이하
                "V-SPACE"이라 합니다.)가 제공하는 서비스(이하 "서비스"라
                합니다.)를 이용함에 있어 이용조건 및 절차에 관한 기본적인 사항을
                규정하는 것을 목적으로 합니다.<br /><br /> 제2조 (약관의 효력 및 변경) <br /><br />
                1. 이 약관은 그 내용을 V-SPACE이 서비스 화면을 통해 게시하고 이용자가
                이에 동의를 함으로써 효력을 발생합니다.<br/>2. V-SPACE은 약관의
                규제등에 관한 법률,전기통신사업 법령,정보통신망 이용 촉진등에
                관한 법률 등 관련법을 위배하지 않는 범위에서 이 약관을 정할 수
                있으며 임의로 약관을 개정할 수 있습니다. 그리고 변경된 약관에
                이의가 있는 회원은 V-SPACE이 정한 양식에 따라 탈퇴할 수
                있습니다.<br/>3. V-SPACE이 약관을 개정할 경우에는 적용일자 및 개정
                사유를 명시하여 현행 약관과 함께 서비스 초기화면에 그 적용일자
                7일 이전부터 적용일자 전일까지 공지하며, 제1항과 같은 방법으로
                효력이 발생합니다. 단, 구 약관에 동의한 이용자가 약관 개정
                적용일 이후에도 계속 V-SPACE의 서비스를 이용하면 개정 약관에
                대해 동의한 것으로 간주합니다.<br/> 4. 약관을 개정할 경우에는 그
                개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그
                이전에 이미 체결된 계약에 대해서는 개정전의 약관조항이 그대로
                적용됩니다. 다만 이미 계약을 체결한 이용자가 3항과 같이 개정약관
                조항의 적용을 받기 원한다는 동의를 표시한 경우에는 자동적으로
                개정약관 조항이 적용됩니다.<br/><br/> 제3조 (약관외 준칙) <br/><br/>이 약관에서
                정하지 않은 사항과 이 약관의 해석에 관하여는 정부가 제정한
                전기통신기본법,전기통신 사업법, 정보통신망 이용촉진 등에 관한
                법률 및 기타 관계법령 또는 상관례에 따릅니다. <br/><br/>제4조 (용어의
                정의)<br/><br/> 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br/> 1.
                V-SPACE : V-SPACE이 운영하는 온라인 서비스 혹은 사업자 <br/>2. 이용자
                : V-SPACE이 제공하는 서비스를 받는 회원 및 비회원 <br/>3. 포플러 :
                V-SPACE에 개인정보를 제공하여 이용계약을 체결하고 이용자
                아이디(ID)를 부여 받은 개인으로, V-SPACE이 제공하는 정보를
                지속적으로 제공 받으며 V-SPACE의 서비스 (판매, 커뮤니케이션
                서비스, 컨텐츠 서비스 등 V-SPACE의 모든 서비스 포함) 및
                V-SPACE이 운영하는 이러한 서비스를 제공하는 인터넷사이트를
                계속적으로 이용할 수 있는 자<br/> 또한, V-SPACE의 정보공유 서비스를
                이용한 수익 목적을 가진자 <br/>4. 페블러: V-SPACE에 개인정보를
                제공하여 이용계약을 체결하고 이용자 아이디(ID)를 부여 받은
                개인으로, V-SPACE이 제공하는 정보를 지속적으로 제공 받으며
                V-SPACE의 서비스 (판매, 커뮤니케이션 서비스, 컨텐츠 서비스 등
                V-SPACE의 모든 서비스 포함) 및 V-SPACE이 운영하는 이러한
                서비스를 제공하는 인터넷사이트를 계속적으로 이용할 수 있는
                자로서 정보 공유를 목적으로하는 단체 및 개인을 대변하는 자로서
                정보 제공의 책임이 있는자 <br/>5. 비회원 : 회원 가입하지 않고
                V-SPACE이 제공하는 서비스를 이용하는 자 <br/>6. 아이디(ID) : 회원의
                식별과 서비스 이용을 위하여 회원이 정하고 V-SPACE이 승인하는
                문자 또는 숫자의 조합 <br/>7. 비밀번호 : 회원이 부여받은 아이디(ID)와
                일치되는 회원임을 확인하고 회원의 비밀보호를 위해 회원 자신이
                정한 문자 또는 숫자의 조합 <br/>8. 해지 : V-SPACE 또는 회원이 서비스
                개통 후 이용 계약을 해약하는 것 <br/><br/>제 2 장 회원가입과 정보변경 및
                회원 탈퇴 <br/><br/>제5조 (회원가입) <br/><br/>1. 이용자는 V-SPACE가 정한 가입
                양식에 따라 회원정보를 기입한 후 온라인 사용 약관에 동의한다는
                의사표시를 함으로써 회원가입을 신청합니다.<br/> 2. V-SPACE은 제1항과
                같이 회원 가입을 신청한 이용자 중 다음 각 호에 해당하지 않는 한
                회원으로 등록합니다. <br/>1) 가입 신청자가 이전에 회원 자격을 상실한
                적이있는 경우 (단, 회원자격 상실 후 3 V-SPACE에 의해 회원 재가입
                승낙을 얻은 경우에는 예외) <br/>2) 등록 내용에 허위, 기재누락, 오기가
                있는 경우 <br/>3) 기타 회원으로 등록하는 것이 V-SPACE의 서비스에
                현저히 지장이 있다고 판단되는 경 우 <br/><br/>제6조 (회원정보의 변경)<br/><br/>
                회원은 이용 신청시 기재한 회원정보가 변경되었을 경우 온라인으로
                직접 수정해야 하며, 관리자에게 수정을 요구할 수 있습니다. 이때
                변경하지 않은 정보로 인해 발생되는 문제의 책임은 회원에게
                있습니다.<br/><br/>제7조 (회원 탈퇴 및 자격 상실 등)<br/><br/> 1. 회원은 탈퇴를
                희망하는 경우 탈퇴를 요청할 수 있으며 V-SPACE은 정한 양식에
                의하여 회 원 탈퇴를 처리합니다.<br/>2. 회원이 다음 각호의 사유에
                해당하는 경우, V-SPACE은 회원자격을 제한 및 정지시킬 수
                있습니다. <br/>1) 가입 신청시에 허위 내용을 등록한 경우 <br/>2) 다른
                사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자
                거래질서를 위협하는 경우 <br/>3) 제공되는 정보를 변경하는 등
                V-SPACE의 운영을 방해한 경우 <br/>4) 법령과 이 약관이 금지하거나
                미풍양속에 반하는 행위를 하는 경우 <br/>3.V-SPACE이 회원자격을 제한,
                정지시킨 후 그 사유가 시정되지 아니하는 경우 V-SPACE은
                회원자격을 상실시킬 수 있습니다. <br/>4.V-SPACE의 판단 착오로 인해
                회원 자격이 상실될 경우 소명할 기회를 제공하며 재가입 및
                자격상실로 인한 회원의 피해는 V-SPACE가 책임진다. <br/><br/>제 3 장 서비스
                제공 및 이용 제8조 (서비스 이용시간 및 중단)<br/><br/>1. 서비스의 이용은
                V-SPACE이 업무상 또는 기술상 특별한 경우를 제외하고는 연중무휴
                1일 24시간 동안 가능한 것을 원칙으로 합니다. 다만, 운영상의
                목적으로 V-SPACE이 정한 기간에는 서비스가 일시 중지될 수
                있습니다. 이런 경우 V-SPACE은 사전 또는 사후 이를 공지합니다. <br/>2.
                V-SPACE은 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수
                있습니다. <br/>가. 서비스용 설비의 보수 등 공사로 인한 부득이한 경우
                <br/>나. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를
                중지했을 경우 <br/>다. 설비 장애 또는 이용 폭주 등으로 서비스 이용에
                지장이 있는 경우 <br/>라. 기타 불가항력적 사유가 있는 경우<br/><br/>
                제9조(이용자에 대한 통지)<br/><br/>1. V-SPACE는 회원이 서비스 이용 중
                필요하다고 인정 되는 다양한 정보에 대해서 전자메일이나 기타 방법
                등으로 회원에게 정보를 제공할 수 있습니다. <br/>2. V-SPACE는 불특정
                다수 회원에 대한 통지의 경우 1주일 이상 게시판에 게시함으로서
                개별 통지에 갈음할 수 있습니다. <br/><br/>제10조 (이용자의 ID 및
                비밀번호에 대한 의무)<br/><br/>1. V-SPACE이 관계법령 및
                "개인정보보호정책"에 의해서 그 책임을 지는 경우를 제외하고,
                자신의 ID와 비밀번호에 관한 관리책임은 각 이용자에게 있습니다.<br/>
                2. 이용자는 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는
                안됩니다. <br/>3. 이용자는 자신의 ID 및 비밀번호를 도난당하거나
                제3자가 사용하고 있음을 인지한 경우에는 바로 V-SPACE에 통보하고
                V-SPACE의 안내가 있는 경우에는 그에 따라야 합니다. <br/><br/>제11조 온라인
                홍보 및 홍보비용<br/><br/>1. V-SPACE는 서비스상에 기재되는 내용을
                검수하며 광고 및 이벤트의 실질적인 이행 및 제공 되는 정보의
                책임은 정보 제공자(페블러)에 있으며 V-SPACE는 홍보 내용에 대한
                책임은지 지 않습니다.<br/>2. V-SPACE는 서비스상에 게재되어 있는
                광고주의 광고 활동에 회원이 참여하거나 거래의 결 과로서 발생하는
                모든 손실 또는 손해에 대해 책임을 지지 않습니다.<br/>3. 등록한 광고
                진행시 특별한 사유없이 페블러는 광고를 중단 또는 과금방식 금액을
                변경할 수 없습니다. 이는 이미 광고에 참여한 포플러의 과금의
                안장성을 보장해야하는 V-SPACE의 의무이며 연결 URL 변경으로
                승인되지 않은 노출 페이지가 발생할 수 있기 때문입니다. <br/>4.
                페블러는 온라인 광고 등록시 광고를 실시간 모니터 해야하며
                포플러의 악의성 댓글 또는 낚시성 글로 인해 이미 발생한 과금에
                대해 V-SPACE는 책임지지 않습니다. <br/>5. 이벤트 생성 및 진행시
                페블러는 성실한 이벤트 진행을 해야하며 이미 승인이 이루어진 이
                벤트 내용과 상반된 내용을 진행 또는 이벤트 결과 불이행으로
                V-SPACE 이벤트 서비스의 신뢰도에 영향을 미칠 경우 V-SPACE는
                법적으로 대응 및 피해보상을 요구합니다. <br/>6. 포플러는 온라인
                홍보로 발생한 수익이 3만원 이상시 출금신청을 통해 계좌이체
                입금을 받 을 수 있으며 3만원 이하는 현금으로 인식하지 않으며
                V-SPACE 는 어떠한 경우라도 지급할 의무가 없습니다. <br/>7. 페블러는
                충전이 이루어진 금액에 대해서는 재인출 할 수 없습니다. V-SPACE의
                충전 금액 및 사용금액은 부가세를 제외한 금액이므로 세금계산서가
                필요할 경 우 결제금액의 10%를 추가 지급해야 한다. <br/>8. V-SPACE는
                과금 시스템의 정확하고 원할한 운영을 책임지며 페블러의 광고비와
                포플러의 수익금 관리에 최선을 다한다. <br/>9. 포플러는 3만원 이상의
                수익 발생시 1만원 단위로 출금 신청 할 수 있으며 V-SPACE는 매 주
                월요일과 목요일 두차레 일괄 송금한다. <br/>( 송금시 법으로 정한
                제세공과금을 제외한 나머지 금액을 지급한다.) <br/><br/>제12조 (회원
                게시물)<br/><br/>1. 게시물에 대한 저작권을 포함한 모든 권리 및 책임은
                이를 게시한 회원에게 있으며 V-SPACE는 V-SPACE의 서비스내의
                게재권을 갖습니다. <br/>2. V-SPACE는 이용자가 서비스에 게재한 정보의
                정확성 및 신뢰도 등 내용에 대해서는 책임을 지지 않습니다. <br/>3.
                V-SPACE은 회원이 서비스에 게시, 게재하거나 서비스를 통해 전송,
                수신한 내용물 및 그 결과에 대하여 책임지지 않으며, 아래 사항에
                해당하는 경우 게시물을 임의로 삭제할 수 있습니다. <br/>가. 타인을
                비방하거나, 프라이버시를 침해하거나, 명예를 손상시키는 내용인
                경우 <br/>나. 공공질서 및 미풍양속에 위반되는 내용인 경우 <br/>다.
                서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있는 경우 <br/>라.
                범죄행위에 관련된다고 판단되는 내용인 경우 <br/>마. V-SPACE의
                지적재산권, 타인의 지적재산권 등 기타 권리를 침해하는 내용인
                경우 <br/>바. 특정제품의 광고 등 기타 상용목적으로 게시되는 내용인
                경우 <br/>사. 입력된 정보를 무단 개조하는 경우 <br/>아. 타회원의 회원 ID,
                비밀번호를 도용하는 경우 <br/>자. 기타 관계법령에 위반된다고 판단되는
                경우 <br/><br/>제13조 (지적소유권의 보호)<br/><br/>1. V-SPACE가 작성한 저작물에
                대한 저작권 및 기타 지적재산권은 V-SPACE에 귀속합니다. <br/>2. 회원은
                서비스를 이용하여 얻은 정보를 가공,판매하는 행위 등 서비스에
                게재된 자료를 상업적으로 사용할 수 없습니다. <br/><br/>제 4 장
                개인정보보호<br/><br/>V-SPACE의 개인정보보호정책은 정보통신망이용촉진
                등에 관한 법률 제16조와 제18조의 규정을 준수합니다. <br/><br/>제14조
                (V-SPACE의 보안관리)<br/><br/>V-SPACE는 이용자의 개인정보가 분실, 도난,
                누출, 변조 또는 훼손되지 않도록 안전성 확보에 필요한 조치를
                강구합니다. 단, 회원이 게시판이나 email, 채팅 등 온라인 상에서
                자발적으로 제공하는 개인정보는 다른 사람이 수집하여 사용할
                가능성이 있으며 이러한 위험은 개인에게 책임이 있고 V-SPACE에는
                책임이 없습니다.<br/><br/>제15조 (V-SPACE의 회원정보 사용에 대한 동의)<br/><br/>
                1.V-SPACE은 V-SPACE가 제공하는 서비스를 이용하는 이용자를 대상으로
                해당 서비스의 양적, 질적 향상을 위하여 이용자의 개인 식별이
                가능한 개인정보를 이용자의 동의를 받아 이를 수집, 이용할 수
                있습니다.<br/>2. V-SPACE이 수집하는 개인정보는 서비스의 제공에
                필요한 최소한으로 하되, 필요한 경우 보다 더 자세한 정보를 요구할
                수 있습니다. <br/>3. 이용자가 제공한 개인정보는 이용자의 동의 없이
                제3자에게 누설하거나 제공하지 않습니다. 단, V-SPACE은 관련
                법률에 어긋나지 않는 한 비즈니스 파트너와의 제휴를 통해 회원에게
                제공되는 서비스 질을 향상시킬 목적이나 온라인 판촉에 관련된
                사항, 통계작성 또는 시장조사 등을 위하여 필요한 정보를 타사에게
                제공하거나 공유할 수 있습니다. <br/>4. 이용자는 V-SPACE에 제공한
                개인정보의 수집과 이용에 대한 동의를 철회할 수 있습니다. <br/>5.
                이용자는 V-SPACE에 제공한 개인정보를 열람할 수 있고 그 정보가
                잘못된 경우 V-SPACE에 대하여 정정을 요구할 수 있습니다. <br/><br/>제 6 장
                각 당사자의 의무<br/><br/> 제16조 (V-SPACE의 의무)<br/><br/> 1. V-SPACE는 법령과 이
                약관이 금지하는 행위를 하지 않으며 이 약관이 정하는 바에 따라
                지속적이고, 안정적으로 서비스를 제공하는데 최선을 다하여야
                합니다.<br/>2. V-SPACE는 이용자가 안전하게 인터넷서비스를 이용할 수
                있도록 이용자의 개인정보(신용정보포함)의 보호, 관리를 위해
                필요한 조치를 취합니다. <br/>3. V-SPACE는 이용자가 원하지 않는
                영리목적의 광고성 전자우편을 발송하지 않습니다. <br/>4. V-SPACE는
                회원 개인 정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않는
                것을 원칙으로 합니다. 단, 아래 사항에 해당하는 경우는
                예외입니다. <br/>가. 사전에 귀하에 공개 동의를 구한 경우 <br/>나.
                배송업무상 배송업체에세 배송에 필요한 최소한의 이용자 정보(성명,
                주소, 전화번호)를 알려주는 경우 <br/>다. 마케팅 및 경품행사를 위한
                자료로 활용할 경우 <br/>라. 전기통신기본법 등 법률의 규정에 의해
                국가기관의 요구가 있는 경우 <br/>마. 범죄에 대한 수사상의 목적이
                있거나 정보통신윤리 위원회의 요청이 있는 경우 <br/>바. 기타
                관계법령에서 정한 절차에 따른 요청이 있는 경우 <br/>사. 마케팅 및
                경품행사를 위한 요청이 있는 경우 <br/>5. V-SPACE는 업무와 관련하여
                회원 전체, 일부의 개인정보에 관한 자료를 작성하여 이를 사용할 수
                있고, 서비스를 통하여 회원의 컴퓨터에 쿠키를 전송할 수 있습니다.
                <br/><br/>제17조 (회원의 의무)<br/><br/> 1. 회원은 이 약관에서 규정하는 사항과
                서비스 이용안내 및 주의사항 등을 준수하여야 합니다. <br/>2. 회원은
                등록 양식에서 요구되는 내용들에 대해 진실 되고 정확하며 현재의
                사실과 일치되는 완전한 정보를 제공하고 유지합니다. <br/>3. 회원은
                자신의 ID와 비밀번호를 관리할 의무를 가지고, 회원의 ID와
                비밀번호를 사용하여 발생하는 모든 결과에 대한 책임은 전적으로
                회원에게 있습니다. <br/>4. 회원은 자신의 ID 및 비밀번호를 제3자에게
                이용하게 해서는 안되며, ID 및 비밀번호를 도난 당하거나 제3자가
                사용하고 있음을 인지한 경우에는 바로 V-SPACE에 통보하고
                V-SPACE의 안내가 있는 경우에는 그에 따라야 합니다. <br/>5. 회원은
                사전 승낙없이 상업적인 목적을 위하여 서비스를 이용할 수
                없습니다. <br/>6. 이용자는 V-SPACE을 이용함으로써 얻은 정보를 승낙
                없이 복제, 송신, 출판, 배포, 방송 및 기타 방법에 의하여
                상업적으로 이용하거나 제3자에게 이용하게 할 수 없습니다. <br/>7.
                회원의 행동규범 <br/>가. 회원이 본 서비스를 통해 게재한 게시물 또는
                컨텐츠에 대해서는 이용자가 전적으로 책임을 집니다. <br/>나. 회원은
                불법적이거나 타인에게 해를 입히거나, 타인의 명예를 훼손하는 등의
                행위를 하지 않습니다. <br/>다. 회원은 타인의 개인정보를 수집 또는
                저장하여 유용하지 않습니다. <br/><br/>제 7 장 기타<br/><br/> 제18조 (분쟁해결 및
                기타사항)<br/><br/> 1. V-SPACE는 이용자가 제기하는 정당한 의견이나 불만을
                반영하여 적절하고 빠른 조치를 취합니다. <br/>2. V-SPACE는 신속한
                처리가 곤란한 경우에는 이용자에게 그 사유와 처리 일정을 통보
                합니다. <br/><br/>제19조 (손해배상책임)<br/><br/> V-SPACE는 V-SPACE의 서비스가
                무상으로 제공되는 한 서비스의 이용과 관련하여 회원에게 발생한
                어떠한 손해에 대해서도 책임을 지지않습니다. <br/><br/>제20조 (면책조항)<br/><br/> 1.
                V-SPACE는 서비스 이용 중 V-SPACE의 책임사유가 없이 발생한 어떠한
                손해에 관하여도 책임을 지지 않습니다. <br/>2. V-SPACE는 약관에 명시된
                사유로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공의
                불이행에 관한 책임이 면제됩니다. <br/>3. V-SPACE는 V-SPACE의 연결
                사이트에 대해 보증책임을 지지 않으며 따라서 연결 사이트와
                이용자간에 이루어진 어떠한 거래에 대해서도 책임을 지지 않습니다.
                <br/>4. V-SPACE는 회원의 귀책사유로 인한 서비스 이용 장애에 대해 그
                책임이 면제됩니다. <br/>5. V-SPACE는 회원이 서비스에 게재한 정보,
                자료, 사실의 신뢰도, 정확성 등 그 내용에 관하여 책임이
                면제됩니다. <br/><br/>제21조 (분쟁의 사전처리)<br/><br/> 1. 회원이 소송을 제기할
                경우는 V-SPACE에 책임사유가 명백히 인정되는 경우에 한정됩니다.
                <br/>2. 회원이 V-SPACE을 상대로 소송을 제기할 경우 상충하는 법령 또는
                법규에 관계없이 본 서비스의 이용 또는 약관으로 인하여 발생하는
                모든 청구 또는 소송은 원인이 발생한 날로부터 1년 이내에
                제기되어야 하며, 이후에는 영원히 제기할 수 없습니다. <br/><br/>제22조
                (관할 법원)<br/><br/> 이용자와 V-SPACE 간에 서비스로부터 발생하거나
                서비스와 관련된 모든 분쟁은 민사소송법의 관할을 가지는 대한민국
                법원에 제기합니다.<br/><br/>부칙<br/> (시행일) 본 약관은 2011년 7월 15일부터
                적용합니다.
              </p>
            </div>
            <div className="agreebox">
              <input
                type="checkbox"
                className="chk"
                onChange={(e) => setUseCheck(e.target.checked)}
              />
              위 회원가입약관에 동의합니다.
            </div>
          </div>
          <div className="registerconfirm-Btn">
            <button
              className="rn-dark-btn"
              style={{ marginRight: "1.3rem" }}
              onClick={() => {
                navigate("/login");
              }}
            >
              취 소
            </button>
            <button
              type="submit"
              className="rn-dark-btn"
              onClick={(e)=>{registerHandleForm(e);}}
            >
              확 인
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Registerconfirm;
