import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const EquipmentsList = ({ items, boardUrl, isLoading }) => {
  if (isLoading) {
    return (
      <div className="designer-portfolio-area pt--150 pb--150 bg_color--1">
        <div className="container plr--80 plr_sm--30 plr_md--30">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner text-center fw-bold">
                  <h4>Loading...</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (items?.length === 0) {
    return (
      <div className="designer-portfolio-area pt--150 pb--100 bg_color--1">
        <div className="container plr--80 plr_sm--30 plr_md--30">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner text-center fw-bold">
                  해당 내용이 없습니다.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <Fragment>
      {console.log(items)}
      <div className="row mt_dec--30">
        {items?.map((item, i) => (
          <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt--60" key={i}>
            <div className="eq_box">
              <div className="thumbnail">
                <Link to={`${boardUrl}/${item.id}`}>{item.image ? <img src={item.image} alt="item Images" /> : <></>}</Link>
              </div>
              <div className="content">
                <div className="inner">
                  <div className="content_heading">
                    <div className="category_list">
                      <Link to={`${boardUrl}/${item.id}`}>{item.short_description}</Link>
                    </div>
                    <h4 className="title">
                      <Link to={`${boardUrl}/${item.id}`}>{item.name}</Link>
                    </h4>
                  </div>
                  <div className="content_footer">
                    <Link to={`${boardUrl}/${item.id}`} className="rn-btn btn-opacity">
                      더보기
                    </Link>
                  </div>
                </div>
                <Link className="transparent_link" to={`${boardUrl}/${item.id}`}></Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default EquipmentsList;
