import React, { useState } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiCheck } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { useGetDatas } from "../hooks/useApi";
import axiosInstance from "../axios/axiosInstance";
import { isLoginSelector } from "../recoil/User";
import { useRecoilValue } from "recoil";
import BreadcrumbGray from "./common/BreadcrumbGray";
import ScrollTop from "./common/ScrollTop";
import Breadcrumb from "./common/Breadcrumb";

const EducationDetail = () => {
  const [check, setCheck] = useState(false);
  const [photoCheck, setPhotoCheck] = useState(false);
  const [guidCheck, setGuidCheck] = useState(false);
  const [eduList, setEduList] = useState([]);
  const [description, setDescription] = useState("");
  const [accompanyPerson, setAccompanyPerson] = useState(0);
  const [accompanyName, setAccompanyName] = useState("");
  const { id } = useParams();
  const data = useGetDatas("/educations/items", id);
  const navigate = useNavigate();
  const isLogin = useRecoilValue(isLoginSelector);
  console.log(data);
  const chkHandler = async (e) => {
    e.preventDefault();
    if (!isLogin) {
      window.alert("로그인이 필요합니다.");
      window.location = "/login";
      return;
    }
    if (!check) {
      alert("개인정보수집이용에 동의해주실 바랍니다.");
      return;
    }
    if (!photoCheck) {
      alert("사진촬영 및 이용에 동의해주실 바랍니다.");
      return;
    }
    if (!guidCheck) {
      alert("안내사항에 동의해주실 바랍니다.");
      return;
    }
    const body = {
      education_id: id,
      companion_num: accompanyPerson,
      companion_names: accompanyName,
      inquiry: description,
    };
    console.log(body);

    await axiosInstance
      .post(`/educations/reservation/`, { education_id: id })
      .then((res) => {
        if (res.status === 200) {
          // console.log("예약")
          const result = async () => {
            await axiosInstance
              .put(`/educations/reservations/${res.data.reservation_id}/`, body)
              .then((response) => console.log(response))
              .catch((error) => console.log(error));
          };
          result();
          window.alert("신청되었습니다.");
          window.location = `/education-confirm/${res.data.reservation_id}`;
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.error === "이미 신청한 교육입니다.") {
          alert("이미 신청한 교육입니다.");
        }
      });
  };
  // const EduList = async () => {
  //   await axiosInstance.get("/educations/items/").then((response) => {
  //     setEduList(response.data);
  //   });
  // };
  // console.log(eduList)

  // useEffect(() => {
  //   EduList();
  // }, []);

  return (
    <React.Fragment>
      <PageHelmet pageTitle="Eduction Details" />

      <Breadcrumb title="교육 프로그램" />

      {/* Start Portfolio Details */}
      <div className="rn-portfolio-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-details">
                <form method="post" onSubmit={chkHandler}>
                  <div className="row row--35 mb--50">
                    <div className="col-lg-6">
                      <div className="inner">
                        <div className="section-title">
                          <h2 className="title">{data.name}</h2>
                          {/* <p className="description mt--30">메쉬믹서(3D스캔) 교육에 참가하기 위한 기본 프로필을 작성합니다.<br/>
작성 내용은 V-SPACE에서 규정하는 정보이용에만 활용됩니다.</p> */}
                          <p className="description mt--30">{data.descript}</p>
                          {/* <p className="description">
                          * 해당 교육은 신청전 3D스캐너 온라인교육을 선행학습
                          하시는 것을 추천드립니다.
                        </p> */}

                          <ul className="list-style--1">
                            <li>
                              <FiCheck />
                              교육일자 : {data.start_date}
                            </li>
                            <li>
                              <FiCheck />
                              교육시간 : {data?.start_time?.slice(0, 5)} - {data?.end_time?.slice(0, 5)}
                            </li>
                            <li>
                              <FiCheck />
                              교육장소 : {data.location}
                            </li>
                            <li>
                              <FiCheck />
                              신청인원(참여인원) : {data.max_people}명
                            </li>
                            <li>
                              <FiCheck />문 의 : 기계관 208호 V-SPACE (051-510-3261)
                            </li>
                            <li>
                              <FiCheck />
                              <a href="mailto:pnuvspace@gmail.com" style={{ color: "#464646" }}>
                                문 의 : pnuvspace@gmail.com
                              </a>
                            </li>
                            <li>
                              <FiCheck />
                              미성년자를 동반할 경우 기입해주시길 바랍니다.
                            </li>
                            <li>
                              <div className="plr--20">
                                <div>
                                  동반인원 :{" "}
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      setAccompanyPerson(e.target.value);
                                    }}
                                    className="member-input"
                                  />
                                  명
                                </div>
                                <div className="mt--5 d-flex">
                                  동반인명 :{" "}
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      setAccompanyName(e.target.value);
                                    }}
                                    className="accompanyName"
                                  />
                                </div>
                              </div>
                            </li>
                            <li>
                              <FiCheck />
                              요청사항(내용이 있을 경우 기입해주시길 바랍니다.)
                            </li>
                            <li>
                              <div className="plr--20">
                                <textarea rows={4} cols={50} onChange={(e) => setDescription(e.target.value)}></textarea>
                              </div>
                            </li>
                          </ul>
                        </div>
                        {/* <div className="portfolio-view-list d-flex flex-wrap">
                        <div className="port-view">
                          <span>Branch</span>
                          <h4>Ability</h4>
                        </div>

                        <div className="port-view">
                          <span>Project Types</span>
                          <h4>Website</h4>
                        </div>

                        <div className="port-view">
                          <span>Program</span>
                          <h4>View Project</h4>
                        </div>
                      </div> */}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="portfolio-details-accordion mt--100 mt_md--40 mt_sm--40">
                        <div className="inner">
                          <div className="section-title">
                            <div className="d-flex align-items-center">
                              <h5>개인정보 수집 이용 동의</h5>
                              <div className="ml--10">
                                <input type="checkbox" className="chk" onChange={(e) => setCheck(e.target.checked)} />
                              </div>
                            </div>
                            <p className="description">
                              - 항목 : 성명, 소속, 직위, 휴대전화, 작업내용, 이메일
                              <br />
                              - 개인 정보 제공 기관 : 창업진흥원, 부산대학교 산학협력단
                              <br />- 수집목적 : 프로그램 신청 및 운영, 사업보고 보유기간 : 사업종료 후 5년
                            </p>
                            <div className="d-flex align-items-center">
                              <h5>사진촬영 및 이용 동의</h5>
                              <div className="ml--10">
                                <input type="checkbox" className="chk" onChange={(e) => setPhotoCheck(e.target.checked)} />
                              </div>
                            </div>
                            <p className="description">교육 중 사진촬영 및 이용(홍보, 증빙자료 등)에 대해 동의합니다</p>

                            <div className="d-flex align-items-center">
                              <h5>안내사항 동의</h5>
                              <div className="ml--10">
                                <input type="checkbox" className="chk" onChange={(e) => setGuidCheck(e.target.checked)} />
                              </div>
                            </div>
                            <p className="description">
                              - 원할한 교육을 위해 참여가 불가능할 경우 미리 고지부탁드립니다 <br />
                              - 교육 중에 일어나는 이용자 부주의로 인한 시설 및 장비 파손 시 원상복구 하여야 합니다
                              <br />
                              - 최소 인원 미달 시 해당 교육이 취소될 수 있습니다
                              <br />
                              - 강사의 안내에 따르지 않아 발생한 사고에 대해서는 책임을 지지 않습니다
                              <br />
                              - 교내 무료 주차는 지원되지 않으며, 주차 규정은 부산대학교 주차관리 규정에 따릅니다
                              <br />
                            </p>
                          </div>
                        </div>
                        <div className="portfolio-details-btn mt--30 d-flex  justify-content-end">
                          {data.is_past_due ? (
                            <button className="btn-default btn-grey-border" type="button">
                              기한완료
                            </button>
                          ) : (
                            <>
                              {data.is_full ? (
                                <>
                                  {" "}
                                  <button className="btn-default btn-grey-border" type="button">
                                    신청마감
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button className="btn-default btn-border" type="submit">
                                    신청하기
                                  </button>{" "}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Details */}

      {/* Start Back To Top */}
      <ScrollTop />
      {/* End Back To Top */}
    </React.Fragment>
  );
};

export default EducationDetail;
