import React, { useEffect, useState } from 'react'
import { FiChevronUp } from "react-icons/fi";

const ScrollTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    const MoveToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      };

      useEffect(() => {
        // 스크롤 이벤트를 감지하여 버튼을 표시 또는 숨김
        const handleScroll = () => {
          if (window.scrollY > 100) {
            setIsVisible(true); // 스크롤 위치가 100 이상이면 버튼 표시
          } else {
            setIsVisible(false); // 스크롤 위치가 100 이하이면 버튼 숨김
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          // 컴포넌트 언마운트 시에 이벤트 리스너 제거
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    
    

  return (
    <div className={`backto-top ${isVisible ? 'visible' : ''}`}>
    <div className="scroll-container text-center">
    <span
      onClick={MoveToTop}
    >
      <FiChevronUp />
    </span>
  </div>
  </div>
  )
}

export default ScrollTop
