import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Card from "react-bootstrap/Card";
import useResize from "../../../hooks/useResize.jsx";
import Search from "../../../elements/blog/Search.jsx";
import Pagination from "../../../elements/blog/Pagination.jsx";
import { useGetUserId } from "../../../hooks/useApi.jsx";
import BreadcrumbGray from "../../../elements/common/BreadcrumbGray.jsx";
import { API_HOST } from "../../../axios/axiosInstance.jsx";
import ScrollTop from "../../../elements/common/ScrollTop.jsx";
import Breadcrumb from "../../../elements/common/Breadcrumb.jsx";

function Gallery() {
  const location = useLocation();
  const base_url = `${API_HOST}/board/gallery-posts/`;
  const [notices, setNotices] = useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const [baseurl, setbaseurl] = useState(base_url);
  const [currentpage, setcurrentpage] = useState(1);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [loading, setLoading] = useState(true);
  const perpage = 6;
  const [userId, isAdmin, writer] = useGetUserId();
  const windowWidth = useResize();
  const [search, setSearch] = useState("");

  function changeurl(event) {
    setbaseurl(event);
  }
  async function getNotices() {
    setLoading(true);
    const { data } = await axios.get(baseurl);
    setNotices(data.results);
    setTotalCount(data.count);
    setNext(data.next);
    setPrevious(data.previous);
    setLoading(false);
  }
  useEffect(() => {
    getNotices(baseurl);
  }, [baseurl]);
  console.log(notices);

  // const [searchSelect, setSearchSelect] = useState("title_only");
  // const [searchKeyword, setSearchKeyword] = useState("");

  // const handleSelectValue = (e) => setSearchSelect(e.target.value);
  // const handleSearchValue = (e) => setSearchKeyword(e.target.value);

  return (
    <>
      <Breadcrumb title="작품갤러리" />

      <div className="gallery-container pt--50 pb--120">
        <div className="gallery-wrap">
          {isAdmin === "관리자" ? (
            <div className="boardwrite">
              <Link to="/gallery/insert">
                <button className="boardwrite-btn" type="button">
                  글쓰기
                </button>
              </Link>
            </div>
          ) : (
            <></>
          )}
          <div className="row">
            {notices && notices.length ? (
              notices.map((notice, key) => {
                return (
                  <div className="col-md-6 col-lg-4 mb-3" key={notice.id}>
                    <Link to={`detail/${notice.id}`}>
                      <Card>
                        <Card.Img variant="top" src={notice.thumbnail} className="gallery-thumbnail" />
                        <Card.Body>
                          <Card.Text>{notice.title}</Card.Text>
                        </Card.Body>
                      </Card>
                    </Link>
                  </div>
                );
              })
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  fontSize: 18,
                  paddingBottom: 50,
                  minHeight: 300,
                  borderTop: "1.5px solid black",
                }}
              >
                등록된 갤러리가 없습니다.
              </div>
            )}
          </div>
          {windowWidth > 768 ? (
            <>
              <div className="boardBottom">
                <Pagination
                  notices={notices}
                  previous={previous}
                  base_url={base_url}
                  changeurl={changeurl}
                  setcurrentpage={setcurrentpage}
                  currentpage={currentpage}
                  totalcount={totalcount}
                  perpage={perpage}
                  next={next}
                />
                <Search baseurl={baseurl} setNotices={setNotices} setTotalCount={setTotalCount} setNext={setNext} onChange={setSearch} setPrevious={setPrevious} />
              </div>
            </>
          ) : (
            <>
              <div className="boardBottom">
                <div className="d-colum w-100">
                  <div className="bottompagination">
                    <Pagination
                      notices={notices}
                      previous={previous}
                      base_url={base_url}
                      changeurl={changeurl}
                      setcurrentpage={setcurrentpage}
                      currentpage={currentpage}
                      totalcount={totalcount}
                      perpage={perpage}
                      next={next}
                    />
                  </div>
                  <div className="searchSBox">
                    <Search baseurl={baseurl} setNotices={setNotices} setTotalCount={setTotalCount} setNext={setNext} setPrevious={setPrevious} />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <ScrollTop />
      </div>
    </>
  );
}

export default Gallery;
