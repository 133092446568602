import React, { useEffect, useRef, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import { useNavigate, useLocation, Link } from "react-router-dom";
// import Modal from "react-modal";
import { FiCalendar } from "react-icons/fi";
import axiosInstance, { axiosFormInstance } from "../axios/axiosInstance";
import Modal from "react-bootstrap/Modal";
import useResize from "../hooks/useResize";
import moment from "moment/moment";
import { isLoginSelector } from "../recoil/User";
import { useRecoilValue } from "recoil";
import Daypicker from "./datePicker/Daypicker";
import ScrollTop from "./common/ScrollTop";
import { useGetUserId } from "../hooks/useApi";
import { RiExternalLinkFill } from "react-icons/ri";
import imageCompression from "browser-image-compression";

const EquipmentsDetail = () => {
  const [Check, setCheck] = useState(false);
  const [privateCheck, setPrivateCheck] = useState(false);
  const [Equip, setEquip] = useState([]);
  const [EquipEdus, setEquipEdus] = useState([]);
  const [EquipDevice, setEquipDevice] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const equipId = location.pathname.slice(
    location.pathname.lastIndexOf("/") + 1
  );
  const [show, setShow] = useState(false);
  const windowWidth = useResize();
  const [selectedOption, setSelectedOption] = useState("시제품 제작");
  const [optionEtcText, setOptionEtcText] = useState(null);
  const [startDate, setStartDate] = useState(null);

  const [selectedTimeItems, setSelectedTimeItems] = useState([]);
  const [selectedTimeItem, setSelectedTimeItem] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(0);
  const [timeTable, setTimeTable] = useState([]);
  const isLogin = useRecoilValue(isLoginSelector);
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [answers, setAnswers] = useState([]);
  const [userId, isAdmin, writer, data] = useGetUserId();
  const [showAlert, setShowAlert] = useState(false);
  const [priorTest, setPriorTest] = useState([]);
  const handleTextareaChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.replace(/\s/g, "").length >= 15) {
      setDescription(inputValue);
      setShowAlert(false);
    } else {
      setShowAlert(true);
    }
  };

  const equipSubmit = async (e) => {
    e.preventDefault();
    if (!isLogin) {
      window.alert("로그인이 필요합니다.");
      window.location = "/login";
      return;
    }

    if (title.trim() === null || title.trim() === "") {
      window.alert("작업 제품명을 선택해주시길 바랍니다.");
      return;
    }
    if (!file) {
      window.alert("작업제품 이미지 파일을 올려주시길 바랍니다.");
      return;
    }
    if (description.trim() === null || description.trim() === "") {
      window.alert("작업제품에 대한 설명을 입력해주길 바랍니다.");
      return;
    }

    if (!startDate || startDate === null || startDate === "") {
      window.alert("날짜를 선택해주시길 바랍니다.");
      return;
    }
    // const timeItems = selectedTimeItems.map(id => ({ id }));
    if (selectedTimeItems.length === 0) {
      window.alert("시간을 선택해주시길 바랍니다.");
      return;
    }
    if (selectedDevice === null) {
      window.alert("대여가능기기를 선택해주시길 바랍니다.");
      return;
    }
    if (!privateCheck) {
      window.alert("개인정보 수집 이용에 동의해 주시길 바랍니다.");
      return;
    }
    const formData = new FormData();
    formData.append("project_name", title);
    formData.append("project_pre_image", file);
    formData.append("project_descript", description);
    formData.append("reservation_date", moment(startDate).format("YYYY-MM-DD"));
    formData.append(
      "purpose",
      selectedOption === "기타" ? optionEtcText : selectedOption
    );


    const pass_result = [];
    for (let i = 0; i < selectedTimeItems.length; i++) {
      try {
        const body = {
          equip_id: equipId,
          reservation_date: moment(startDate).format("YYYY-MM-DD"),
          reservation_time_id: selectedTimeItems[i],
          purpose: selectedOption === "기타" ? optionEtcText : selectedOption,
          reservation_device_id: EquipDevice[selectedDevice].id,
        };

        const result = await axiosInstance.post("/equips/reservation/", body);

        const reservation_id = result.data.reservation_id;

        if (result.status === 200) {
          let retryCount = 0;
          while (retryCount < 3) {
            try {
              const update_result = await axiosFormInstance.put(
                `/equips/reservations/${result.data.reservation_id}/`,
                formData
              );
              if (update_result.status === 200) {
                pass_result.push(reservation_id);
                break;
              }
            } catch (error) {
              if (error.response && error.response.status === 404) {
                retryCount++;
                continue;
              }
              console.log(error);
              break;
            }
          }
          // alert("예약되었습니다.");
          // navigate(`/equipments-confirm/${reservation_id}`, {
          //   state: {
          //     reserv_ids: pass_result,
          //   },
          // });
        }
      } catch (err) {
        console.log(err);
        alert(err.response.data.error);
      }
    }
    if (pass_result.length == selectedTimeItems.length) {
      alert("예약되었습니다.");
      navigate(`/equipments-confirm/${pass_result[0]}`, {
        state: {
          reserv_ids: pass_result,
        },
      });
    } else if (pass_result.length > 0) {
      alert("일부 예약이 완료되었습니다. 나머지 예약을 다시 시도해주세요.");
      navigate(`/equipments-confirm/${pass_result[0]}`, {
        state: {
          reserv_ids: pass_result,
        },
      });
    } else {
      alert("예약에 실패하였습니다.");
    }
  };

  const getEquipDetail = async () => {
    await axiosInstance.get(`/equips/items/${equipId}`).then((res) => {
      setEquip(res.data);
      // setTimeTable(res.data.time_table);
      setEquipDevice(res.data.devices);
      setPriorTest(res.data.prior_test);
      // if (res.data.prior_test) setEquipEdus(res.data.prior_test);
    });
  };

  useEffect(() => {
    if (selectedDevice != null && startDate) {
      axiosInstance
        .get(
          `/equips/deivce-time-table/${EquipDevice[selectedDevice]?.id
          }/?date=${moment(startDate).format("YYYY-MM-DD")}`
        )
        .then((res) => {
          setTimeTable(res.data.time_tables);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [selectedDevice, startDate]);

  useEffect(() => {
    if (timeTable.length > 0) {
      setSelectedTimeItems([]);
    }
  }, [timeTable]);

  const handleOption = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleDeviceClick = (index) => {
    // 선택된 장비의 인덱스를 상태에 저장
    setSelectedDevice(index);
  };

  useEffect(() => {
    getEquipDetail();
  }, [location]);

  const imageCompress = async (e) => {
    let file = e.target?.files[0];
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 720,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(file, options);
      const promise = imageCompression.getDataUrlFromFile(compressedFile);
      promise.then((result) => {
        // Base64 문자열을 Blob 객체로 변환
        const byteString = atob(result.split(',')[1]);
        const mimeString = result.split(',')[0].split(':')[1].split(';')[0];
        const byteNumbers = new Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
          byteNumbers[i] = byteString.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: mimeString });
  
        // Blob 객체를 File 객체로 변환
        const newFile = new File([blob], file.name, { type: mimeString });
        
        // File 객체를 상태에 저장
        setFile(newFile);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = (e) => {
    // const se
    // setFile(e.target.files[0]);
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileTypes = [
        "image/apng",
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "image/svg+xml",
        "image/tiff",
        "image/webp",
        "image/x-icon",
      ];
      if (fileTypes.includes(selectedFile.type)) {
        imageCompress(e);
      } else {
        alert("이미지 파일만 업로드 가능합니다.");
      }
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0, // 맨 위로 스크롤
      behavior: "smooth", // 부드러운 스크롤 효과 사용
    });
  };
  //  console.log(file)
  return (
    <React.Fragment>
      <PageHelmet pageTitle="Equipments Details" />

      {/* Start Equipments Details */}
      <div className="rn-blog-details ptb--70 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  <div className="blog-single-list-wrapper">
                    {Equip.image ? <div className="row mt--20">
                      <div className="thumbnail w-100 text-center col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center pb--60 mt--20">
                        <img
                          src={Equip.image}
                          alt="Eq Images"
                        // className="img-fluid"
                        />
                      </div>
                    </div> : null}

                    <div className="row">
                      <div className="col-lg-12 col-sm-12 content mb--20">
                        <h4 className="title">{Equip.name}</h4>
                        <ul className="list-style">
                          <li>사용가능수량 : {Equip.quantity}대 </li>
                          <li>장비 설명</li>
                          <div
                            style={{
                              whiteSpace: "pre-wrap",
                              paddingLeft: 10,
                              marginTop: 10,
                            }}
                          >
                            {Equip.descript}
                          </div>
                        </ul>
                        <h4 className="title">V-SPACE 장비교육 안내</h4>
                        <p>
                          - 해당 장비 영상 시청 및 설문지 작성을 이수하신 분들에 한하여 V-SPACE 장비를 사용할 수
                          있도록 하고 있습니다. 먼저 해당 장비 영상 및 설문지
                          작성하신 후에 예약하시길 바랍니다.
                          <br />
                          - 장비 예약 전 필수 동영상 및 설문지가 없는 장비도
                          있습니다.
                          <br />
                          {/* <br />- 해당영상은 무단 복제 및 배포, 상업적인 사용을
                          원칙적으로 금합니다.
                          <br />- 영상을 스킵하거나 건너뛰어서 설명을 놓치는
                          부분이 없도록 해주시기 바랍니다.(특히 사용가능한 재료
                          부분) */}
                          - V-SPACE에서 사용되는 대부분의 장비는 무료로
                          사용가능하며 사용되는 소모성 재료는 직접 가지고 오셔야
                          됩니다.
                          <br />- 장비당 1일 1인 최대 연속 2타임(1타임=30분)
                          예약 가능합니다.
                          <br />- 장비 사용 후 완료사진 미제출 시 이후 해당 장비
                          예약 불가합니다. 마이페이지에서 완료사진을 제출 할 수
                          있습니다.
                          <br />- 예약건 QR코드 지참시 입장 가능합니다.
                          <br />- 대량 출력 또는 생산이 필요할 시, 기타
                          문제상황발생이나 문의사항이 있으신 분들은 V-SPACE
                          (051-510-3261)로 전화 문의바랍니다.
                          <br /> - 해당 장비교육 영상 링크 : {priorTest && priorTest.length > 0 ? (
                            <>
                              {/* {" [ "}   */}
                              {priorTest.map((pt, index) => {
                                return (
                                  <>
                                    <Link to={`/online-equip-detail/${pt.id}`} onClick={handleScrollToTop}>
                                      <span key={index} style={{ fontSize: 16, fontWeight: 600, marginRight: 2 }}>
                                        {pt.title}
                                      </span><RiExternalLinkFill />
                                      {index !== priorTest.length - 1 ? "," : ""}
                                    </Link>
                                  </>
                                );
                              })}
                              {/* {" ] "} */}
                            </>
                          ) : (
                            <span style={{ fontWeight: 'bold' }}>해당 장비교육 영상은 없습니다.</span>
                          )}

                        </p>
                        <h4 className="title mt--30">안내사항</h4>
                        <p className="description">
                          - 홈페이지를 통해 예약승인 결과를 확인 후 사용이
                          가능합니다. <br />
                          - 이용자 부주의로 인한 시설 및 장비 파손시 원상 복구
                          하여야 합니다.
                          <br />
                          - 해당 신청건의 결과 보고서 미제출시 이용 사용이
                          불가합니다
                          <br />
                          - 관리자 판단하에 사용에 제재를 받는 경우가 있을 수
                          있습니다. <br />
                        </p>

                        <h4 className="title mt--30">
                          V-SPACE 장비 예약
                          <FiCalendar
                            size={25}
                            style={{
                              marginBottom: 3,
                              marginLeft: 8,
                              color: "gray",
                            }}
                          />
                        </h4>
                        <form method="post" onSubmit={equipSubmit}>
                          <table className="equip-purpose">
                            <thead>
                              <tr>
                                <td>사용목적</td>
                                {/* <td colSpan={2}>
                                  <div className="d-flex">
                                    <select
                                      value={selectedOption}
                                      onChange={handleOption}
                                      className="equip-purpose-select"
                                    >
                                      <option value="시제품 제작">
                                        시제품 제작
                                      </option>
                                      <option value="초도물량 생산 및 양산">
                                        초도물량 생산 및 양산
                                      </option>
                                      <option value="디자인 목업">
                                        디자인 목업
                                      </option>
                                      <option value="기타">기타</option>
                                    </select>

                                    {selectedOption === "기타" ? (
                                      <input
                                        type="text"
                                        value={optionEtcText || ""}
                                        onChange={(e) =>
                                          setOptionEtcText(e.target.value)
                                        }
                                        style={{ marginLeft: 5, width: "50%" }}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </td> */}
                                {/* 라디오 버튼으로 변경 세로 배열로 */}
                                <td colSpan={2}>
                                  <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center">
                                      <input
                                        type="radio"
                                        id="radio1"
                                        name="radio"
                                        value="시제품 제작"
                                        checked={selectedOption === "시제품 제작"}
                                        onChange={handleOption}
                                        style={{width:15}}
                                      />
                                      <label
                                        htmlFor="radio1"
                                        style={{ marginLeft: 10 }}
                                      >
                                        시제품 제작
                                      </label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <input
                                        type="radio"
                                        id="radio2"
                                        name="radio"
                                        style={{width:15}}
                                        value="초도물량 생산 및 양산"
                                        checked={
                                          selectedOption ===
                                          "초도물량 생산 및 양산"
                                        }
                                        onChange={handleOption}
                                      />
                                      <label
                                        htmlFor="radio2"
                                        style={{ marginLeft: 10 }}
                                      >
                                        초도물량 생산 및 양산
                                      </label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <input
                                        type="radio"
                                        id="radio3"
                                        name="radio"
                                        value="디자인 목업"
                                        style={{width:15}}
                                        checked={selectedOption === "디자인 목업"}
                                        onChange={handleOption}
                                      />
                                      <label
                                        htmlFor="radio3"
                                        style={{ marginLeft: 10 }}
                                      >
                                        디자인 목업
                                      </label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <input
                                        type="radio"
                                        id="radio4"
                                        name="radio"
                                        value="기타"
                                        style={{width:15}}
                                        checked={selectedOption === "기타"}
                                        onChange={handleOption}
                                      />
                                      <label
                                        htmlFor="radio4"
                                        style={{ marginLeft: 10 }}
                                      >
                                        기타
                                      </label>
                                      {selectedOption === "기타" ? (
                                        <input
                                          type="text"
                                          value={optionEtcText || ""}
                                          onChange={(e) =>
                                            setOptionEtcText(e.target.value)
                                          }
                                          style={{
                                            marginLeft: 20,
                                            width: "70%",
                                          }}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>작업제품명</td>
                                <td>
                                  <input
                                    type="text"
                                    onChange={(e) => setTitle(e.target.value)}
                                    placeholder="필수입력"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>작업제품이미지</td>
                                <td>
                                  <input
                                    type="file"
                                    style={{
                                      border: "none",
                                      paddingLeft: 0,
                                      paddingBottom: 0,
                                    }}
                                    accept=".jpeg, .jpg, .png"
                                    onChange={handleFileChange}
                                  />
                                  <span>
                                    (모델링 이미지 스크린샷 또는 도면)
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>작업제품설명</td>
                                <td>
                                  <textarea
                                    row={4}
                                    cols={50}
                                    onChange={handleTextareaChange}
                                    placeholder="15자 이상 필수입력"
                                  />
                                  <span>
                                    (제작목적, 제작형태나 전체 제작 과정 중 현재 진행중인 상황 등 상세 기입)
                                    {showAlert && (
                                      <>
                                        <span
                                          style={{
                                            color: "red",
                                            paddingLeft: 10,
                                          }}
                                        >
                                          최소 15자 이상 입력하세요.
                                        </span>
                                      </>
                                    )}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>대여가능기기</td>
                                <td>
                                  {EquipDevice.map((device, index) => (
                                    <button
                                      type="button"
                                      className={`device-btn ${selectedDevice === index
                                          ? "selected"
                                          : ""
                                        }`}
                                      key={index}
                                      onClick={() => handleDeviceClick(index)}
                                    >
                                      {device.name}
                                    </button>
                                  ))}
                                </td>
                              </tr>
                              <tr>
                                <td>예약날짜</td>
                                <td colSpan={3}>
                                  <Daypicker
                                    startDate={startDate}
                                    onChange={(date) => {
                                      setStartDate(date);
                                    }}
                                    saturday={true}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>예약시간</td>
                                <td colSpan={3}>
                                  {timeTable?.map((time, index) => (
                                    <button
                                      key={index}
                                      id={`time-item${time.id}`}
                                      className="time-btn"
                                      style={{
                                        ...{
                                          padding: "5px 10.5px",
                                        },
                                        ...(selectedTimeItems.includes(
                                          time.id
                                        ) && {
                                          background: "var(--color-primary)",
                                          color: "#fff",
                                          border:
                                            "1px solid var(--color-primary)",
                                        }),
                                        ...(time.is_reserved && {
                                          background: "#d4d4d4",
                                          color: "#fff",
                                          border: "1px solid #d4d4d4",
                                          cursor: "not-allowed",
                                        }),
                                      }}
                                      // style={{
                                      //   ...{
                                      //     padding: "5px 10.5px",
                                      //   },
                                      //   ...(selectedTimeItems?.id ===
                                      //     time.id && {
                                      //     background: "var(--color-primary)",
                                      //     color: "#fff",
                                      //     border:
                                      //       "1px solid var(--color-primary)",
                                      //   }),
                                      //   ...(time.is_reserved && {
                                      //     background: "#d4d4d4",
                                      //     color: "#fff",
                                      //     border: "1px solid #d4d4d4",
                                      //     cursor: "not-allowed",
                                      //   }),
                                      // }}
                                      onClick={() => {
                                        const index = timeTable.findIndex(
                                          (t) => t.id === time.id
                                        );

                                        if (index === -1) {
                                          return;
                                        }

                                        if (
                                          selectedTimeItems.includes(time.id)
                                        ) {
                                          setSelectedTimeItems(
                                            selectedTimeItems.filter(
                                              (id) => id !== time.id
                                            )
                                          );
                                        } else if (
                                          selectedTimeItems.length === 0
                                        ) {
                                          setSelectedTimeItems([time.id]);
                                        } else if (
                                          selectedTimeItems.length === 1
                                        ) {
                                          const prevTimeId =
                                            selectedTimeItems[0];
                                          const prevTimeIndex =
                                            timeTable.findIndex(
                                              (t) => t.id === prevTimeId
                                            );

                                          if (
                                            Math.abs(index - prevTimeIndex) ===
                                            1 ||
                                            Math.abs(index - prevTimeIndex) ===
                                            timeTable.length - 1
                                          ) {
                                            setSelectedTimeItems([
                                              ...selectedTimeItems,
                                              time.id,
                                            ]);
                                          } else {
                                            alert(
                                              "연속된 2개의 시간만 선택 가능합니다."
                                            );
                                          }
                                        } else {
                                          alert(
                                            "이미 2개의 시간을 선택하였습니다."
                                          );
                                        }

                                        // if (selectedTimeItems.includes(time.id)) {
                                        //   setSelectedTimeItems(selectedTimeItems.filter(id => id !== time.id));
                                        // } else if (selectedTimeItems.length < 2) {
                                        //   setSelectedTimeItems([...selectedTimeItems, time.id]);
                                        // } else {
                                        //   alert("장비당 1일 1인 최대 2타임(1타임=30분) 예약가능합니다.");
                                        // }
                                        if (time.is_reserved)
                                          return alert(
                                            "이미 예약된 시간입니다."
                                          );
                                        // if (selectedTimeItem?.id === time.id) {
                                        //   setSelectedTimeItem(null);
                                        // } else {
                                        //   setSelectedTimeItem(time);
                                        // }
                                        // handleTimeItemClick(time)
                                      }}
                                      type="button"
                                      // disabled={time.is_reserved}
                                      {...((time.is_reserved || (startDate === moment().format("YYYY-MM-DD") && moment().format("HH") > time.end_time.substring(0, 2))) && {
                                        disabled: true,
                                      })
                                      }
                                    >
                                      {time.start_time.substring(0, 5)}~
                                      {time.end_time.substring(0, 5)}
                                    </button>
                                  ))}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="d-flex align-items-center">
                            <h4 className="title mt--30 mt-sm--20">
                              개인정보 수집 이용 동의
                            </h4>
                          </div>
                          <p className="description mb--0">
                            - 항목 : 성명, 소속, 직위, 휴대전화, 작업내용, 이메일
                            <br />
                            - 개인 정보 제공 기관 : 창업진흥원, 부산대학교
                            산학협력단
                            <br />- 수집목적 : 프로그램 신청 및 운영, 사업보고
                            <br />- 보유기간 : 사업종료 후 5년
                          </p>
                          <div className="ml--10">
                            <span style={{ fontWeight: 700 }}>동의하기</span>
                            <input
                              style={{
                                position: "relative",
                                top: "13px",
                                left: "10px",
                              }}
                              type="checkbox"
                              className="chk"
                              //  style={{width:20, marginTop: 10}}
                              onChange={(e) =>
                                setPrivateCheck(e.target.checked)
                              }
                            />
                          </div>
                          <div className="blog-btn d-flex justify-content-end mt--30">
                            <button className="btn-default" type="submit">
                              예약하기
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Blog Details */}

      {/* Start Back To Top */}
      <ScrollTop />
      {/* End Back To Top */}
    </React.Fragment>
  );
};

export default EquipmentsDetail;
