import React, { useEffect, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import axiosInstance from "../axios/axiosInstance";
import BreadcrumbGray from "./common/BreadcrumbGray";
import LongPagination from "./blog/LongPagination";
import ScrollTop from "./common/ScrollTop";
import OnlineEducationList from "./list/OnlineEducationList";
import WholeSearch from "./blog/WholeSearch";
import Breadcrumb from "./common/Breadcrumb";

const OnlineEquipList = () => {
  // const [Edu, setEdu] = useState([]);

  const [search, setSearch] = useState("");
  const [items, setItems] = useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const [baseurl, setbaseurl] = useState("/equips/priortests/");
  const [currentpage, setcurrentpage] = useState(1);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [reserveData, setReserveData] = useState([]);
  const [searchSelect, setSearchSelect] = useState("title_only");
  const [searchKeyword, setSearchKeyword] = useState("");
  const handleSearchValue = (e) => setSearchKeyword(e.target.value);
  const [isLoading, setIsLoading] = useState(true);
  const postPerPage = 6;
  // const EduList = async () => {
  //     await axiosInstance.get("/equipedu/items/").then((response) => {
  //       setEdu(response.data.results);
  //     });
  //   };

  async function searchContents() {
    const { data } = await axiosInstance.get(baseurl + `?search=${searchKeyword}`);
    console.log(data);
    // setNotices(data.results);
    // setTotalCount(data.count);
    // setNext(data.next);
    // setPrevious(data.previous);

    // onChange(searchKeyword)
  }

  const handleFetch = async (url) => {
    const result = await axiosInstance.get(url);
    setItems(result.data.results);
    setNext(result.data.next);
    setPrevious(result.data.previous);
    setTotalCount(result.data.count);
    // const itemsWithReservationCheck = [];
    // for (const item of result.data.results) {
    //   const reservationResult = await axiosInstance.get(
    //     `/equipedu/reservation-check/${item.id}`
    //   );
    //   console.log(reservationResult);
    //   const newItem = {
    //     ...item,
    //     reservationResult: reservationResult.data, // 예약 확인 결과를 추가합니다.
    //   };
    //   itemsWithReservationCheck.push(newItem);
    // }
    // setReserveData(itemsWithReservationCheck);
    setIsLoading(false);
  };

  useEffect(() => {
    // EduList();
    handleFetch(baseurl);
  }, [baseurl]);

  // const paginate = (pageNumber) => {
  //     setCurrentPage(pageNumber);
  //   };

  //   const lastPostIndex = currentPage * postPerPage;
  //   const firstPostIndex = lastPostIndex - postPerPage;
  //   const currentEquip = Edu.slice(firstPostIndex, lastPostIndex);
  return (
    <React.Fragment>
      <PageHelmet pageTitle="Online Equipments Education" />

      {/* Start Breadcrump Area 
            <Breadcrumb title={'CREATION'} subtitle={'Specialized & Customized Open Innovation Hub For IoT'}  />
           End Breadcrump Area */}
      <Breadcrumb title="온라인 장비 교육" />

      <div className="designer-portfolio-area ptb--120 bg_color--1">
        <div className="container plr--80 plr_sm--30 plr_md--30">
          <OnlineEducationList items={items} isLoading={isLoading} />

          <div style={{ height: "10px" }} />
          <LongPagination
            search={search}
            items={items}
            previous={previous}
            base_url={baseurl}
            changeurl={handleFetch}
            setcurrentpage={setcurrentpage}
            totalcount={totalcount}
            perpage={6}
            next={next}
            currentpage={currentpage}
          />
          <WholeSearch setItems={setItems} onChange={setSearch} setNext={setNext} setPrevious={setPrevious} setTotalCount={setTotalCount} baseurl={baseurl} />
        </div>
      </div>

      {/* Start Back To Top */}
      <ScrollTop />
      {/* End Back To Top */}
    </React.Fragment>
  );
};

export default OnlineEquipList;
