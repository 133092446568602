import React, { useEffect, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { BsClipboard } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../axios/axiosInstance";
import Modal from "react-bootstrap/Modal";
import { IconBase } from "react-icons";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import ProfileCorrection from "./mypageTab/ProfileCorrection";
import ReservationManage from "./mypageTab/ReservationManage";
import BoardManage from "./mypageTab/BoardManage";
import ScrollTop from "../../elements/common/ScrollTop";

function Mypage() {
  const navigate = useNavigate();
  const [mypageData, setMypageData] = useState("");
  const [myQrModalVisible, setMyQrModalVisible] = useState(false);
  const [userId, setUserId] = useState("");
  const [oldPasswd, setOldPassWd] = useState("");
  const [passwd, setPassWd] = useState("");
  const [pwValid, setPwValid] = useState(false);
  const [newPwValid, setNewPwValid] = useState(false);
  const [passwdChk, setPassWdChk] = useState("");
  const [username, setUsername] = useState("");
  const [boards, setBoards] = useState([]);

  const [equipEdus, setEquipEdus] = useState([]);

  async function getMypageData() {
    const { data } = await axiosInstance.get("/users/auth/check/");
    setMypageData(data);
  }

  async function getBoardData() {
    const { data } = await axiosInstance.get("/users/my-qna-posts/");
    setBoards(data);
  }

  async function getEquipEduData() {
    const { data } = await axiosInstance.get(`/equips/testresults/?user__id=${mypageData.id}`);
    setEquipEdus(data);
  }

  useEffect(() => {
    getMypageData();
    getBoardData();
  }, []);

  useEffect(() => {
    if (mypageData) {
      getEquipEduData();
    }
  }, [mypageData]);

  const handlePw = (e) => {
    setOldPassWd(e.target.value);
    if (oldPasswd.length < 7) {
      return setPwValid(false);
    }
    setPwValid(true);
  };
  const handleNewPW = (e) => {
    setPassWd(e.target.value);
    if (passwd.length < 7) {
      return setNewPwValid(false);
    }
    setNewPwValid(true);
  };

  const passwdHandler = async (e) => {
    e.preventDefault();
    const body = {
      old_password: oldPasswd,
      new_password: passwd,
    };
    await axiosInstance
      .put("/users/change-password/", body)
      .then((res) => {
        if (res.status === 200) {
          alert("비밀번호가 수정되었습니다.");
          window.location = "/mypage";
        }
      })
      .catch((error) => {
        if (error.response.data.message === "new password is same as old password") {
          alert("새로운 비밀번호가 기존비밀번호와 같습니다.");
        } else {
          alert("기존비밀번호가 틀렸습니다.");
        }
      });
  };
  return (
    <div className="mypage-container pb--120 ">
      <Modal show={myQrModalVisible} onHide={() => setMyQrModalVisible(false)} dialogClassName="mypage-modal">
        <Modal.Header closeButton>
          <Modal.Title>내 QR코드</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <img src={mypageData.qr_code} alt="qr_code" style={{ width: "200px", height: "200px" }} />
          </div>
        </Modal.Body>
      </Modal>

      <div className="mypage-wrap  plr--50 plr_lg--50 plr_sm--30 plr_md--30">
        <div className="contentMypageSubtitle">{/* <h5 className="contentMypageboard"> 마이페이지</h5> */}</div>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col sm={2}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">MYQR코드</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">예약관리</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">게시물관리</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fourth">기본정보</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fifth">회원정보수정</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="sixth">비밀번호수정</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <h4>MYQR코드</h4>
                  <div className="text-center">
                    <img src={mypageData.qr_code} alt="qr_code" style={{ width: "200px", height: "200px" }} />
                    {/* <button className="btn btn-primary" onClick={() => setMyQrModalVisible(true)} style={{}}>
                      QR코드 보기
                    </button> */}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <h4>예약관리</h4>
                  <ReservationManage />
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <h4>Q&A 게시물</h4>
                  <BoardManage boards={boards} />
                </Tab.Pane>
                <Tab.Pane eventKey="fourth">
                  <h4>기본정보</h4>
                  <table>
                    <thead>
                      <tr>
                        <td>MyQR코드</td>
                        <td>
                          <button className="btn btn-primary" style={{ backgroundColor: "#F8BA10", border: "none" }} onClick={() => setMyQrModalVisible(true)}>
                            QR코드 보기
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>아이디</td>
                        <td>
                          <input defaultValue={mypageData?.user_id} disabled />
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>비밀번호</td>
                        <td style={{ paddingLeft: 10 }}>* * * * * * * * *</td>
                      </tr>
                      <tr>
                        <td>이름</td>
                        <td>
                          <input defaultValue={mypageData?.username} disabled />
                        </td>
                      </tr>
                      <tr>
                        <td>회원번호</td>
                        <td>
                          <input defaultValue={mypageData?.member_serial} disabled />
                        </td>
                      </tr>
                      <tr>
                        <td>소속(상세내용)</td>
                        <td>
                          <input value={mypageData?.classification + "(" + mypageData?.department + ")"} disabled />
                        </td>
                      </tr>
                      <tr>
                        <td>직급</td>
                        <td>
                          <input value={`${mypageData?.company_name}`} disabled />
                        </td>
                      </tr>
                      <tr>
                        <td>전화번호</td>
                        <td>
                          <input defaultValue={mypageData?.phone_num} disabled />
                        </td>
                      </tr>
                      <tr>
                        <td>이메일</td>
                        <td>
                          <input defaultValue={mypageData?.email} disabled />
                        </td>
                      </tr>
                      <tr>
                        <td>관심분야</td>
                        <td>
                          <input defaultValue={mypageData?.interest} disabled />
                        </td>
                      </tr>
                      <tr>
                        <td>이수한 장비교육</td>
                        <td>
                          {equipEdus.length > 0 ? (
                            <ul>
                              {equipEdus && equipEdus.map((equipEdu) => (
                                <li key={equipEdu.id}>{equipEdu.prior_test.title}</li>
                              ))}
                            </ul>
                          ) : (
                            <div>이수한 장비교육이 없습니다.</div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Tab.Pane>
                <Tab.Pane eventKey="fifth">
                  <h4>회원정보수정</h4>
                  <ProfileCorrection mypageData={mypageData} />
                </Tab.Pane>
                <Tab.Pane eventKey="sixth">
                  <h4>비밀번호수정</h4>
                  <table>
                    <thead>
                      <tr>
                        <td>기존비밀번호</td>
                        <td>
                          <input type="password" name="oldpasswd" onChange={handlePw} />
                        </td>
                      </tr>
                      {/* {!pwValid && oldPasswd.length > 0 && (
                        <tr>
                          <td></td>
                          <td style={{ color: "red", fontSize: 13 }}>
                            8자 이상 입력해주세요
                          </td>
                        </tr>
                      )} */}
                    </thead>
                    <tbody>
                      <tr>
                        <td>새로운 비밀번호</td>
                        <td>
                          <input type="password" name="newpasswd" onChange={handleNewPW} />
                        </td>
                      </tr>
                      {!newPwValid && passwd.length > 0 && (
                        <tr>
                          <td></td>
                          <td style={{ color: "red", fontSize: 13 }}>8자 이상 입력해주세요</td>
                        </tr>
                      )}
                      <tr>
                        <td>비밀번호 확인</td>
                        <td>
                          <input type="password" name="renewpasswd" onChange={(e) => setPassWdChk(e.target.value)} />
                        </td>
                      </tr>
                      {passwd !== passwdChk && passwdChk.length > 0 && (
                        <tr>
                          <td></td>
                          <td style={{ color: "red", fontSize: 13 }}>비밀번호가 일치하지않습니다.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="blog-btn d-flex justify-content-end mt--30">
                    <button className="btn-default" onClick={passwdHandler}>
                      수정하기
                    </button>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
        <ScrollTop />
      </div>
    </div>
  );
}

export default Mypage;
