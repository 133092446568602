import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axiosInstance from "../../../axios/axiosInstance";
const ProfileCorrection = ({ mypageData }) => {
  // const [id, setId] = useState(mypageData?.id);
  const [passwd, setPasswd] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [department, setDepartment] = useState("");
  const [interest, setInterest] = useState("");
  const [repasswd, setRepasswd] = useState("");
  //유효성 검사
  const [pwValid, setPwValid] = useState(false);
  const [EmailValid, setEmailValid] = useState(false);
  const [rank, setRank] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [others, setOthers] = useState("");
  const navigate = useNavigate();
  const [classification, setClassification] = useState([]);

  const handlePw = (e) => {
    setPasswd(e.target.value);
    if (passwd.length < 7) {
      return setPwValid(false);
    }
    setPwValid(true);
  };
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    const regex =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    if (regex.test(email.trim())) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  };
  const classificationList = async () => {
    await axiosInstance.get(`/users/classifications/`).then((res) => {
      // console.log(res)
      setClassification(res.data.data);
      
    });
  };


  const handleChangePW = async (e) => {
    e.preventDefault();
    const body = {
      access_token: localStorage.getItem("access_token"),
      classification_id: selectedOption, //소속
      company_name: rank, //직급
      department: department, //상세내용
      interest: interest, //관심사
      phone_num: phone, //핸드폰번호
      username: username, //사용자이름
      email: email,
    };

    await axiosInstance.post("/users/set-profile/", body)
    .then((res) => {if(res.status ===200)
      {alert("수정되었습니다."); console.log(res); window.location="/mypage";}})
    .catch((error)=>console.log(error));
  };
  useEffect(() => {
    classificationList();
    if(mypageData){
      setSelectedOption(mypageData?.classification_id);
    }
  }, [mypageData]);

  return (
    <>
      <table>
        <thead>
          <tr>
            <td>아이디</td>
            <td>
              <input defaultValue={mypageData?.user_id} disabled />
            </td>
          </tr>
          <tr>
            <td>이름</td>
            <td>
              <input
                defaultValue={mypageData?.username}
                disabled
              />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>소속</td>
            <td>
              <select
                value={selectedOption}
                onChange={handleOptionChange}
                className="select-belong"
              >
                {classification.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </td>
          </tr>
          <tr>
            <td>소속상세내용</td>
            <td>
              <input
                type="text"
                name="department"
                defaultValue={mypageData?.department}
                onChange={(e) => setDepartment(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>직급</td>
            <td>
              <input
                type="text"
                name="rank"
                defaultValue={mypageData?.company_name}
                onChange={(e) => setRank(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>전화번호</td>
            <td>
              <input
                type="text"
                name="phone"
                defaultValue={mypageData?.phone_num}
                onChange={(e) => setPhone(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>이메일</td>
            <td>
              <input
                type="text"
                name="email"
                defaultValue={mypageData?.email}
                onChange={handleEmail}
              />
            </td>
          </tr>
          {!EmailValid && email.length > 0 && (
            <tr>
              <td></td>
              <td style={{ color: "red" }}>이메일형식으로 입력해주세요</td>
            </tr>
          )}
          <tr>
            <td>관심분야</td>
            <td>
              <input
                type="text"
                name="interest"
                defaultValue={mypageData?.interest}
                onChange={(e) => setInterest(e.target.value)}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="blog-btn d-flex justify-content-end mt--30">
        <button className="btn-default" onClick={handleChangePW}>
          수정하기
        </button>
      </div>
      </>
  );
};

export default ProfileCorrection;
