import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { useAllGetData, useDelData, useGetUserId } from "../../../hooks/useApi.jsx";
import BreadcrumbGray from "../../../elements/common/BreadcrumbGray.jsx";
import Breadcrumb from "../../../elements/common/Breadcrumb.jsx";
function ReferenceDetail() {
  const location = useLocation();
  const boardId = location.pathname.slice(location.pathname.lastIndexOf("/") + 1);

  const [data, boardUserId] = useAllGetData("/board/materials", boardId);
  const [userId] = useGetUserId();
  const [contents, setContents, delContent] = useDelData("/board/materials", boardId, "/reference");

  return (
    <>
      <Breadcrumb title="자료실" />

      <div className="boardDetail  pt--50 pb--120">
        <div className="boardDetail-wrap">
          {data && (
            <table className="boardDetail">
              <thead>
                <tr>
                  <td colSpan={4}>
                    <strong>{data.title}</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={3}>작성자 : {data.user.username}</td>
                  <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>작성일 :{data.created_at.slice(2, 10)}</td>
                </tr>
                <tr>
                  <td>
                    첨부파일{" "}
                    {data.upfile ? (
                      <a href={data.upfile} target="_blank" rel="noopener noreferrer" className="downfile">
                        다운
                      </a>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <div className="ql-editor" dangerouslySetInnerHTML={{ __html: data.content }} />
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          <div className="boardBtn-wrap">
            {boardUserId === userId ? (
              <>
                <Link to={`/reference/update/${boardId}`}>
                  <button type="button" className="list">
                    수정
                  </button>
                </Link>
                <button type="button" className="del" onClick={delContent}>
                  삭제
                </button>
              </>
            ) : (
              <></>
            )}
            <Link to="/reference">
              <button type="button" className="list">
                목록
              </button>
            </Link>
          </div>
        </div>
        {/* <>    <h2>공지사항</h2></>
    
         {content.map((con, key) => {
          {
            return (
              <table className="boardDetail" key={key}>
                <thead>
                  <tr>
                    <td>SUBJECT</td>
                    <td>
                      <strong>{con.title}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>WRITER</td>
                    <td>shoes shop</td>
                  </tr>
                  <tr>
                    <td>DATE</td>
                    <td style={{ color: "grey" }}>{con.regdate}</td>
                    <td>VIEW</td>
                    <td style={{ color: "grey" }}>8207</td>
                  </tr>
                </thead>
                <tbody>
                  <td
                    colSpan={"4"}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <p style={{ marginTop: "20px", marginBottom: "20px" }}>
                      {con.content}
                    </p>
                  </td>
                </tbody> 
              </table>
            );
          }
        })}

        <button
          onClick={() => {
            navigate(-1);
          }}
          className="listBox"
        >
          LIST
        </button>*/}
      </div>
    </>
  );
}

export default ReferenceDetail;
