import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PageHelmet from "../../../component/common/Helmet";
import { FaPaperclip } from "react-icons/fa";
import Pagination from "../../../elements/common/Pagination";
import Search from "../../../elements/blog/Search";
import useResize from "../../../hooks/useResize";
import axiosInstance, { API_HOST } from "../../../axios/axiosInstance";
import ScrollTop from "../../../elements/common/ScrollTop";
// import { useRecoilState } from "recoil";
// import { TokenAtom } from "../../recoil/User.js";
function Board() {
  const location = useLocation();
  const base_url = `${API_HOST}/board/boards/`;
  const [notices, setNotices] = useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const [baseurl, setbaseurl] = useState(base_url);
  const [currentpage, setcurrentpage] = useState(1);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [loading, setLoading] = useState(true);
  const perpage = 20;
  const windowWidth = useResize();
  const [search, setSearch]= useState("");
  function changeurl(event) {
    setcurrentpage(1)
    setbaseurl(event);
  }
  async function getNotices() {
    setLoading(true);
    const { data } = await axios.get(baseurl);
    setNotices(data);
    setTotalCount(data.count);
    setNext(data.next);
    setPrevious(data.previous);
    setLoading(false);
  }

  useEffect(() => {
    getNotices(baseurl);

  }, [baseurl]);

  return (
    <React.Fragment>
      <PageHelmet pageTitle="Board" />
      <div className="rn-page-title-area ptb--100" data-black-overlay="7">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="blog-single-page-title text-center pt--100">
                                <h2 className="title theme-gradient">자유게시판</h2>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
    <div className="board-container pt--50 pb--120">
        <div className="board-wrap">
          <div className="boardwrite">
            <Link to="/board/insert">
              <button className="boardwrite-btn" type="button">
                글쓰기
              </button>
            </Link>
          </div>
          {windowWidth > 768 ? (
            <>
              <table className="board">
                <thead>
                  <tr>
                    <td>
                      <strong>번호</strong>
                    </td>
                    <td>
                      <strong>제목</strong>
                    </td>
                    <td>
                      <strong>작성자</strong>
                    </td>
                    <td>
                      <strong>파일</strong>
                    </td>
                    <td>
                      <strong>등록일</strong>
                    </td>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                      <tr>
                    <td colSpan={5} >Loading...</td>
                    </tr>
                  ) : (
                    <>
                      {notices && notices.length ? (
                        notices.map((notice, key) => {
                          return (
                            <tr key={key}>
                              <td>
                                {notice.row_number}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                <Link to={`detail/${notice.id}`}>
                                    {notice.title}
                                </Link>
                              </td>
                              <td>{notice.user.username}</td>
                              <td>
                                {notice.upfile ? (
                                  <span>
                                    <FaPaperclip />
                                  </span>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>{notice.created_at.slice(2, 10)}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={5}>등록된 글이 없습니다</td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
              <div className="boardBottom">
                <Pagination
                  notices={notices}
                  previous={previous}
                  base_url={base_url}
                  search={search}
                  changeurl={changeurl}
                  setcurrentpage={setcurrentpage}
                  currentpage={currentpage}
                  totalcount={totalcount}
                  perpage={perpage}
                  next={next}
                />
                <Search
                baseurl={base_url}
                onChange={setSearch}
                setNotices={setNotices}
                setTotalCount={setTotalCount}
                setNext={setNext}
                setPrevious={setPrevious}
                />
              </div>
            </>
          ) : (
            <>
              <table className="board">
                <thead>
                  <tr>
                    <td>
                      <strong>번호</strong>
                    </td>
                    <td>
                      <strong>제목</strong>
                    </td>
                    <td>
                      <strong>작성자</strong>
                    </td>
                    <td>
                      <strong>등록일</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {notices && notices.length ? (
                    notices.map((notice, key) => {
                      return (
                        <tr key={key}>
                          <td>
                            {totalcount - perpage * (currentpage - 1) - key}
                          </td>
                          <td style={{ textAlign: "left" }}>
                            <Link to={`detail/${notice.id}`}>
                                {notice.title}
                            </Link>
                          </td>
                          <td>{notice.user.name}</td>
                          <td>{notice.created_at.slice(5, 10)}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5}>등록된 글이 없습니다</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="boardBottom">
                <div className="d-colum w-100">
                  <div className="bottompagination">
                    <Pagination
                      notices={notices}
                      previous={previous}
                      base_url={base_url}
                      changeurl={changeurl}
                      setcurrentpage={setcurrentpage}
                      currentpage={currentpage}
                      totalcount={totalcount}
                      perpage={perpage}
                      next={next}
                    />
                  </div>
                  <div className="searchSBox">
                   <Search
                    baseurl={baseurl}
                    setNotices={setNotices}
                    setTotalCount={setTotalCount}
                    setNext={setNext}
                    setPrevious={setPrevious}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
      
        </div>
        
        <ScrollTop/>
    </div>
    </React.Fragment>

  );
}

export default Board;
