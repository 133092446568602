import React, { useEffect, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import axiosInstance from "../axios/axiosInstance";
import EducationList from "./list/EducationList";
import BreadcrumbGray from "./common/BreadcrumbGray";
import LongPagination from "./blog/LongPagination";
import ScrollTop from "./common/ScrollTop";
import WholeSearch from "./blog/WholeSearch";
import Breadcrumb from "./common/Breadcrumb";

const Education = () => {
  const [reserveData, setReserveData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [items, setItems] = useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const [baseurl, setbaseurl] = useState("/educations/items/");
  const [currentpage, setcurrentpage] = useState(1);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const postPerPage = 6;
  // const EduList = async () => {
  //     await axiosInstance.get("/educations/items/").then((response) => {
  //       setEdu(response.data.results);
  //     });
  //   };
  //   console.log(Edu)

  const handleFetch = async (url) => {
    const result = await axiosInstance.get(url);
    try {
      setItems(result.data.results);
      setNext(result.data.next);
      setPrevious(result.data.previous);
      setTotalCount(result.data.count);
      // const itemsWithReservationCheck = [];
      //     for (const item of result.data.results) {
      //         const reservationResult = await axiosInstance.get(`/educations/reservation-check/${item.id}`);
      //         const newItem = {
      //             ...item,
      //             reservationResult: reservationResult.data, // 예약 확인 결과를 추가합니다.
      //           };
      //           itemsWithReservationCheck.push(newItem);
      //   };
      //   setReserveData(itemsWithReservationCheck);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // EduList();
    handleFetch(baseurl);
  }, [baseurl]);

  return (
    <React.Fragment>
      <PageHelmet pageTitle="Education" />

      <Breadcrumb title="교육프로그램" />

      <div className="designer-portfolio-area ptb--120 bg_color--1">
        <div className="container plr--80 plr_sm--30 plr_md--30">
          <EducationList items={items} isLoading={isLoading} />
          <LongPagination
            search={search}
            items={items}
            previous={previous}
            base_url={baseurl}
            changeurl={handleFetch}
            setcurrentpage={setcurrentpage}
            totalcount={totalcount}
            perpage={6}
            next={next}
            currentpage={currentpage}
          />
          <WholeSearch setItems={setItems} setNext={setNext} setPrevious={setPrevious} setTotalCount={setTotalCount} baseurl={baseurl} onChange={setSearch} />
        </div>
      </div>
      {/* Start Back To Top */}
      <ScrollTop />
      {/* End Back To Top */}
    </React.Fragment>
  );
};

export default Education;
