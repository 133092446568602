import React, { Component, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axios/axiosInstance";
import ScrollTop from "./common/ScrollTop";


const ToolsDetail = () => {
  const [Check, setCheck] = useState(false);
  const [tool, setTool] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const {id} = useParams();
  const equipSubmit = async (e) => {
    // e.preventDefault();
    // if (!Check) {
    //   alert("장비대여 필수 동영상을 보셨나요?.");
    // } else {
      navigate("/checkout");
    // }
  };

  // const ToolDetail = async () => {
  //   await axiosInstance.get(`/equips/items/${equipId}`).then((res) => {
  //     setEquip(res.data);
  //   });
  // };

  const toolList = async () => {
    await axiosInstance
      .get(`/worktools/items/${id}`)
      .then((res) => {
        setTool(res.data);
      });
  };
  console.log(tool)
  useEffect(() => {
    // EquipDetail();
    toolList();
  }, []);

  return (
    <React.Fragment>
      {/* Start Equipments Details */}
      <div className="rn-blog-details ptb--70 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  <div className="blog-single-list-wrapper">
                    <div className="row mt--20">
                      <div className="thumbnail w-100 pt--40 pb--80 text-center col-lg-12 col-md-12 col-sm-12">
                        <img
                          src={tool.image}
                          alt="Eq Images"
                          className="img-fluid"
                        />
                        {/* <span>파우더를 이용한 3D프린터</span> */}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-sm-12 content mb--20">
                        <h4 className="title">{tool.name}</h4>
                        <ul className="list-style">
                          <li>사용가능수량 : {tool.rentable_quantity}대 </li>
                          <li>장비 설명</li>
                          <div
                            style={{
                              whiteSpace: "pre-wrap",
                              paddingLeft: 10,
                              marginTop: 10,
                            }}
                          >
                            {tool.descript}
                          </div>
                        </ul>
                        <h4 className="title">V-SPACE 공구 대여 안내</h4>
                        <p>
                          * 현장신청과 당일 반납
                          <br />* 개인 QR코드 V-SPACE입구에서 찍고, 공구 QR코드를 찍으면 대여가 가능합니다.
                          <br />* 대여반납 : 반납할 때, 개인 QR코드 찍기
                          <br />궁금하거나 더 문의하시고 싶으신 바 있으시면, V-SPACE (051-510-3261)로 연락바랍니다.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="blog-btn d-flex justify-content-end mt--30">
                  <Link to="/tools">   <button className="rn-btn" >
                      목록으로
                    </button></Link>
                  </div>
                  {/* 
                    <div className="video-wrapper position-relative pt--45 mb--40 ">
                      <div className="thumbnail w-100">
                        <img
                          src="/assets/images/blog/bl-big-01.jpg"
                          alt="Blog Images"
                          style={{width:'100%'}}
                        />
                      </div>
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="ZOoVOfieAF8"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button
                        className="video-popup position-top-center"
                        onClick={this.openModal}
                      >
                        <span className="play-icon"></span>
                      </button>
                    </div> */}
                  {/* <p className="mb--0">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet. It re are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injectedeed eedhumour, or randomised words which don't look even slightly believable.</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      {/* End Blog Details */}

      {/* Start BLog Comment Form 
                <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <div className="section-title">
                                        <span className="subtitle">Have a Comment?</span>
                                        <h2 className="title">Leave a Reply</h2>
                                    </div>
                                    <form className="mt--40" action="#">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Name" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="email" placeholder="Email" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Website" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <textarea type="text" placeholder="Comment"></textarea>
                                                </div>
                                                
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="blog-btn mt--30">
                                                    <Link className="btn-default" to="/blog-details"><span>SEND MESSAGE</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                End BLog Comment Form  */}

      {/* Start Back To Top */}
     <ScrollTop/>
      {/* End Back To Top */}
    </React.Fragment>
  );
};

export default ToolsDetail;
