import React, { useEffect, useState } from "react";
import axiosInstance from "../../../axios/axiosInstance";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import imageCompression from "browser-image-compression";

const ReservationTable = (props) => {
  const {
    titlename,
    clickedTab,
    num,
    datas,
    onDelete,
    setFile,
    workHandler,
    setReservedData,
    firstModal,
    setFirstModal,
    extendDay,
    currentDate,
    setSelectedDataId,
    selectedDataId
  } = props;
  const navigate = useNavigate();
  const [preview, setPreView] = useState(null);
  const dataLength = datas.length;
  const [showButton, setShowButton] = useState(true);

  const DelData = async (url, id) => {
    await axiosInstance
      .delete(`${url}/${id}/`)
      .then((res) => {
        if (res.status === 200) {
          alert("예약 취소되었습니다.");
          // window.location="/mypage";
          onDelete();
        }
      })
      .catch((error) => console.log(error));
  };

  const cancleHandler = async (e, id, titlename) => {
    e.preventDefault();
    let url = "";
    switch (titlename) {
      case "시설":
        url = "facilities/rentals";
        break;
      case "장비":
        url = "equips/reservations";
        break;
      case "공구":
        url = "worktools/rentals";
        break;
      case "온라인장비교육":
        url = "equipedu/reservations";
        break;
      case "교육프로그램":
        url = "educations/reservations";
        break;
      case "행사":
        url = "eventedu/reservations";
        break;
      case "멘토링":
        url = "mentorings/reservations";
        break;
      case "투어":
        url = "tours/reservations";
        break;
    }

    DelData(url, id);
  };
  const renderDetail = (data, titlename) => {
    switch (titlename) {
      case "시설":
        return data.facility?.name;
      case "장비":
        return `${data.equipment?.name} [${data.reservation_device?.name}]`;
      case "공구":
        return data.eachworktool?.worktool?.name;
      case "온라인장비교육":
      case "교육프로그램":
      case "행사":
        return data.education?.name;
      case "멘토링":
        return data.mentoring?.name;
      case "투어":
        return data.tour?.name;
      default:
        return "";
    }
  };
 console.log(datas)
  const dateData = (data, titlename) => {
    switch (titlename) {
      case "시설":
        return data.rental_date;
      case "장비":
      case "멘토링":
      case "투어":
        return data.reservation_date;
      case "공구":
        return data.rental_at === null
          ? "승인중"
          : data.rental_at?.slice(0, 10);
      case "온라인장비교육":
      case "교육프로그램":
      case "행사":
        return data.education?.start_date;
      default:
        return data.reservation_date;
    }
  };

  const timeData = (data, titlename) => {
    switch (titlename) {
      case "시설":
        return `${data.rental_time?.start_time.slice(0, 5)}~${data.rental_time?.end_time.slice(0, 5)}`;
      case "장비":
        return `${data.reservation_start_time.slice(0, 5)}~${data.reservation_end_time.slice(0, 5)}`;
      case "멘토링":
      case "투어":
        return `${data.reservation_time?.start_time.slice(0, 5)}~${data.reservation_time?.end_time.slice(0, 5)}`;
      case "공구":
        return data.rental_at === null
          ? "승인중"
          : data.rental_at?.slice(12, 16);
      case "온라인장비교육":
      case "교육프로그램":
      case "행사":
        return `${data.education?.start_time.slice(0, 5)}~${data.education?.end_time.slice(0, 5)}`;
      default:
        return `${data.reservation_time?.start_time.slice(0, 5)}~${data.reservation_time?.end_time.slice(0, 5)}`;
    }
  };

  const imageCompress = async (e) => {
    let file = e.target?.files[0];
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 720,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(file, options);
      const promise = imageCompression.getDataUrlFromFile(compressedFile);
      promise.then((result) => {
        // Base64 문자열을 Blob 객체로 변환
        const byteString = atob(result.split(',')[1]);
        const mimeString = result.split(',')[0].split(':')[1].split(';')[0];
        const byteNumbers = new Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
          byteNumbers[i] = byteString.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: mimeString });
  
        // Blob 객체를 File 객체로 변환
        const newFile = new File([blob], file.name, { type: mimeString });
        
        // File 객체를 상태에 저장
        setFile(newFile);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = (e) => {
    // const se
    // setFile(e.target.files[0]);
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileTypes = [
        "image/apng",
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "image/svg+xml",
        "image/tiff",
        "image/webp",
        "image/x-icon",
      ];
      if (fileTypes.includes(selectedFile.type)) {
        imageCompress(e);
      } else {
        alert("이미지 파일만 업로드 가능합니다.");
      }
    }
  };

  const handleOpenModal = (id, date) => {
    setFirstModal(true);
    setSelectedDataId(id);
    setReservedData(date);
    console.log(id)

  };
  console.log(selectedDataId)
  return (
    <>
      {/* <Modal size="sm" show={firstModal} onHide={()=>setFirstModal(false)}  aria-labelledby="example-modal-sizes-title-sm"
>
    <Modal.Body >
     <h1>모달</h1>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={()=>setFirstModal(false)}>
        Close
      </Button>
    </Modal.Footer>
  </Modal> */}

      <Modal
        size="sm"
        show={firstModal}
        onHide={() => setFirstModal(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            작업물 등록
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="file"
            style={{ border: "none" }}
            accept=".jpeg, .jpg, .png"
            onChange={handleFileChange}
          />
          {preview && (
            <div>
              <p className="mb--5">선택한 파일 미리 보기</p>
              <img src={preview} alt="미리 보기" style={{ height: 300 }} />
            </div>
          )}
          <div className="blog-btn d-flex justify-content-end mt--30 mb--0">
            <button
              className="btn-default"
              onClick={() => workHandler(selectedDataId)}
            >
              등록하기
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <div
        className={
          clickedTab === num
            ? "reservation-content active-content"
            : "reservation-content"
        }
      >
        <div>
          <table className="reservation-table">
            <thead>
              <tr>
                <th>번호</th>
                <th>{titlename}</th>
                <th>예약날짜</th>
                <th>예약시간</th>
                <th>상태</th>
                {titlename === "장비" ? <th>작업물등록</th> : <></>}
              </tr>
            </thead>
            {datas && datas.length > 0 ? (
              <>
                {datas.map((data, index) => (
                  <tbody key={index}>
                    <tr>
                      <td>
                        {index+1}
                      </td>
                      <td>{renderDetail(data, titlename)}</td>
                      <td>{dateData(data, titlename)}</td>
                      <td>{timeData(data, titlename)}</td>
                      <td>
                        {titlename === "시설" && 
                          <>
                            <span>{data.state}</span>
                            {data.state === "승인" && <>
                              <button
                                className="cancle-btn ms-2"
                                onClick={(e) =>
                                  cancleHandler(e, data.id, titlename)
                                }
                              >
                                취소
                              </button>
                              </>
                            } 
                          </>}

                        {titlename === "공구" ? (
                          <>
                            {data.state === "대여중" ? (
                              <button
                                className="extend-btn"
                                onClick={() => extendDay(data.id)}
                              >
                                하루연장
                              </button>
                            ) : (
                              <span>{data.state}
                              {data.state === "요청" && <>
                              <button
                                className="cancle-btn ms-2"
                                onClick={(e) =>
                                  cancleHandler(e, data.id, titlename)
                                }
                              >
                                취소
                              </button>
                              </>
                              }
                              </span>
                              
                            )}
                          </>
                        ) : (
                          <>
                            {(data.state === "요청" || data.state === "승인")? (
                              <button
                                className="cancle-btn"
                                onClick={(e) =>
                                  cancleHandler(e, data.id, titlename)
                                }
                              >
                                취소
                              </button>
                            ) : null}
                            {data.state === "회원취소" ? (
                              <span> 취소완료</span>
                            ) : null}
                            {["종료", "사용"].includes(data.state) ? (
                              <span>완료</span>
                            ) : null}
                            {data.state === "기관취소" ? (
                              <span>기관취소</span>
                            ) : null}
                            {data.state === "노쇼" ? (
                              <span>노쇼</span>
                            ) : null}
                          </>
                        )}
                      </td>
                      {titlename === "장비" ? (
                        <>
                          {data.state === "종료" ? (
                            <td>
                              <span>완료</span>
                            </td>
                          ) : null}
                          {data.state === "회원취소" ? (
                            <td>
                              <span>회원취소</span>
                            </td>
                          ) : null}
                          {["승인", "사용", "요청", "종료"].includes(data.state) ? (
                            <>
                              {data.project_post_image !== null ? (
                                <td>
                                  <div className="d-flex justify-content-around align-items-center">
                                  <span>등록됨</span> 
                                  <button
                                    onClick={() =>
                                      handleOpenModal(
                                        data.id,
                                        data.reservation_date
                                      )
                                    }
                                    className="work-register-btn"
                                  >
                                    새로등록
                                  </button>
                                  </div>
                                </td>
                              ) : (
                                (data.state === "사용" || data.state === "종료") && currentDate >= data.reservation_date?
                                <td>
                                  <button
                                    className="work-register-btn"
                                    onClick={() =>
                                      handleOpenModal(
                                        data.id,
                                        data.reservation_date
                                      )
                                    }
                                  >
                                    등록
                                  </button>
                                </td>
                                :<td><span>등록불가</span></td>
                              )}
                            </>
                          ) : null}
                        </>
                      ) : (
                        <></>
                      )}
                    </tr>
                  </tbody>
                ))}
              </>
            ) : (
              <tbody>
                 {titlename === "장비" ? ( <tr>
                  <td colSpan={6}>예약 데이터가 없습니다</td>
                </tr>):<tr><td colSpan={5}>예약 데이터가 없습니다</td></tr>}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default ReservationTable;
