import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance, { API_HOST } from "../../../axios/axiosInstance";
import BreadcrumbGray from "../../../elements/common/BreadcrumbGray";
import Pagination from "../../../elements/blog/Pagination";
import Search from "../../../elements/blog/Search";
import useResize from "../../../hooks/useResize";
import { isLoginSelector } from "../../../recoil/User";
import { useRecoilValue } from "recoil";
import { FiLock } from "react-icons/fi";
import { useAllGetData, useGetUserId } from "../../../hooks/useApi";
import { BsArrowReturnRight } from "react-icons/bs";
import ScrollTop from "../../../elements/common/ScrollTop";
import Accordion from "react-bootstrap/Accordion";
import Breadcrumb from "../../../elements/common/Breadcrumb";

function QNABoard() {
  const [qnanotices, setQnaNotices] = useState([]);
  const base_url = `${API_HOST}/board/qnaboards/`;
  const isLogin = useRecoilValue(isLoginSelector);
  const [totalcount, setTotalCount] = useState(0);
  const [baseurl, setbaseurl] = useState(base_url);
  const [currentpage, setcurrentpage] = useState(1);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const perpage = 20;
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const windowWidth = useResize();
  const [userId, isAdmin, writer] = useGetUserId();
  const [fnqDatas, setFnqDatas] = useState([]);

  function changeurl(event) {
    setbaseurl(event);
  }
  async function getQnaNotices() {
    const { data } = await axios.get(`${API_HOST}/board/qnaboards/`);
    setQnaNotices(data.results);
    setNext(data.next);
    setPrevious(data.previous);
    setTotalCount(data.count);
  }

  const getFnQNotices = async () => {
    await axiosInstance.get(`/board/faqs/`).then((res) => {
      setFnqDatas(res.data.results);
    });
  };
  useEffect(() => {
    getQnaNotices();
    getFnQNotices();
  }, []);

  const handleWrite = (e) => {
    e.preventDefault();
    if (!isLogin) {
      alert("로그인이 필요합니다");
      window.location = "/login";
      return;
    }
    navigate("/qnaboard/insert");
  };

  const privateHandler = (writerId, qnaId) => {
    try {
      if (writerId === userId || isAdmin === "관리자") {
        navigate(`detail/${qnaId}`);
      } else {
        window.alert("비밀글입니다.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const ReplyHandler = (writerId, qnaId) => {
    try {
      if (writerId === userId || isAdmin === "관리자") {
        navigate(`detail/${qnaId}`);
      } else {
        window.alert("비밀글입니다.");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Breadcrumb title="Q&A" />

      <div className="board-container pt--50 pb--120">
        <div className="fnqboard-wrap pb--50">
          <h5>자주 묻는 질문(FAQ)</h5>

          <Accordion className="mb-2">
            {fnqDatas ? (
              <>
                {fnqDatas.map((fnq, index) => (
                  <Accordion.Item eventKey={index} className="item">
                    <Accordion.Header className="accordionHead">{fnq.title}</Accordion.Header>
                    <Accordion.Body style={{ textAlign: "left" }}>{fnq.content}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </>
            ) : (
              <p style={{ paddingLeft: 10, paddingTop: 5, paddingBottom: 5 }}>해당 데이터가 없습니다</p>
            )}
          </Accordion>
        </div>
        <div className="board-wrap">
          <div className="boardwrite">
            {/* <Link to="/qnaboard/insert">  */}
            <button className="boardwrite-btn" type="button" onClick={(e) => handleWrite(e)}>
              글쓰기
            </button>
            {/* </Link> */}
          </div>
          <table className="qboard">
            <thead>
              <tr>
                <td>
                  <strong>제목</strong>
                </td>
                <td>
                  <strong>작성자</strong>
                </td>
                <td>
                  <strong>등록일</strong>
                </td>
              </tr>
            </thead>
            <tbody>
              {qnanotices && qnanotices.length > 0 ? (
                qnanotices.map((notice, key) => {
                  return (
                    <>
                      <tr key={key}>
                        {/* <td>{key+1}</td> */}
                        <td>
                          {notice.is_private ? (
                            <>
                              <Link to={null}>
                                <span onClick={() => privateHandler(notice.user.id, notice.id)}>
                                  <FiLock /> 비밀글입니다
                                </span>
                              </Link>
                              {isAdmin === "관리자" && (
                                <>
                                  {notice.child?.[0] ? (
                                    <></>
                                  ) : (
                                    <>
                                      <Link to={`/qnaboard/insert?id=${notice.id}&isPrivate=true`}>
                                        <span className="qna-reply-button">답글달기</span>
                                      </Link>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <Link to={`/qnaboard/detail/${notice.id}`}>{notice.title}</Link>
                              {isAdmin === "관리자" && (
                                <>
                                  {notice.child?.[0] ? (
                                    <></>
                                  ) : (
                                    <>
                                      <Link to={`/qnaboard/insert?id=${notice.id}`}>
                                        <span className="qna-reply-button">답글달기</span>
                                      </Link>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </td>
                        <td>{notice.user?.username}</td>
                        <td>{notice.created_at.slice(2, 10)}</td>
                      </tr>
                      {notice.child && (
                        <tr>
                          <td>
                            <BsArrowReturnRight />
                            <span
                              style={{
                                color: "rgb(16, 56,172)",
                                marginRight: 10,
                                marginLeft: 10,
                              }}
                            >
                              답변
                            </span>
                            {notice.child[0].is_private ? (
                              <>
                                <Link to={null}>
                                  <span onClick={() => ReplyHandler(notice.user.id, notice.child[0].id)}>
                                    <FiLock /> 비밀글입니다
                                  </span>
                                </Link>
                              </>
                            ) : (
                              <>
                                <Link to={`/qnaboard/detail/${notice.child[0].id}`}>{notice.child[0].title}</Link>
                              </>
                            )}
                          </td>
                          <td>{notice.child[0].user.username}</td>
                          <td>{notice.child[0].created_at.slice(2, 10)}</td>
                        </tr>
                      )}
                    </>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={3} className="text-center">
                    등록된 글이 없습니다
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {windowWidth > 768 ? (
            <>
              <div className="boardBottom">
                <Pagination
                  notices={qnanotices}
                  previous={previous}
                  base_url={base_url}
                  search={search}
                  changeurl={changeurl}
                  setcurrentpage={setcurrentpage}
                  currentpage={currentpage}
                  totalcount={totalcount}
                  perpage={perpage}
                  next={next}
                />
                <Search baseurl={base_url} onChange={setSearch} setNotices={setQnaNotices} setTotalCount={setTotalCount} setNext={setNext} setPrevious={setPrevious} />
              </div>
            </>
          ) : (
            <>
              <div className="boardBottom">
                <div className="d-colum w-100">
                  <div className="bottompagination">
                    <Pagination
                      notices={qnanotices}
                      previous={previous}
                      base_url={base_url}
                      changeurl={changeurl}
                      setcurrentpage={setcurrentpage}
                      currentpage={currentpage}
                      totalcount={totalcount}
                      perpage={perpage}
                      next={next}
                    />
                  </div>
                  <div className="searchSBox">
                    <Search baseurl={baseurl} setNotices={setQnaNotices} setTotalCount={setTotalCount} setNext={setNext} setPrevious={setPrevious} onChange={setSearch} />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <ScrollTop />
      </div>
    </>
  );
}

export default QNABoard;
