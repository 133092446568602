import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import QuillEditor from "../../../elements/blog/QuillEditor";
import { useGetUserId, usePostData } from "../../../hooks/useApi";
import { TokenAtom } from "../../../recoil/User";
import { useRecoilState } from "recoil";
import imageCompression from "browser-image-compression";

function BoardInsert() {
  const location = useLocation();

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [username, setUsername] = useState("");
  const boardId = location.pathname.slice(
    location.pathname.lastIndexOf("/") + 1
  );
  const [userId, isAdmin, writer] = useGetUserId();
  const [file, setFile] = useState(null);
  // const postFormData = useFormPostData("/board/boards/", "/board");
  const postData = usePostData("/board/boards/", "/board");
  const [accessToken, setAccessToken] = useRecoilState(TokenAtom);
 
 
  const HandleForm = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("title", title);
  
    formData.append("username", writer);
    formData.append("content", content);
    formData.append("upfile", file);
    const processedContent = content
      .replace(/<p>/g, "")
      .replace(/<\/p>/g, "")
      .trim();

   
    if (title.trim() === "") {
      window.alert("제목을 입력해주세요");
      return;
    }
    if (content === "<p><br></p>" || processedContent === "") {
      window.alert("내용을 입력해주세요.");
      return;
    }

      await postData(formData)
  };

  const handleText = (value) => {
    setContent(value);
  };
  const handleFileChange = (e) => {
    // const se
    // setFile(e.target.files[0]);
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileTypes = [
        "image/apng",
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "image/svg+xml",
        "image/tiff",
        "image/webp",
        "image/x-icon",
      ];
      if (fileTypes.includes(selectedFile.type)) {
        imageCompress(e);
      } else {
        alert("이미지 파일만 업로드 가능합니다.");
      }
    }
  };

  const imageCompress = async (e) => {
    let file = e.target?.files[0];
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 720,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(file, options);
      const promise = imageCompression.getDataUrlFromFile(compressedFile);
      promise.then((result) => {
        // Base64 문자열을 Blob 객체로 변환
        const byteString = atob(result.split(',')[1]);
        const mimeString = result.split(',')[0].split(':')[1].split(';')[0];
        const byteNumbers = new Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
          byteNumbers[i] = byteString.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: mimeString });
  
        // Blob 객체를 File 객체로 변환
        const newFile = new File([blob], file.name, { type: mimeString });
        
        // File 객체를 상태에 저장
        setFile(newFile);
      });
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {}, []);
  return (
    <>
      <div className="rn-page-title-area ptb--100" data-black-overlay="7">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-single-page-title text-center pt--100">
                <h2 className="title theme-gradient">자유게시판</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="boardInsert pt--50 pb--120">
        <div className="boardInsert-wrap">
          <form method="post" onSubmit={HandleForm}>
            <table className="boarderInsertTable">
              <thead>
                <tr>
                  <td className="boardRow">
                    <strong>제목</strong>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="title"
                      placeholder="제목을 입력해주세요"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="boardRow">
                    <strong>작성자</strong>
                  </td>
                  <td>
                    <strong style={{ marginLeft: 10 }}>{writer}</strong>
                  </td>
                </tr>
                <tr>
                  <td className="boardRow">
                    <strong>내용</strong>
                  </td>
                  <td className="QnaBoardcontent">
                    {/* <EditorToolbar/> */}
                    <QuillEditor value={content} onChange={handleText} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>파일</strong>
                  </td>
                  <td>
                    <input
                      type="file"
                      name="file"
                      onChange={handleFileChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="QNABoardBtnGrp">
              <button type="submit">저장</button>
              <Link to="/board">
                <button type="button" className="list">
                  목록
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default BoardInsert;
