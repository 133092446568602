import React, { useState } from "react";
import PageHelmet from "../../component/common/Helmet";
import Breadcrumb from "../common/Breadcrumb";
import ScrollTop from "../common/ScrollTop";

const FacilityUtilize = () => {
  return (
    <React.Fragment>
      <PageHelmet pageTitle="Eduction Details" />
      <Breadcrumb title={"공간 이용 시"} />

      {/* Start Portfolio Details */}
      <div className="rn-portfolio-details ptb--100 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-details">
                <div className="row row--35 mb--50">
                  <div className="col-lg-12">
                    <div className="inner plr_sm--10">
                      <div className="section-title">
                        <h3 className="use-title">공간 이용 방법</h3>
                        <div className="portfolio-details mt--50 mt_md--40 mt_sm--40">
                          <div className="inner">
                            <div className="section-title">
                              <p className="description">
                                회원가입 - 상단메뉴바 층별안내도 확인 -
                                이용하고자 하는 공간 선정 - 상단메뉴바 시설예약
                                진행 - 시설예약 작성 작성 - 관리자 승인 - 해당건
                                QR코드 생성 - 현장확인
                              </p>
                              <div>
                                <h5 className="mb--5">
                                  공간에 비치된 장비 사용시
                                </h5>
                              </div>
                              <p className="description">
                               - 공간예약 시 기본적으로 사용가능한 장비가 포함,
                                관리자 안내에 따라 그 외 장비 현장예약 후
                                사용가능 (장비 이용 시 페이지 확인)
                              </p>
                              <div>
                                <h5 className="mb--5">공간 예약 안내</h5>
                              </div>
                              <p className="description">
                                - 공간당 1일 1회 예약 가능 <br/> (1일 이상 사용 시
                                관리자에게 전화문의)
                                <br /> - 관리자의 판단하에 공간 사용 목적이
                                부적합할 시 예약 취소
                                <br /> - 이용자 부주의로 인한 시설 및 장비 파손
                                시 원상복구
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Details */}

      {/* Start Back To Top */}
      <ScrollTop/>
      {/* End Back To Top */}
    </React.Fragment>
  );
};

export default FacilityUtilize;
