import React from 'react';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

const CustomPrevArrow = (props) => (
  <div onClick={props.onClick}
  style={{
    position: 'absolute',
    top: '48%', // 수직 중앙 정렬
    left: '10px', // 원하는 가로 위치
    transform: 'translateY(-50%)', // 세로 중앙 정렬을 위한 변환
    zIndex:3,
    fontSize: '35px',
    cursor:'pointer'
  }}>
    <IoIosArrowBack />
  </div>
);

const CustomNextArrow = (props) => (
  <div onClick={props.onClick}
  style={{
    position: 'absolute',
    top: '48%', // 수직 중앙 정렬
    right: '10px', // 원하는 가로 위치
    transform: 'translateY(-50%)', // 세로 중앙 정렬을 위한 변환
    zIndex:3,
    fontSize: '35px',
    cursor:'pointer'
  }}
  >
    <IoIosArrowForward />
  </div>
);

export { CustomPrevArrow, CustomNextArrow };