import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { useFormPostData, useGetUserId, usePostData } from "../../../hooks/useApi.jsx";
import axiosInstance from "../../../axios/axiosInstance.jsx";
import ReactQuill from "react-quill";
import QuillEditor from "../../../elements/blog/QuillEditor.jsx";
import BreadcrumbGray from "../../../elements/common/BreadcrumbGray.jsx";
import Breadcrumb from "../../../elements/common/Breadcrumb.jsx";
import imageCompression from "browser-image-compression";

function ReferenceInsert() {
  const location = useLocation();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(1);
  const [userId, isAdmin, writer] = useGetUserId();
  const [file, setFile] = useState(null);
  // const postFormData = useFormPostData("/board/materials/", "/reference");
  const postData = usePostData("/board/materials/", "/reference");
  const postFormData = useFormPostData("/board/materials/", "/reference");

  const category = async () => {
    const data = await axiosInstance.get("/board/material-categories/");
    setCategoryList(data.data);
  };
  console.log(categoryList);
  const handleForm = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("user", parseInt(userId));
    formData.append("category", selectedCategory);
    formData.append("title", title);
    formData.append("content", content);
    formData.append("upfile", file);
    console.log("눌렀다");
    const info = {
      user: parseInt(userId),
      category: selectedCategory,
      title: title,
      username: writer,
      content: content,
      upfile: file,
    };
    if (title.trim() === "") {
      window.alert("제목을 입력해주세요");
      return;
    }
    const processedContent = content.replace(/<p>/g, "").replace(/<\/p>/g, "").trim();
    if (content === "<p><br></p>" || processedContent === "") {
      window.alert("내용을 입력해주세요.");
      return;
    }

    if (!file || typeof file === "string") {
      await postData(info);
    } else {
      await postFormData(formData);
    }
  };

  const handleText = (value) => {
    setContent(value);
  };

  const imageCompress = async (e) => {
    let file = e.target?.files[0];
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 720,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(file, options);
      const promise = imageCompression.getDataUrlFromFile(compressedFile);
      promise.then((result) => {
        // Base64 문자열을 Blob 객체로 변환
        const byteString = atob(result.split(',')[1]);
        const mimeString = result.split(',')[0].split(':')[1].split(';')[0];
        const byteNumbers = new Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
          byteNumbers[i] = byteString.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: mimeString });
  
        // Blob 객체를 File 객체로 변환
        const newFile = new File([blob], file.name, { type: mimeString });
        
        // File 객체를 상태에 저장
        setFile(newFile);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileTypes = ["image/apng", "image/bmp", "image/gif", "image/jpeg", "image/pjpeg", "image/png", "image/svg+xml", "image/tiff", "image/webp", "image/x-icon"];
      if (fileTypes.includes(selectedFile.type)) {
        imageCompress(e);
      } else {
        alert("이미지 파일만 업로드 가능합니다.");
      }
    }
  };


  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  useEffect(() => {
    category();
  }, []);

  return (
    <>
      <Breadcrumb title="자료실" />

      <div className="boardInsert pt--50 pb--120">
        <div className="boardInsert-wrap">
          <form method="post" onSubmit={handleForm}>
            <table className="boarderInsertTable">
              <thead>
                <tr>
                  <td>
                    <strong>제목</strong>
                  </td>
                  <td>
                    <input type="text" name="title" placeholder="제목을 입력해주세요" onChange={(e) => setTitle(e.target.value)} />
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>작성자</strong>
                  </td>
                  <td>
                    <strong style={{ marginLeft: 10 }}>{writer}</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>카테고리</strong>
                  </td>
                  <td>
                    <select style={{ marginLeft: 10 }} value={selectedCategory} onChange={handleCategoryChange}>
                      {categoryList &&
                        categoryList.map((cate, key) => {
                          return (
                            <option key={cate.id} value={cate.id}>
                              {cate.name}
                            </option>
                          );
                        })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>내용</strong>
                  </td>
                  <td className="QnaBoardcontent">
                    {/* <EditorToolbar/> */}
                    <QuillEditor value={content} onChange={handleText} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>파일</strong>
                  </td>
                  <td>
                    <input type="file" name="file" onChange={handleFileChange} />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="QNABoardBtnGrp">
              <button type="submit">저장</button>
              <Link to="/reference">
                <button type="button" className="list">
                  목록
                </button>
              </Link>
              {/* <MyA to="/qnaboardDel">
                <button type="button" className="del" >
                  삭제
                </button>
              </MyA> */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ReferenceInsert;
