import React from "react";
import { Link } from "react-router-dom";

const BoardManage = ({ boards }) => {
  return (
    <>
          <table className="mypage-board-table" >
            <thead>
              <tr>
                <th>번호</th>
                <th>제목</th>
                <th>등록일</th>
                <th>답변</th>
              </tr>
            </thead>
            <tbody>
            {boards && boards.length>0? <>{boards.map((board, index) => (  
               <tr key={board.id}>
                <td>{index+1}</td>
                <td><Link to={`/qnaboard/detail/${board.id}`}>{board.title}</Link></td>
                <td>{board.created_at?.slice(0,10)}</td>
                <td>{board.child? <Link to={`/qnaboard/detail/${board.child[0].id}`}>답변완료</Link> :"답변대기"}</td>
              </tr>
               ))}</> :<><tr><td colSpan={3}>등록된 데이터가 없습니다</td></tr></>}
            </tbody>
          </table>   
    </>
  );
};

export default BoardManage;
