import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import BreadcrumbGray from "../../../elements/common/BreadcrumbGray";
import { useAllGetData, useDelData, useGetUserId } from "../../../hooks/useApi";
import Breadcrumb from "../../../elements/common/Breadcrumb";
function GalleryDetail() {
  const location = useLocation();
  const boardId = location.pathname.slice(location.pathname.lastIndexOf("/") + 1);

  const [data] = useAllGetData("/board/gallery-posts", boardId);
  const [user, setUser] = useState("");
  const [date, setDate] = useState("");
  const [userId, isAdmin, writer] = useGetUserId();
  const [contents, setContents, delContent] = useDelData("/board/gallery-posts", boardId, "/gallery");
  useEffect(() => {
    if (data) {
      setUser(data.user.username);
      setDate(data.created_at);
    }
  }, [data]);
  console.log(data);
  return (
    <>
      <Breadcrumb title="작품갤러리" />
      <div className="boardDetail-container pt--50 pb--120">
        <div className="boardDetail-wrap">
          <table className="boardDetail">
            <thead>
              <tr>
                <td colSpan={4}>
                  <strong>{data.title}</strong>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={3}>작성자 : {user}</td>
                <td style={{ textAlign: "right" }}>작성일 :{date.slice(2, 10)}</td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <div className="ql-editor" dangerouslySetInnerHTML={{ __html: data.content }} />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="boardBtn-wrap">
            {isAdmin === "관리자" ? (
              <>
                <Link to={`/gallery/update/${boardId}`}>
                  <button type="button" className="list">
                    수정
                  </button>
                </Link>
                <button type="button" className="del" onClick={delContent}>
                  삭제
                </button>
              </>
            ) : (
              <></>
            )}
            <Link to="/gallery">
              <button type="button" className="list">
                목록
              </button>
            </Link>
          </div>
        </div>
        {/* <>    <h2>공지사항</h2></>
    
         {content.map((con, key) => {
          {
            return (
              <table className="boardDetail" key={key}>
                <thead>
                  <tr>
                    <td>SUBJECT</td>
                    <td>
                      <strong>{con.title}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>WRITER</td>
                    <td>shoes shop</td>
                  </tr>
                  <tr>
                    <td>DATE</td>
                    <td style={{ color: "grey" }}>{con.regdate}</td>
                    <td>VIEW</td>
                    <td style={{ color: "grey" }}>8207</td>
                  </tr>
                </thead>
                <tbody>
                  <td
                    colSpan={"4"}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <p style={{ marginTop: "20px", marginBottom: "20px" }}>
                      {con.content}
                    </p>
                  </td>
                </tbody> 
              </table>
            );
          }
        })}

        <button
          onClick={() => {
            navigate(-1);
          }}
          className="listBox"
        >
          LIST
        </button>*/}
      </div>
    </>
  );
}

export default GalleryDetail;
