import React, { Component, useEffect, useState } from "react";
import PageHelmet from "../../component/common/Helmet";
import { FiClock, FiUser, FiMessageCircle, FiHeart } from "react-icons/fi";
import { Link, useLocation, useParams } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp, FiCalendar, FiCheckSquare } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import axiosInstance from "../../axios/axiosInstance";


const EquipmentsConfirm = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const { state } = useLocation();
  const { reserv_ids } = state;

  const [Check, setCheck] = useState(false);
  const [confirmData, setConfirmData] = useState(null);
  const [guidCheck, setGuidCheck] = useState(false);
  const [confirmDataTimes, setConfirmDataTimes] = useState([])

  const handleNavigate = () =>{
    navigate("/equipments")
  }
  const getEquipEducationList = async (id) => {
    const result = await axiosInstance.get(`/equips/reservations/${id}/`)
    return result.data
      // .then((res) => {
      //   console.log(res.data);
      //   // setEquipEdus(res.data);
      //   setConfirmData(res.data);
      // });
  };
  useEffect(() => {
    // EquipDetail();
    if(id) getEquipEducationList(id).then(data => {
        console.log(data);
        setConfirmData(data);
    })  
  }, [id]);

  useEffect(() => {
    if(reserv_ids) reserv_ids.forEach(reserv_id => {
      getEquipEducationList(reserv_id).then(data => {
        console.log(data);
        setConfirmDataTimes(prev => [...prev, data])
      })  
    });
  }, [reserv_ids])

  if (confirmData === null) {
    return (
      <div className="rn-blog-details ptb--70 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  <div>로딩중</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }

  return (
    <React.Fragment>

      <PageHelmet pageTitle="Equipments Details" />


      {/* Start Equipments Details */}
      <div className="rn-blog-details pt--100 pb--20 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  <div className="blog-single-list-wrapper">
                    <div className="row">
                      <div className="col-lg-12 col-sm-12 content mb--20">
                        <h4 className="title">V-SPACE 장비</h4>
                        <ul className="list-style">
                          <li>장비 : {confirmData?.equipment?.name}</li>
                          <li>예약 날짜 : {confirmData.reservation_date}</li>
                          <li>예약 시간 : {confirmData.reservation_time.start_time.slice(0,5)}~{confirmData.reservation_end_time.slice(0,5)}</li>
                        </ul>
                        <div className="section-title">
                            <h5>개인정보 수집 이용 동의</h5>

                          <p className="description">
                            - 항목 : 성명, 소속, 직위, 휴대전화, 작업내용, 이메일
                            <br />
                            - 개인 정보 제공 기관 :  창업진흥원, 부산대학교 산학협력단
                            <br />- 수집목적 : 프로그램 신청 및 운영, 사업보고{" "}  <br />
                            - 보유기간 : 사업종료 후 5년
                          </p>
                          {/* <div className="d-flex align-items-center">
                            <h5>사진촬영 및 이용 동의</h5>
                            <div className="ml--10">
                              <input
                                type="checkbox"
                                className="chk"
                                onChange={(e) => setPhotoCheck(e.target.checked)}
                              />
                            </div>
                          </div> */}

                
                            <h5 className="mb--5">안내사항</h5>
              
                          <p className="description">
                            - 홈페이지를 통해 예약승인 결과를 확인 후 사용이 가능합니다. <br />
                            - 이용자 부주의로 인한 시설 및 장비 파손시 원상 복구 하여야 합니다.<br />
                            - 해당 신청건의 결과 보고서 미제출시 이용 사용이 불가합니다<br />
                            - 관리자 판단하에 사용에 제재를 받는 경우가 있을 수 있습니다. <br />
                          </p>
                          <h5>예약확인 및 취소</h5>
                          <p className="description">
                           - 마이페이지에서 예약확인 및 취소 가능합니다.
                          </p>
                        </div>
                        <div className="blog-btn d-flex justify-content-end mt-30">
                            <button className="btn-default" onClick={handleNavigate}>
                              확인하기
                            </button>
                          </div>
                
                      </div>
                    </div>
                  </div>
                  {/* 
                    <div className="video-wrapper position-relative pt--45 mb--40 ">
                      <div className="thumbnail w-100">
                        <img
                          src="/assets/images/blog/bl-big-01.jpg"
                          alt="Blog Images"
                          style={{width:'100%'}}
                        />
                      </div>
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="ZOoVOfieAF8"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button
                        className="video-popup position-top-center"
                        onClick={this.openModal}
                      >
                        <span className="play-icon"></span>
                      </button>
                    </div> */}
                  {/* <p className="mb--0">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet. It re are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injectedeed eedhumour, or randomised words which don't look even slightly believable.</p> */}
                
    
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Blog Details */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </React.Fragment>
  );
};

export default EquipmentsConfirm;
