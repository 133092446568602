import React, { useEffect, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import { useNavigate, useLocation } from "react-router-dom";
// import Modal from "react-modal";
import ReactPlayer from "react-player";
import YouTube from 'react-youtube';
import axiosInstance from "../axios/axiosInstance";
import Modal from "react-bootstrap/Modal";
import { isLoginSelector } from "../recoil/User";
import { useRecoilValue } from "recoil";
import ScrollTop from "./common/ScrollTop";
import { useGetUserId } from "../hooks/useApi";

// function getIDfromURL(url) {
//   const regExp =
//     /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;

//   const match = url.match(regExp);

//   if (match && match[2].length === 11) {
//     return match[2];
//   }

//   console.log("The supplied URL is not a valid youtube URL");

//   return "";
// }
const OnlineEquipListDetail = () => {
  const [Check, setCheck] = useState(false);
  const [Equip, setEquip] = useState([]);
  const [EquipEdus, setEquipEdus] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const equipId = location.pathname.slice(
    location.pathname.lastIndexOf("/") + 1
  );
  const [videoIsOpen, setVideoIsOpen] = useState(false);

  const isLogin = useRecoilValue(isLoginSelector);
  const [userId, isAdmin, writer, data] = useGetUserId();
  const [answers, setAnswers] = useState([]);
  console.log(EquipEdus);
  const equipSubmit = async (e) => {
    e.preventDefault();
    if (EquipEdus.length > 0 && answers.length == 0) {
      alert("설문지 작성 후 제출하시길 바랍니다.");
      return;
    }
    // if( EquipEdus.length ==0){
    //   alert("설문지 작성후 제출하시길 바랍니다.");
    //   return;
    // }
    if (!isLogin) {
      alert("로그인이 필요합니다");
      window.location = "/login";
      return;
    }
    try {
      const result = await axiosInstance.post("/equips/submit-test/", {
        test_id: equipId,
        equip_id: Equip.id,
        test_answers: answers,
      });
      if (result.status === 200) {
        alert("설문지가 제출되었습니다.");
        window.location = "/online-equip";
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const getEquipDetail = async () => {
    await axiosInstance.get(`/equips/priortests/${equipId}`).then((res) => {
      setEquip(res.data);
      if (res.data.questions) setEquipEdus(res.data.questions);
    });
  };

  useEffect(() => {
    getEquipDetail();
  }, []);

  const [testIsOpen, setTestIsOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLogin) {
      window.alert("로그인이 필요합니다.");
      window.location = "/login";
      return;
    }
    let isPass = true;

    // 선택한 답변 데이터를 수집합니다.
    const answers = EquipEdus.map((question) => {
      if (!question.is_number && question.select_list) {
        const answer_list = question.select_list.split(",");
        // console.log(answer_list)
        let answer = "";
        answer_list.forEach((answervalue, i) => {
          const answerInput = document.getElementById(
            `answer-${question.id}-${i}`
          );
          // console.log(answerInput)
          // console.log(answerInput.checked)
          if (answerInput.checked) answer = `${question.id}:${answervalue}`;
        });
        if (answer === "") isPass = false;
        return answer;
      } else {
        const answerInput = document.getElementById(`answer-${question.id}`);
        if (!answerInput) return "";
        const _value = answerInput.value;
        if (!_value) isPass = false;
        return _value;
      }
    });

    // 수집한 답변 데이터를 활용하여 처리 로직을 구현합니다.
    // console.log(answers); // 예시: 콘솔에 답변 데이터 출력
    if (!isPass) {
      window.alert("모든 답변을 작성해 주시길 바랍니다.");
      return;
    }
    try {
      const testResult = await axiosInstance.get(
        `/equips/testresults/?user_id=${userId}&prior_test__id=${Equip.priority}`
      );
      if (testResult.data?.length !== 0) {
        alert("설문지를 작성하셨습니다.");
        // const result = await axiosInstance.post("/equips/submit-test/", {
        //     test_id: equipId,
        //     equip_id: Equip.id,
        //     test_answers: answers
        //   });
        //   if(result.status ===200){
        //     alert("설문지가 제출되었습니다.")
        //   }
      }
      //  const result = await axiosInstance.post("/equips/submit-test/", {
      //     test_id: equipId,
      //     equip_id: Equip.id,
      //     test_answers: answers
      //   });
      //  console.log(result.data)
      // const test_result_id = result.data.test_result_id

      // 임시
      // setCheck(true);
    } catch (error) {
      console.log(error);

    }
    setAnswers(answers);
    setTestIsOpen(false); // 모달을 닫습니다.
  };

  return (
    <React.Fragment>
      <PageHelmet pageTitle="OnlineEquipList Details" />

      {/* <ModalVideo
        channel="youtube"
        isOpen={videoIsOpen}
        videoId={Equip?.video_url ? getIDfromURL(Equip.video_url) : ""}
        onClose={() => setVideoIsOpen(false)}
      /> */}

      <Modal show={testIsOpen} 
        onHide={() => setTestIsOpen(false)}>
        <form onSubmit={handleSubmit}>
          <div
            className="modal-container"
            style={{
              minHeight: "200px",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              
              maxHeight: "80vh",
              scrollBehavior: "smooth",
              overflow: "scroll",
            }}
          >
            <div className="">
              <h4 className="text-center">{Equip.title}</h4>
              {EquipEdus && EquipEdus.length > 0 ? (
                EquipEdus.map((question, index) => (
                  <div key={question.id}>
                    <h5>
                      {index + 1}. {question.title}
                    </h5>
                    <h6>{question.description}</h6>
                    <p>
                      {question.sub_script}{" "}
                      {question.is_number &&
                        `(1:매우낮음,2:낮음,3:보통,4.높음, 5.매우높음 숫자로 입력)`}
                    </p>
                    {question.is_number ? (
                      <input id={`answer-${question.id}`} />
                    ) : null}
                    {!question.is_number && question.select_list ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          gap: "8px",
                          marginBottom: "8px",
                        }}
                      >
                        {question.select_list
                          .split(",")
                          .map((select, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "row",
                                gap: "8px",
                                marginBottom: "8px",
                              }}
                            >
                              <input
                                type="radio"
                                style={{ width: "16px" }}
                                id={`answer-${question.id}-${index}`}
                                name={`answer-${question.id}`}
                                value={select}
                              />
                              <label htmlFor={`answer-${question.id}-${index}`}>
                                {select}
                              </label>
                            </div>
                          ))}
                      </div>
                    ) : null}
                    {!question.is_number && !question.select_list ? (
                      <textarea id={`answer-${question.id}`} />
                    ) : null}
                  </div>
                ))
              ) : (
                <h5>{Equip.title}을 시청하였습니다.</h5>
              )}
            </div>
            <div className="d-flex justify-content-end mt--20">
              <button type="submit" className="rn-btn mr--10">
                작성
              </button>
              <button
                className="rn-btn"
                type="button"
                onClick={() => setTestIsOpen(false)}
              >
                닫기
              </button>
            </div>
          </div>
        </form>
      </Modal>
      {/* Start Equipments Details */}
      <div className="rn-blog-details ptb--70 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  <div className="blog-single-list-wrapper">
                    <div className="row">
                      <h4 className="title">{Equip.title}</h4>

                      <div className="thumbnail text-center col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center pb--60 mt--20">
                        <ReactPlayer
                          url={Equip.video_url}
                          controls={true}
                          playing={true}
                          width="900px"
                          height="600px"
                        />
                      </div>
                      {/* 유트브 영상 링크 */}
                      <div className="justify-content-center align-items-center text-center mb--30">
                        <a href={Equip.video_url} target="_blank">
                          동영상 재생 불가시 클릭
                        </a>
                        </div>
                    </div>
                    {/* <button
                                            className="rn-btn"
                                            style={{ zIndex: 0 }}
                                            onClick={() => {
                                              setVideoIsOpen(true);
                                            }}
                                          >
                                            동영상보기
                                          </button> */}
                    <div className="row">
                      <div className="col-lg-12 col-sm-12 content mb--20">
                        {/* <ul className="list-style">
                          <li>사용가능수량 : {Equip.quantity}대 </li>
                          <li>장비 설명</li>
                          <div
                            style={{
                              whiteSpace: "pre-wrap",
                              paddingLeft: 10,
                              marginTop: 10,
                            }}
                          >
                            {Equip.descript}
                          </div>
                        </ul> */}
                        <h4 className="title">V-SPACE 장비교육 안내</h4>
                        <p>
                          * 해당영상은 무단 복제 및 배포, 상업적인 사용을
                          원칙적으로 금합니다.
                          <br />* 영상을 스킵하거나 건너뛰어서 설명을 놓치는
                          부분이 없도록 해주시기 바랍니다.(특히 사용가능한 재료
                          부분)
                          <br />* V-SPACE에서 사용되는 대부분의 장비는 무료로
                          사용가능하며 사용되는 소모성 재료는 직접 가지고 오셔야
                          됩니다.
                          {/* <br />* 장비당 1일 1인 최대 2타임(1타임=30분) 예약가능 */}
                          {/* <br />* 장비 사용 후 완료사진 미제출시 이후 해당 장비 예약 불가 */}
                          {/* <br />* 예약건 QR코드 지참시 입장 가능 */}
                          {/* <br />* 대량 출력 또는 생산이 필요할 시, 기타 문제상황발생이나 문의사항이 있으신 분들은 V-SPACE (051-510-3261)로 전화 문의바랍니다. */}
                        </p>

                        <h4 className="title">장비교육 설명</h4>
                        <div
                          style={{
                            whiteSpace: "pre-wrap",
                            paddingLeft: 10,
                            marginTop: 0,
                            marginBottom: 50,
                          }}
                        >
                          {Equip.description}
                        </div>

                        <h4 className="title">장비 예약 전 필수 설문지</h4>
                        {EquipEdus.length > 0 && EquipEdus ? <button
                          className="rn-btn"
                          onClick={() => {
                            // onPressEdu();
                            setTestIsOpen(true);
                          }}
                        >
                          설문지작성
                        </button> : <span>해당 장비교육 동영상에는 설문지가 없습니다. 해당교육이 필수인 장비예약을 원하시는 분들은, 동영상을 다 보신 후에 제출하기 버튼을 누르셔야 해당 교육 이수가 됩니다.</span>}
                      </div>
                      <div className="blog-btn d-flex justify-content-end mt--30">
                        <button className="btn-default" onClick={equipSubmit}>
                          제출하기
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Blog Details */}

      {/* Start Back To Top */}
      <ScrollTop />
      {/* End Back To Top */}
    </React.Fragment>
  );
};

export default OnlineEquipListDetail;
