// import axios from "axios";
import React, { useEffect, useState } from "react";
// import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance, {updateInstanceWithToken} from "../../axios/axiosInstance";
import { TokenAtom } from "../../recoil/User";
import { useRecoilState } from "recoil";
// import jwt from 'jwt-decode';
function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

const Login = () => {
  const [id, setId] = useState("");
  const [passwd, setPasswd] = useState("");
  const [idEmailValid, setIdEmailValid] = useState(false);
  const [pwValid, setPwValid] = useState(false);
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [accessToken, setAccessToken] = useRecoilState(TokenAtom);
  

 
  const handleForm = async (e) => {
    e.preventDefault();
    await axiosInstance.post("/users/auth/token/",{user_id: id, password: passwd})
    .then(
      (res) => {
        localStorage.setItem('access_token', res.data.token.access);
        localStorage.setItem('refresh_token', res.data.token.refresh);
        setAccessToken(res.data.token.access);
        if(res.status ===200){
          updateInstanceWithToken();
          window.alert("로그인되었습니다.");
          navigate("/")
        }
      }
    ).catch(
      (error) =>{
        console.log(error)
        if(error.response.request.status === 401){
          window.alert("아이디 비번을 다시 확인하시기 바랍니다.")
        }else if(error.response.request.status === 400){
          window.alert("아이디 비번을 다시 확인하시기 바랍니다.")
    
        }
      }
    )
  };

  // const handleId = (e) =>{
  //   setId(e.target.value);
  //   const regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
  //   if(regex.test(id)){
  //     setIdEmailValid(true);
  //   }else{
  //     setIdEmailValid(false);
  //   }
  // }

  // const handlePw = (e) =>{
  //   setPasswd(e.target.value);
  //   const regex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;
  //   if(regex.test(passwd)){
  //     setPwValid(true)
  //   }else{
  //     setPwValid(false);
  //   }
  // }
  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      axiosInstance.post("/users/token/", {
        token: token
      }).then((response) => {
        if (response.status === 200) {
          navigate("/");
          alert("이미 로그인 되어있습니다.");
        }
      }).catch((error) => {
        console.log(error);
      })
    }
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [idEmailValid, pwValid]);

  // useEffect(()=>{
    //  if(idEmailValid && pwValid){
    //   setNotAllow(false);
    //   return;
    //  }
    //  setNotAllow(true);
   //},[idEmailValid, pwValid])


  return (
    <div className="login-wrap">
      <div className="login-container ">
        <div className="login-box">
        <h2>로그인</h2>
          <form method="post" onSubmit={handleForm}>
            <div className="login-control">
              <input
                type="text"
                value={id}
                onChange={(e)=>setId(e.target.value)}
                //onChange={handleId}
                placeholder="아이디"
                required
              />
            </div>
          
                <div className="login-control">
              <input
                type="password"
                value={passwd}
                //onChange={handlePw}
                onChange={(e)=>setPasswd(e.target.value)}
                placeholder="비밀번호"
                required
              />
             </div>
            <div>
              <input  type="submit" className="loginBtn" value="로그인" />
              {/* disabled={notAllow} */}
            </div>
            {windowSize.innerWidth>425?
            <div className="id-container align-items-center text-center">
              <Link to="/findId"><label>아이디 <span>&#183;</span> 비밀번호 찾기</label></Link>
              <small>  ㅣ  </small>
              <Link to="/registerconfirm"><label>회원가입</label></Link>
            </div>
             : 
             <div className="align-items-center text-center">
             <Link to="/findId"><label>아이디 <span>&#183;</span> 비밀번호 찾기</label></Link>
             ㅣ<Link to="/registerconfirm"><label>회원가입</label></Link>
           </div>
            }
            
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
