import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { FaPaperclip } from "react-icons/fa";
import { useGetUserId } from "../../../hooks/useApi.jsx";
import Pagination from "../../../elements/blog/Pagination.jsx";
import useResize from "../../../hooks/useResize.jsx";
import Search from "../../../elements/blog/Search.jsx";
import BreadcrumbGray from "../../../elements/common/BreadcrumbGray.jsx";
import { API_HOST } from "../../../axios/axiosInstance.jsx";
import ScrollTop from "../../../elements/common/ScrollTop.jsx";
import Breadcrumb from "../../../elements/common/Breadcrumb.jsx";

function Reference() {
  const location = useLocation();
  const base_url = `${API_HOST}/board/materials`;
  const [notices, setNotices] = useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const [baseurl, setbaseurl] = useState(base_url);
  const [currentpage, setcurrentpage] = useState(1);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [loading, setLoading] = useState(true);
  const perpage = 20;
  const [userId, isAdmin, writer] = useGetUserId();
  const [searchSelect, setSearchSelect] = useState("title_only");
  const [searchKeyword, setSearchKeyword] = useState("");
  const handleSelectValue = (e) => setSearchSelect(e.target.value);
  const handleSearchValue = (e) => setSearchKeyword(e.target.value);

  async function searchContents() {
    const { data } = await axios.get(baseurl + `?search=${searchKeyword}&${searchSelect}=${searchSelect}`);
    setNotices(data.results);
    setTotalCount(data.count);
    setNext(data.next);
    setPrevious(data.previous);

    // onChange(searchKeyword)
  }

  const windowWidth = useResize();
  function changeurl(event) {
    setbaseurl(event);
  }

  async function getNotices() {
    setLoading(true);
    const { data } = await axios.get(baseurl);
    setNotices(data.results);
    setTotalCount(data.count);
    setNext(data.next);
    setPrevious(data.previous);
    setLoading(false);
  }

  useEffect(() => {
    getNotices(baseurl);
  }, [baseurl]);
  console.log(notices);
  return (
    <>
      <Breadcrumb title="자료실" />

      <div className="rboard-container  pt--50 pb--120">
        <div className="rboard-wrap">
          {isAdmin === "관리자" ? (
            <div className="rboardwrite">
              <Link to="/reference/insert">
                <button className="rboardwrite-btn" type="button">
                  글쓰기
                </button>
              </Link>
            </div>
          ) : (
            <></>
          )}
          {windowWidth > 768 ? (
            <>
              <table className="rboard">
                <thead>
                  <tr>
                    <td>
                      <strong>번호</strong>
                    </td>
                    <td>
                      <strong>종류</strong>
                    </td>
                    <td>
                      <strong>제목</strong>
                    </td>
                    <td>
                      <strong>작성자</strong>
                    </td>
                    <td>
                      <strong>파일</strong>
                    </td>
                    <td>
                      <strong>등록일</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {notices && notices.length ? (
                    notices.map((notice, key) => {
                      return (
                        <tr key={key}>
                          <td>{totalcount - perpage * (currentpage - 1) - key}</td>
                          <td>{notice.category.name}</td>
                          <td style={{ textAlign: "left" }}>
                            <Link to={`detail/${notice.id}`}>{notice.title}</Link>
                          </td>

                          <td>admin</td>
                          <td>
                            {notice.upfile ? (
                              <span>
                                <FaPaperclip />
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>{notice.created_at.slice(2, 10)}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6}>등록된 글이 없습니다</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="rboardBottom">
                <Pagination
                  notices={notices}
                  previous={previous}
                  base_url={base_url}
                  changeurl={changeurl}
                  setcurrentpage={setcurrentpage}
                  currentpage={currentpage}
                  totalcount={totalcount}
                  perpage={perpage}
                  next={next}
                />
                <div className="searchBox">
                  <select onChange={handleSelectValue}>
                    <option value="title_only">제목</option>
                    <option value="content_only">내용</option>
                    <option value="user_only">작성자</option>
                    <option value="category_only">카테고리</option>
                    <option value="title_content_only">제목+내용</option>
                  </select>
                  <input value={searchKeyword} onChange={handleSearchValue} />
                  <button type="submit" onClick={searchContents}>
                    찾기
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <table className="rboard">
                <thead>
                  <tr>
                    <td>
                      <strong>번호</strong>
                    </td>
                    <td>
                      <strong>종류</strong>
                    </td>
                    <td>
                      <strong>제목</strong>
                    </td>
                    <td>
                      <strong>작성자</strong>
                    </td>
                    <td>
                      <strong>등록일</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {notices && notices.length > 0 ? (
                    notices.map((notice, key) => {
                      return (
                        <tr key={key}>
                          <td>{totalcount - perpage * (currentpage - 1) - key}</td>
                          <td>{notice.category.name}</td>
                          <td style={{ textAlign: "left" }}>
                            <Link to={`detail/${notice.id}`}>{notice.title}</Link>
                          </td>
                          <td>admin</td>
                          <td>{notice.created_at.slice(2, 10)}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5}>등록된 글이 없습니다</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="rboardBottom">
                <div className="d-colum w-100">
                  <div className="bottompagination">
                    <Pagination
                      notices={notices}
                      previous={previous}
                      base_url={base_url}
                      changeurl={changeurl}
                      setcurrentpage={setcurrentpage}
                      currentpage={currentpage}
                      totalcount={totalcount}
                      perpage={perpage}
                      next={next}
                    />
                  </div>
                  <div className="searchBox">
                    <select onChange={handleSelectValue}>
                      <option value="title_only">제목</option>
                      <option value="content_only">내용</option>
                      <option value="user_only">작성자</option>
                      <option value="category_only">카테고리</option>
                      <option value="title_content_only">제목+내용</option>
                    </select>
                    <input value={searchKeyword} onChange={handleSearchValue} />
                    <button type="submit" onClick={searchContents}>
                      찾기
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <ScrollTop />
      </div>
    </>
  );
}

export default Reference;
