import React, { Component, useEffect, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import Slider from "react-slick";
import { slickDot } from "../page-demo/script";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import { useGetListDatas } from "../hooks/useApi";
import { Link } from "react-router-dom";
import axiosInstance from "../axios/axiosInstance";

const PortfolioSlide = () => {
  const [data, setData] = useState("");
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0, // 맨 위로 스크롤
      behavior: "smooth", // 부드러운 스크롤 효과 사용
    });
  };
  const getData = async () => {

      await axiosInstance.get("/equips/items-no-page/")
      .then(
        (res) => {
        console.log(res)
        setData(res.data);
      }).catch((error)=>{
        console.log(error)
      })
  };

  useEffect(() => {
    getData();
  }, []);


  return (
    <React.Fragment>
      {/* Start Portfolio Area */}
      <div className="oprtfolio-area pt--120 pb--25">
        <div className="rn-slick-dot">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--15">
                  <span className="color-title">Equipments</span>
                  {/* <h4 className="title">보유 장비</h4> */}
                  <p className="title-des">{data?.length}종류의 장비를 보유하고 있습니다.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="slick-space-gutter--15 slickdot--20 slider-activation ">
                  <Slider {...slickDot}>
                    {data &&
                      data.map((value, index) => (
                        <div className="portfolio" key={index}>
                          <div className="thumbnail-inner">
                            <img src={value.image} className="img-fluid" />
                            {/* <div className={`thumbnail ${value.image}`}></div>
                                                        <div className={`bg-blr-image ${value.image}`}></div> */}
                          </div>

                          <div className="content">
                            {/* <div style={{ height: "1px", width: "100%", backgroundColor: "#000", marginBottom: "20px" }}></div> */}
                            <div className="inner">
                              {/* <p>{value.category}</p> */}
                              <h4>
                                <Link to={`/equipments-details/${value.id}`} onClick={handleScrollToTop}>{value.name}</Link>
                              </h4>
                              <div className="portfolio-button">
                                <Link to={`/equipments-details/${value.id}`} className="rn-btn" onClick={handleScrollToTop}>
                                  상세보기
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Area */}
    </React.Fragment>
  );
};

export default PortfolioSlide;
