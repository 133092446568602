import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import QuillEditor from "../../../elements/blog/QuillEditor";
import BreadcrumbGray from "../../../elements/common/BreadcrumbGray";
import { useGetUserId, usePostData } from "../../../hooks/useApi";
import Breadcrumb from "../../../elements/common/Breadcrumb";

function QNABoardInsert() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const isPrivate = searchParams.get("isPrivate");

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [userId, isAdmin, writer] = useGetUserId();
  const [isChecked, setIsChecked] = useState(isPrivate ? true : false);
  const postData = usePostData("/board/qnaboards/", "/qnaboard");

  const handleForm = async (e) => {
    e.preventDefault();
    if (title.trim() === "") {
      window.alert("제목을 입력해주세요");
      return;
    }
    const processedContent = content.replace(/<p>/g, "").replace(/<\/p>/g, "").trim();
    if (content === "<p><br></p>" || processedContent === "") {
      window.alert("내용을 입력해주세요.");
      return;
    }
    if (id) {
      const info = {
        user: parseInt(userId),
        title: title,
        content: content,
        is_private: isChecked,
        parent: parseInt(id),
      };
      await postData(info);
    } else {
      const info = {
        user: parseInt(userId),
        title: title,
        content: content,
        is_private: isChecked,
      };
      await postData(info);
    }
  };
  console.log(isPrivate);

  // const sub_menubar = Menulists.find((m)=>m.subUrl == location.pathname);
  //  const sub_menulists = Menulists.filter((ml)=>ml.mainMenu ==sub_menubar.mainMenu);
  // console.log(sub_menubar)

  // const [content, setContent] = useState([]);

  // useEffect(() => {
  //   getContent();
  // }, [content]);

  //const navigate = useNavigate();;

  const handleText = (value) => {
    setContent(value);
  };
  return (
    <>
      <Breadcrumb title="Q&A" />

      <div className="boardInsert  pt--50 pb--120">
        <div className="boardInsert-wrap">
          <form method="post" onSubmit={handleForm}>
            <table className="boarderInsertTable">
              <thead>
                <tr>
                  <td className="boardRow">
                    <strong>제목</strong>
                  </td>
                  <td>
                    <input type="text" name="title" placeholder="제목을 입력해주세요" onChange={(e) => setTitle(e.target.value)} />
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="boardRow">
                    <strong>작성자</strong>
                  </td>
                  <td>
                    <strong style={{ marginLeft: 10 }}>{writer}</strong>
                  </td>
                </tr>
                <tr>
                  <td className="boardRow">
                    <strong>내용</strong>
                  </td>
                  <td className="QnaBoardcontent">
                    <QuillEditor value={content} onChange={handleText} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>비밀글</strong>
                  </td>
                  <td className="privateCheckbox">
                    {/* <input
                     className="privateChk"
                      type="checkbox"
                      checked={isChecked}
                      onChange={(e) => setIsChecked(e.target.checked)}
                    /> */}
                    {isPrivate ? (
                      <input className="privateChk" type="checkbox" checked={isChecked} readOnly />
                    ) : (
                      <input className="privateChk" type="checkbox" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="QNABoardBtnGrp">
              <button type="submit">저장</button>
              <Link to="/qnaboard">
                <button type="button" className="list">
                  목록
                </button>
              </Link>
              <Link to="/qnaboardDel">
                <button type="button" className="del">
                  삭제
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default QNABoardInsert;
