import React, { useState } from 'react'
import Calendar from 'react-calendar';
import moment from 'moment';
import "react-calendar/dist/Calendar.css";
import {FiChevronRight,FiChevronLeft, FiChevronsLeft, FiChevronsRight} from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
const CalendarPicker = ({events, atTo}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const navigate = useNavigate();
  const tileContent = ({ date }) => {
    const eventOnDate = events.find(
      (event) =>
        event.date.getDate() === date.getDate() &&
        event.date.getMonth() === date.getMonth() &&
        event.date.getFullYear() === date.getFullYear()
    );

    return eventOnDate ? <p>{eventOnDate.title}</p> : null;
  };
  const handleDateClick = (date) => {
    setSelectedDate(date);
    const formatedDate = moment(date).format("YYYY-MM-DD");
    navigate(atTo ==='tour'? `/tour-details/${formatedDate}`:`/mentoring-details/${formatedDate}`);
  };
  const getToday = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // 오늘 날짜의 시간을 00:00:00로 설정
    return today;
  };
  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6; // 0: Sunday, 6: Saturday
  };
  const tileDisabled = ({ date }) => {
    return date < getToday() || isWeekend(date);
  };
 


  return (
    <div>
      <Calendar
            onClickDay={handleDateClick} 
            tileDisabled={tileDisabled} // 현재 날짜 이전은 클릭 비활성화
            tileContent={tileContent}
            formatDay={(locale, date) => moment(date).format('DD')} 
            nextLabel={<FiChevronRight />}
            prevLabel={<FiChevronLeft />}
            next2Label={<FiChevronsRight/>}
            prev2Label={<FiChevronsLeft/>}
         />
    {/* {selectedDate && (
      <div>
      <h2>{moment(selectedDate.toDateString()).format("YYYY년 MM월 DD일")}</h2>
      <ul>
        {events
          .filter(
            (event) =>
              event.date.getDate() === selectedDate.getDate() &&
              event.date.getMonth() === selectedDate.getMonth() &&
              event.date.getFullYear() === selectedDate.getFullYear()
          )
          .map((event) => (
            <li key={event.title}>{event.title}</li>
          ))}
      </ul>
    </div>
  )} */}
      </div>

  )
}

export default CalendarPicker;
