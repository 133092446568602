import React, { useState } from "react";
import PageHelmet from "../../component/common/Helmet";
import Breadcrumb from "../common/Breadcrumb";
import ScrollTop from "../common/ScrollTop";

const ProgramUtilize = () => {
  return (
    <React.Fragment>
      <PageHelmet pageTitle="Eduction Details" />
      <Breadcrumb title={"프로그램 이용 시"} />

      {/* Start Portfolio Details */}
      <div className="rn-portfolio-details ptb--100 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-details">
                <div className="row row--35 mb--50">
                  <div className="col-lg-12">
                    <div className="inner  plr_sm--10">
                      <div className="section-title">
                        <h3 className="title">프로그램 이용 방법</h3>
                        <div className="portfolio-details mt--50 mt_md--40 mt_sm--40">
                          <div className="inner">
                            <div className="section-title">
                              <p className="description">
                                회원가입 - 상단메뉴바 프로그램 신청 진행 - 해당
                                신청 양식 작성 - 관리자 승인 - 완료
                              </p>
                              <div>
                                <h5 className="mb--5">프로그램 예약 안내</h5>
                              </div>
                              <p className="description">
                                - 멘토링 및 제작 지원 신청 시 관리자 확인 후 개별연락(담당자 배정 및 일자 재조율)<br/>
                                - 노쇼 3회 이상 시 프로그램 예약 불가
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Details */}

      {/* Start Back To Top */}
      <ScrollTop/>
      {/* End Back To Top */}
    </React.Fragment>
  );
};

export default ProgramUtilize;
