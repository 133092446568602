import React, { useEffect, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import Pagination from "./common/Pagination";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import CalendarPicker from "./datePicker/CalendarPicker";
import BreadcrumbGray from "./common/BreadcrumbGray";
import ScrollTop from "./common/ScrollTop";
import Breadcrumb from "./common/Breadcrumb";

const Tour = () => {
  const [events, setEvents] = useState([]);
  //   // 더미 데이터를 사용한 예시
  //   { id: 1, date: new Date(2023, 6, 15), title: "Meeting", description: "Discuss project" },
  //   { id: 2, date: new Date(2023, 6, 20), title: "Conference", description: "Attend conference" },
  //   { id: 3, date: new Date(2023, 6, 25), title: "Appointment", description: "Doctor's appointment" },
  // ]);
  const [atTo, setAtTo] = useState("tour");

  // useEffect(() => {
  //   window.addEventListener("load", function () {
  //     console.log("All assets are loaded");
  //   });
  // }, []);

  const currentPage = 1;
  const postPerPage = 6;
  const lastPostIndex = currentPage * postPerPage;
  const firstPostIndex = lastPostIndex - postPerPage;

  return (
    <React.Fragment>
      <PageHelmet pageTitle="Tour" />

      <Breadcrumb title="V-SPACE 투어예약" />

      <div className="mentoring-area ptb--120 bg_color--1">
        <div className="container plr--80 plr_sm--30 plr_md--30">
          <CalendarPicker events={events} atTo={atTo} />
        </div>
      </div>

      <ScrollTop />
    </React.Fragment>
  );
};

export default Tour;
