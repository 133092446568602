import React, { Component } from "react";
import ServiceOne from "../../elements/service/ServiceOne";
import MainSlide from "../../elements/MainSlide";

class SliderOne extends Component {
  render() {
    return (
      <div className="slider-activation">
        {/* Start Single Slide */}
        <div className="slide slide-style-1 d-flex align-items-center">
          <div className="container position-relative">
            <div className="row">
              <div className="col-lg-12">
                <MainSlide />
                {/* <div className="inner">
                                    <h1 className="title theme-gradient">Vspace</h1> 
                            </div> */}
              </div>
            </div>

            {/* Start Service Areas
                    <div className="service-wrapper service-white">
                        <ServiceOne />
                    </div>
                     End Service Area */}
          </div>
        </div>
        {/* End Single Slide */}
      </div>
    );
  }
}
export default SliderOne;
