import React, { useEffect, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import Pagination from "./common/Pagination";
import axiosInstance from "../axios/axiosInstance";
import SpaceList from "./list/SpaceList";
import BreadcrumbGray from "./common/BreadcrumbGray";
import ScrollTop from "./common/ScrollTop";
import LongPagination from "./blog/LongPagination";
import Breadcrumb from "../elements/common/Breadcrumb";
import WholeSearch from "./blog/WholeSearch";

const Space = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [items, setItems] = useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const [baseurl, setbaseurl] = useState("/facilities/items/");
  const [currentpage, setcurrentpage] = useState(1);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const postPerPage = 6;

  const EqList = async (url) => {
    const result = await axiosInstance.get(url);

    try{
    console.log(result)
     setItems(result.data.results);
     setNext(result.data.next);
     setPrevious(result.data.previous);
     setTotalCount(result.data.count);
    setIsLoading(false);
  }catch(error){
   console.log(error)
  }
    // await axiosInstance.get("/facilities/items/").then((response) => {
    //   setEquip(response.data);
    // });
  };
  console.log(items);

  useEffect(() => {
    EqList(baseurl);
  }, [baseurl]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    console.log(pageNumber);
  };

  return (
    <React.Fragment>
      <PageHelmet pageTitle="Blog" />

      <Breadcrumb title="시설 예약" />

      <div className="designer-portfolio-area ptb--120 bg_color--1">
        <div className="container plr--80 plr_sm--30 plr_md--30">
          <SpaceList items={items} isLoading={isLoading}/>
          <LongPagination
            search={search}
            items={items}
            previous={previous}
            base_url={baseurl}
            changeurl={EqList}
            setcurrentpage={setcurrentpage}
            totalcount={totalcount}
            perpage={6}
            next={next}
            currentpage={currentpage}
          />
{/*           
          <Pagination
            totalPosts={items.length}
            postsPerPage={postPerPage}
            paginate={paginate}
            currentPage={currentPage}
          />  */}
         <WholeSearch setItems={setItems} setNext={setNext} setPrevious={setPrevious} setTotalCount={setTotalCount} baseurl={baseurl}  onChange={setSearch}/>

        </div>
      </div>

      {/* Start Back To Top */}
      <ScrollTop />
      {/* End Back To Top */}
    </React.Fragment>
  );
};

export default Space;
