import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { Popover, Transition } from "@headlessui/react";
import DatePickerCustomDay from './DatePickerCustomDay'
import "react-datepicker/dist/react-datepicker.css";
import { getMonth, getDate, isToday } from "date-fns";

const Daypicker = ({startDate, onChange,saturday}) => {
  const isWeekend = (date) => {
    const day = date.getDay();
    return saturday ? day === 0 : day === 0 || day === 6; // 0: Sunday, 6: Saturday
  };
  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const customDayClassName = (date) => {
    if (getDate(date) === getDate(startDate) && getMonth(date) === getMonth(startDate)) {
      return "selected-day";
    } else if (isWeekend(date)) {
      return "weekend-day";
    } else if (isToday(date)) {
      return "today-day";
    } else {
      return "react-datepicker__day_span";
    }
  };

  return (
    // <>
    //   <div className="overflow-hidden dark:bg-neutral-800 p-8">

      <DatePicker
    selected={startDate}
    onChange={onChange}
    minDate={new Date()}  
    renderDayContents={(day, date) => (
      <div className={isWeekend(date) ? 'weekend-day':"react-datepicker__day_span"}>
        {day}
      </div>
    )}
    showTimeSelect={false}
    placeholderText="날짜를 선택해주세요"
    calendarClassName="rasta-stripes"
    dateFormat="yyyy년 MM월 dd일"
    dayClassName={customDayClassName}

    // dayClassName={(d) =>
    //   getDate(d) === getDate(startDate) && getMonth(d) === getMonth(startDate)
    //    || isWeekend(d)   ? 'weekend-day'
    //       : 'react-datepicker__day_span'
    // }
    />
   
    //   </div>
    // </>
  );
};

export default Daypicker;
