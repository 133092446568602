import React, { useState } from 'react'
import axiosInstance from '../../axios/axiosInstance';

const WholeSearch = (props) => {
    const {setItems, setNext, setPrevious, setTotalCount, baseurl, onChange} = props;
    const [searchKeyword, setSearchKeyword] = useState("");
      const handleSearchValue = (e) => setSearchKeyword(e.target.value);

  async function searchContents() {
    const { data } = await axiosInstance.get(
      baseurl + `?search=${searchKeyword}`
    );
    console.log(data)
    setItems(data.results);
    setNext(data.next);
    setPrevious(data.previous);
    setTotalCount(data.count);
    onChange(searchKeyword)
    // setNotices(data.results);
    // setTotalCount(data.count);
    // setNext(data.next);
    // setPrevious(data.previous);

    //  onChange(searchKeyword)
  }
    return (
   <div className="search-box mt--20">
            <input value={searchKeyword} onChange={handleSearchValue} />
            <button className="search-btn" type="submit" onClick={searchContents}>
              찾기
            </button>
          </div>
  )
}

export default WholeSearch
