import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams,useLocation, Link} from "react-router-dom";
import { API_HOST } from "../../../axios/axiosInstance";

function BoardDetail() {
   const [content, setContent] = useState(null);

   const { id } = useParams();

  async function getContent() {
    const { data } = await axios.get(
      `${API_HOST}/board/boards/${id}/`
    );
    setContent(data);
  
  }

  useEffect(() => {
    getContent();
  }, []);

  async function updateContent() {
    const { data } = axios.put(`${API_HOST}/board/boards/${id}/`,
		    {
          id: id,
        	tite: "test tilte",
          content: "test content"
        },
        {
        	headers: {Authorization: `Barear ${localStorage.getItem('access_token')}`},
        }
      )
  }

  //const navigate = useNavigate();
  return (
    <>
    <div className="rn-page-title-area ptb--100" data-black-overlay="7">
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="blog-single-page-title text-center pt--100">
                    <h2 className="title theme-gradient">자유게시판</h2>

                </div>
            </div>
        </div>
    </div>
</div>
      <div className="boardDetail pt--50 pb--120">
        <div className="boardDetail-wrap">
          {content&& <table className="boardDetail">
           <thead>
            <tr>
              <td><strong>{content.title}</strong></td>
             </tr>
           </thead>
           <tbody>
            <tr>
              <td><div style={{display: "flex", justifyContent:"space-between"}}><div>작성자 : {content.user.name}</div> <div>작성일 : {content.created_at.slice(2, 10)}</div></div></td>
             </tr>
             <tr>
              <td>첨부파일 <a href={content.upfile}>다운</a></td>
             </tr>
             <tr>
              <td>
               <br/>
                {content.content}
                <br/> <br/>
              </td>
             </tr>
             {/* <tr>
              <td style={{backgroundColor: "e9e9e9"}}>이전글 안녕하세요</td>
             </tr>
             <tr>
              <td>다음글 다음글은 없습니다</td>
             </tr> */}
           </tbody>
          </table>}
          <div className="boardBtn-wrap">

                <button type="button" className="list" onClick={updateContent}>
                  수정
                </button>
         
              <Link to="/board">
                <button type="button" className="list">
                  목록
                </button>
              </Link>
          </div>
          </div>
         {/* <>    <h2>공지사항</h2></>
    
         {content.map((con, key) => {
          {
            return (
              <table className="boardDetail" key={key}>
                <thead>
                  <tr>
                    <td>SUBJECT</td>
                    <td>
                      <strong>{con.title}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>WRITER</td>
                    <td>shoes shop</td>
                  </tr>
                  <tr>
                    <td>DATE</td>
                    <td style={{ color: "grey" }}>{con.regdate}</td>
                    <td>VIEW</td>
                    <td style={{ color: "grey" }}>8207</td>
                  </tr>
                </thead>
                <tbody>
                  <td
                    colSpan={"4"}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <p style={{ marginTop: "20px", marginBottom: "20px" }}>
                      {con.content}
                    </p>
                  </td>
                </tbody> 
              </table>
            );
          }
        })}

        <button
          onClick={() => {
            navigate(-1);
          }}
          className="listBox"
        >
          LIST
        </button>*/}
      </div>
      </>
  );
}

export default BoardDetail;
