import React, { Component, useEffect, useState } from "react";
import Slider from "react-slick";
import { slickMainDot } from "../page-demo/script";
import axiosInstance from "../axios/axiosInstance";
import { Link } from "react-router-dom";

const MainSlide = () => {
  const [Edus, setEdus] = useState([]);

  const EduList = async () => {
    try {
      const response = await axiosInstance.get("/educations/items/");
      setEdus(response.data.results?.slice(0, 6));
      const eventResponse = await axiosInstance.get("/eventedu/items/");
      const eventItems = eventResponse.data.results?.slice(0, 6);
      const eventItemsWithTypes = eventItems.map((item) => ({
        ...item,
        type: "event",
      }));
      // const OnlineResponse = await axiosInstance.get("/equips/priortests/");
      // const OnlineItems = OnlineResponse.data.results?.slice(0, 6);
      // const onlineItemsWithTypes = OnlineItems.map((item) => ({
      //   ...item,
      //   type: "online",
      // }));

      // setEdus((prevEdus) => [...prevEdus, ...eventItemsWithTypes, ...onlineItemsWithTypes]);
     setEdus((prevEdus) => [...prevEdus, ...eventItemsWithTypes]);

    } catch (error) {
      console.log(error);
    }
  };

  console.log(Edus);
  useEffect(() => {
    EduList();
  }, []);
  return (
    <React.Fragment>
      {/* Start Portfolio Area */}
      <div className="main-area  pb--20 ">
        <div className="main-slick-dot">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="slick-space-gutter--15 slickdot--20">
                  <h3 style={{ color: "#fff", fontFamily: "font_EB" }}>진행 중인 프로그램</h3>
                  <Slider {...slickMainDot}>
                    {Edus.map((value, index) => (
                      <div className="main-slide" key={index}>
                        <div className="main-thumbnail-inner">
                          <div className="main-thumbnail">
                          {/* <Link to={value.type === "event" ? `event-participate-detail/${value.id}` : value.type === "online" ? `online-equip-detail/${value.id}` : `/education-detail/${value.id}`}> */}

                            <Link to={value.type === "event" ? `event-participate-detail/${value.id}`  : `/education-detail/${value.id}`}>
                              {value.image ? <img src={value.image} /> : <img src="/assets/images/portfolio/interior/portfolio-1.jpg" />}
                            </Link>
                          </div>
                          {/* <div className={`bg-blr-image ${value.image}`}></div> */}
                        </div>
                        <div className="main-content">
                          <div className="main-inner">
                            {/* <p>{value.category}</p>  */}
                            <h4>
                              <Link
                                to={value.type === "event" ? `event-participate-detail/${value.id}` : `/education-detail/${value.id}`}
                              >
                                {value.name ? value.name : value.title}
                              </Link>
                            </h4>
                            <div className="portfolio-button">
                              <Link
                                to={value.type === "event" ? `event-participate-detail/${value.id}` :`/education-detail/${value.id}`}
                                className="rn-btn"
                              >
                                보러가기
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Area */}
    </React.Fragment>
  );
};

export default MainSlide;
