import React, { useEffect, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import ScrollTop from "./common/ScrollTop";
const EquipStatus = () => {
  const [Equip, setEquip] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState("tab1");
  const postPerPage = 6;

  const handleTabClick = (tab) => setSelectedTab(tab);

  return (
    <React.Fragment>
      <PageHelmet pageTitle="Equipment Status" />

      <Breadcrumb title={"장비 현황"} />
      {/* Start Blog Area */}

      <div className="bg_color--1 ">
        <div className="rn-blog-area  ptb--100 bg_color--1">
          <div className="container row">
          <div className="col-lg-3 col-md-6 col-12">
            <img
              src={require("../../public/assets/images/about/3dprinterscanner.png")}
            />
          </div>
          <div className="col-lg-3 col-md-6  col-12">
            <img
              src={require("../../public/assets/images/about/printer.png")}
            />
          </div>
          <div className="col-lg-3 col-md-6  col-12">
            <img
              src={require("../../public/assets/images/about/metalprocessing.png")}
            />
          </div>
          <div className="col-lg-3 col-md-6  col-12">
            <img src={require("../../public/assets/images/about/etc.png")} />
          </div>
          <div className="d-flex justify-content-end mt--30">
          <p >
          * 해당 내용은 사용자가 장비를 선정하는 과정을 돕기 위함입니다.
          <br />
          * 예약 가능 여부는 장비예약 메뉴를 통해 확인 가능합니다.
        </p>
         </div>
        </div>
        </div>

       
      </div>
      {/* End Blog Area */}

      {/* Start Back To Top */}
      <ScrollTop />
      {/* End Back To Top */}
    </React.Fragment>
  );
};

export default EquipStatus;
