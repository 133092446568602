import React from 'react'
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const FloorTitle = ({floorNum}) => {
  switch(floorNum){
    case 1:
      return <div style={
        {
          fontSize: 25,
          fontWeight: 'bold',
          textAlign: 'left',
          marginBottom: 20,
          marginTop:20,
        }
      }>A-SPACE<br/>(Another Campus_LG전자)</div>
    case 2:
      return <div style={
        {
          fontSize: 30,
          fontWeight: 'bold',
          textAlign: 'left',
          marginBottom: 20,
          marginTop:20,
        }
      }>V-SPACE</div>
    case 3:
      return <div style={
        {
          fontSize: 30,
          fontWeight: 'bold',
          textAlign: 'left',
          marginBottom: 20,  
          marginTop:20,
        }
      }>C-SPACE</div>
  }
};

const FloorContent = ({floorNum}) => {
  switch(floorNum){
    case 1:
      return (
        <div className="row" style={{textAlign:"left"}}>
        <h3>&lt;사용안내&gt;</h3>
        <p>누구나 사용이 가능한 공간입니다.
        <br/>LG전자와의 협업공간으로 다양한 가전제품들을 체험하고, 경험할 수 있는 메이킹 공간입니다.
        <br/>모든 공간은 예약 없이 운영시간 내 사용이 가능하며, 작업테이블이 배치되어 있어 다양한 활동이 가능합니다.</p>
            <p>* 현장 공구대여 가능
            <br/>* 장비사용은 예약 필수이며, 현장 상황에 따라 해당층에서의 장비사용이 불가할 수 있습니다.
            <br/>* 입구에 안내데스크 위치</p>
        <h3>&lt;운영시간&gt;</h3>
        <p>월-금 09:30~11:30 / 13:30~17:30
        <br/>(점심시간 12:00~13:00 전 후의 30분은 장비 점검 및 출력물 회수 시간입니다. 원활한 사용을 위해 운영시간을 지켜주세요)</p>
        </div>
      )
    case 2:
      return (
        <div className="row" style={{textAlign:"left"}}>
        <h3>&lt;사용안내&gt;</h3>
        <p>각 실 마다 특징에 맞는 장비가 배치되어 있으며, 각각의 장비는 “장비예약”을 통해 예약 가능합니다.
        <br/>작업 특성상 여러 대의 장비를 사용하여야 하거나, 넓은 공간이 필요한 경우는 “시설예약”을 통해 공간 전체를 대여할 수 있습니다.
        <br/>공간 대여 시 사용가능한 장비는 상단 이미지 우측에 기재되어 있으며, 그 외의 장비는 현장 “공구대여“ 기능을 활용해 대여 가능합니다.</p>
        <p>* 현장 공구대여 가능
        <br/>* 장비사용은 예약 필수이며, 현장 상황에 따라 장비 및 공간 사용이 제한 될 수 있습니다.
        <br/>* 입구에 안내데스크 위치
        </p>
       <h3>&lt;운영시간&gt;</h3>
        <p>월-금 09:30~11:30 / 13:30~17:30
        <br/>(점심시간 12:00~13:00 전 후의 30분은 장비 점검 및 출력물 회수 시간입니다. 원활한 사용을 위해 운영시간을 지켜주세요)</p>
        </div>
      )
    case 3:
      return (
        <div className="row" style={{textAlign:"left"}}>
        <div>
        <h3>&lt;사용안내&gt;</h3>
        <p>누구나 사용이 가능한 공간입니다.
        <br/>회의실을 제외한 곳은 예약 없이 운영시간 내 사용이 가능합니다.
        <br/>사용 후 제자리 정리정돈 부탁드립니다. (C-SPACE내부에 청소기가 있습니다. 청소기를 사용하여 나가기 전 정리해주세요)</p>
        <h3>&lt;운영시간&gt;</h3>
        <p>월-금 09:00~22:00<br/>(도서관 운영시간은 상이합니다.)</p>
        <h3>&lt;예약안내&gt;</h3>
        <p>“시설예약”을 통해 예약이 가능하며, 각 실 입구에 부착된 월패드를 통해 실시간 예약 내용 확인이 가능합니다.
        <br/>회의실 사용은 예약 없이 가능하나, 예약자 우선임으로 배려 부탁합니다.</p>
        </div>
       </div>
      )
  }
}

const ModalComponent = ({show, onHide, imgUrl ,floorNum}) => {
  return (
    // <Modal show={firstModal} onHide={handleCloseModal}>

   <Modal show={show} onHide={onHide} scrollable>
     {/* <Button
      className="close"
      style={{
        position: 'absolute',
        top: 15,
        right: 15,
        backgroundColor: 'white',
        color: 'black',
        border: 'none',
        fontSize:20,
        width: 20,
      }}
      onClick={onHide}
    >
      &times;
    </Button> */}
            <Modal.Body >
              <img
                src={require(`../../../public/assets/images/about/${imgUrl}`)}
             />
                <FloorTitle floorNum={floorNum}/>
                <FloorContent floorNum={floorNum}/>
            
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={onHide}>
                Close
              </Button>
            </Modal.Footer>
            
          </Modal>
  )
}

export default ModalComponent
