import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import QuillEditor from "../../../elements/blog/QuillEditor.jsx";
import { useDelData, useFormUpdateData, useGetData, useGetUserId, useUpdateData } from "../../../hooks/useApi.jsx";
import axiosInstance from "../../../axios/axiosInstance.jsx";
import BreadcrumbGray from "../../../elements/common/BreadcrumbGray.jsx";
import Breadcrumb from "../../../elements/common/Breadcrumb.jsx";
import imageCompression from "browser-image-compression";

function ReferenceUpdate() {
  const location = useLocation();
  const boardId = location.pathname.slice(location.pathname.lastIndexOf("/") + 1);
  const [userId, isAdmin, writer] = useGetUserId();
  const [title, content, setContent, setTitle, data] = useGetData("/board/materials", boardId);
  const [contents, setContents, delContent] = useDelData("/board/materials", boardId, "/reference");
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(1);

  const updateFormData = useFormUpdateData("/board/materials", boardId, "/reference");

  const [file, setFile] = useState(null);

  const category = async () => {
    const data = await axiosInstance.get("/board/material-categories/");
    setCategoryList(data.data);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };
  const handleForm = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("user", parseInt(userId));
    formData.append("category", selectedCategory);
    formData.append("title", title);
    formData.append("content", content);
    formData.append("upfile", file);
    if (!file || typeof file === "string") {
      formData.append("upfile", "");
    }

    // const info = {
    //   user: parseInt(userId),
    //   category: selectedCategory,
    //   title: title,
    //   username: writer,
    //   content: content,
    // };
    const processedContent = content.replace(/<p>/g, "").replace(/<\/p>/g, "").trim();
    if (title.trim() === "") {
      window.alert("제목을 입력해주세요");
      return;
    }

    if (content === "<p><br></p>" || processedContent === "") {
      window.alert("내용을 입력해주세요.");
      return;
    }

    const result = await updateFormData(formData);

    // if (!file|| typeof file === "string" ) {
    //   await updateData(info);
    // } else {
    //   await updateFormData(formData);
    // }
  };

  const handleText = (value) => {
    setContent(value);
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileTypes = [
        "image/apng",
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "image/svg+xml",
        "image/tiff",
        "image/webp",
        "image/x-icon",
      ];
      if (fileTypes.includes(selectedFile.type)) {
        imageCompress(e);
      } else {
        alert("이미지 파일만 업로드 가능합니다.");
      }
    }
  };

  const imageCompress = async (e) => {
    let file = e.target?.files[0];
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 720,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(file, options);
      const promise = imageCompression.getDataUrlFromFile(compressedFile);
      promise.then((result) => {
        // Base64 문자열을 Blob 객체로 변환
        const byteString = atob(result.split(',')[1]);
        const mimeString = result.split(',')[0].split(':')[1].split(';')[0];
        const byteNumbers = new Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
          byteNumbers[i] = byteString.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: mimeString });
  
        // Blob 객체를 File 객체로 변환
        const newFile = new File([blob], file.name, { type: mimeString });
        
        // File 객체를 상태에 저장
        setFile(newFile);
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    category();
    if (data) {
      setFile(data.upfile);
    }
  }, [data]);

  return (
    <>
      <Breadcrumb title="자료실" />

      <div className="boardInsert pt--50 pb--120">
        <div className="boardInsert-wrap">
          <form method="post" onSubmit={handleForm}>
            <table className="boarderInsertTable">
              <thead>
                <tr>
                  <td className="boardRow">
                    <strong>제목</strong>
                  </td>
                  <td>
                    <input type="text" name="title" value={title} placeholder="제목을 입력해주세요" onChange={(e) => setTitle(e.target.value)} />
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="boardRow">
                    <strong>작성자</strong>
                  </td>
                  <td>
                    <strong style={{ marginLeft: 10 }}>{writer}</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>작성자</strong>
                  </td>
                  <td>
                    <select style={{ marginLeft: 10 }} value={selectedCategory} onChange={handleCategoryChange}>
                      {categoryList &&
                        categoryList.map((cate, key) => {
                          return (
                            <option key={cate.id} value={cate.id}>
                              {cate.name}
                            </option>
                          );
                        })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className="boardRow">
                    <strong>내용</strong>
                  </td>
                  <td className="QnaBoardcontent">
                    {/* <EditorToolbar/> */}
                    <QuillEditor value={content} onChange={handleText} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>파일</strong>
                  </td>
                  <td>
                    <input type="file" name="file" onChange={handleFileChange} />
                    {file && file.length ? (
                      <div className="row file-wrap align-items-center">
                        <div className="col-lg-10 col-md-10 col-xs-12">
                          <div className="file-name">파일: {file.substring(file.lastIndexOf("/") + 1)}</div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-xs-12">
                          <button className="file-cancle" onClick={() => setFile(null)}>
                            파일 취소
                          </button>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="QNABoardBtnGrp">
              <button type="submit">저장</button>
              <button type="button" className="del" onClick={delContent}>
                삭제
              </button>
              <Link to="/reference">
                <button type="button" className="list">
                  목록
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ReferenceUpdate;
