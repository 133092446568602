import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";

import axiosInstance from "../../../axios/axiosInstance";
import QuillEditor from "../../../elements/blog/QuillEditor";
import { useDelData, useGetUserId, useUpdateData } from "../../../hooks/useApi.jsx";
import BreadcrumbGray from "../../../elements/common/BreadcrumbGray";
import Breadcrumb from "../../../elements/common/Breadcrumb";

function QNABoardUpdate() {
  const location = useLocation();
  const boardId = location.pathname.slice(location.pathname.lastIndexOf("/") + 1);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const quillRef = useRef(); // quill ref
  const [isChecked, setIsChecked] = useState(false);
  const [userId, isAdmin, writer] = useGetUserId();
  const updateData = useUpdateData("/board/qnaboards", boardId.includes("&") ? boardId.split("&")[1] : boardId, "/qnaboard");
  const [contents, setContents, delContent] = useDelData("/board/qnaboards", boardId.includes("&") ? boardId.split("&")[1] : boardId, "/qnaboard");

  async function getBoardData() {
    if (boardId.includes("&")) {
      const replyId = boardId.split("&");
      await axiosInstance.get(`/board/qnaboards/${replyId[0]}`).then((res) => {
        setTitle(res.data.child[0].title);
        setContent(res.data.child[0].content);
        setIsChecked(res.data.child[0].is_private);
      });
    } else {
      await axiosInstance.get(`/board/qnaboards/${boardId}`).then((res) => {
        setTitle(res.data.title);
        setContent(res.data.content);
        setIsChecked(res.data.is_private);
      });
    }
  }

  const handleForm = async (e) => {
    e.preventDefault();

    const processedContent = content.replace(/<p>/g, "").replace(/<\/p>/g, "").trim();
    if (title.trim() === "") {
      window.alert("제목을 입력해주세요");
      return;
    }

    if (content === "<p><br></p>" || processedContent === "") {
      window.alert("내용을 입력해주세요.");
      return;
    }

    if (boardId.includes("&")) {
      const info = {
        title: title,
        content: content,
        is_private: isChecked,
        parent: parseInt(boardId.split("&")[0]),
      };
      await updateData(info);
    } else {
      const info = {
        row_number: parseInt(boardId),
        title: title,
        content: content,
        is_private: isChecked,
      };
      await updateData(info);
    }
  };

  useEffect(() => {
    getBoardData();
  }, []);

  const handleText = (value) => {
    setContent(value);
  };
  return (
    <>
      <Breadcrumb title="Q&A" />

      <div className="boardInsert pt--50 pb--120">
        <div className="boardInsert-wrap">
          <form method="post" onSubmit={handleForm}>
            <table className="boarderInsertTable">
              <thead>
                <tr>
                  <td className="boardRow">
                    <strong>제목</strong>
                  </td>
                  <td>
                    <input type="text" name="title" value={title} onChange={(e) => setTitle(e.target.value)} />
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="boardRow">
                    <strong>작성자</strong>
                  </td>
                  <td>
                    <strong style={{ marginLeft: 10 }}>{writer}</strong>
                  </td>
                </tr>
                <tr>
                  <td className="boardRow">
                    <strong>내용</strong>
                  </td>
                  <td className="QnaBoardcontent">
                    <QuillEditor value={content} onChange={handleText} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>비밀글</strong>
                  </td>
                  <td className="privateCheckbox">
                    <input type="checkbox" className="privateChk" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="QNABoardBtnGrp">
              <button type="submit">저장</button>
              <button type="button" className="del" onClick={delContent}>
                삭제
              </button>
              <Link to="/qnaboard">
                <button type="button" className="list">
                  목록
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default QNABoardUpdate;
