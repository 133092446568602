import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { API_HOST } from "../../../axios/axiosInstance";
import axiosInstance from "../../../axios/axiosInstance";
import BreadcrumbGray from "../../../elements/common/BreadcrumbGray";
import { useGetUserId } from "../../../hooks/useApi";
import { isLoginSelector } from "../../../recoil/User";
import Breadcrumb from "../../../elements/common/Breadcrumb";
function QNAqnaboardDetail() {
  const location = useLocation();
  const boardId = location.pathname.slice(location.pathname.lastIndexOf("/") + 1);
  const [content, setContent] = useState(null);
  const [boardUserId, setBoardUserId] = useState("");
  const navigate = useNavigate();
  const isLogin = useRecoilValue(isLoginSelector);
  const [userId, isAdmin] = useGetUserId();

  async function getContent() {
    if (boardId.includes("&")) {
      const replyId = boardId.split("&");
      const { data } = await axiosInstance.get(`board/qnaboards/${replyId[0]}/`);
      setContent(data.child[0]);
      setBoardUserId(data.child[0].user.id);
    } else {
      const { data } = await axiosInstance.get(`board/qnaboards/${boardId}/`);
      setContent(data);
      setBoardUserId(data.user.id);
    }
  }

  console.log(content);
  async function delContent() {
    const { data } = await axiosInstance
      .delete(`board/qnaboards/${boardId}/`)
      .then((response) => {
        if (response.status === 204) {
          window.alert("삭제되었습니다.");
          window.location = "/qnaboard";
          setContent(data);
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.response.status === 403) {
          window.alert("삭제할 수 없습니다.");
        } else if (e.response.status === 401) {
          window.alert("로그인이 필요합니다.");
          window.location = "/login";
        }
        // 알럿, 리다이렉팅
      });
  }

  useEffect(() => {
    getContent();
  }, []);
  return (
    <>
      <Breadcrumb title="Q&A" />
      <div className="qnaboardDetail  pt--50 pb--120">
        <div className="qnaboardDetail-wrap">
          {content && (
            <table className="qnaboardDetail">
              <thead>
                <tr>
                  <td colSpan={4}>
                    <strong>{content.title}</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={3}>작성자 : {content.user.username}</td>
                  <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>작성일 : {content.created_at.slice(2, 10)}</td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <div className="ql-editor" dangerouslySetInnerHTML={{ __html: content.content }} />
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          <div className="boardBtn-wrap">
            {isLogin ? (
              <>
                {userId === boardUserId ? (
                  <>
                    <Link to={`/qnaboard/update/${boardId}`}>
                      <button type="button" className="list">
                        수정
                      </button>
                    </Link>
                    <button type="button" className="del" onClick={delContent}>
                      삭제
                    </button>
                  </>
                ) : (
                  <></>
                )}
                <Link to="/qnaboard">
                  <button type="button" className="list">
                    목록
                  </button>
                </Link>
              </>
            ) : (
              <Link to="/qnaboard">
                <button type="button" className="list">
                  목록
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default QNAqnaboardDetail;
