// import queryString from "query-string";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
const queryString = require('querystring');

const LongPagination = (props) => {
  const {
    search,
    items,
    previous,
    base_url,
    changeurl,
    setcurrentpage,
    totalcount,
    perpage,
    next,
    currentpage,
    categoryName,
    ...other
  } = props;
  const lastPage = Math.ceil(totalcount / perpage);
  return (
    <div className="rn-pagination text-center mt--40">
    <ul className="page-list">
        {items && items.length ? (
          <>
            {previous ? (
              <>
                  <li
                    
                    onClick={() => {
                      if(categoryName =="전체" || !categoryName ||categoryName ==null ){
                        const param = { page: 1, search: search ? search : "" };
                        const _q = queryString.stringify(param);
                        changeurl(`${base_url}/?${_q}`);
                        setcurrentpage(1); 
                      }else if(categoryName ){
                      const param = { category:  categoryName? categoryName:"" ,page: 1, search: search ? search : "" };
                      const _q = queryString.stringify(param);
                      changeurl(`${base_url}/?${_q}`);
                      setcurrentpage(1);
                    }}}
                    disabled={!previous}
                  >
                    <Link>&laquo;</Link>
                  </li>
                <li
                 
                  onClick={() => {
                    changeurl(previous);
                    setcurrentpage(currentpage - 1);
                  }}
                  disabled={!previous}
                >
                  <Link>&lt;</Link>
                </li>
              </>
            ) : (
              <>
                <li
                    onClick={() => {
                      if(categoryName =="전체" || !categoryName ||categoryName ==null  ){
                        const param = { page: 1, search: search ? search : "" };
                        const _q = queryString.stringify(param);
                          changeurl(`${base_url}/?${_q}`);
                          setcurrentpage(1);

                       }else if(categoryName){
                         const param = {category: categoryName ?categoryName:"" , page: 1, search: search ? search : "" };
                      // const param = { page: 1, search: search ? search : "" };
                      const _q = queryString.stringify(param);
                    // const _q = ""
                      changeurl(`${base_url}/?${_q}`);

                      setcurrentpage(1);
                    }}}
                    disabled={!previous}
                  >
                    <Link>&laquo;</Link>
                </li>
                <li>
                  <Link>&lt;</Link>
                </li>
              </>
            )}
            {(() => {
               let _pages = [...Array(Math.floor(totalcount / perpage)).keys()];
             //  let _pages = Array.from({ length: Math.floor(totalcount / perpage) }, (_, index) => index);
              if (Math.floor(totalcount / perpage) < totalcount / perpage) {
                _pages.push(_pages.length);
              }
              let _from = Math.floor((currentpage - 1) / 10) * 10;
              if (_pages.length > _from + 10) {
                _pages = _pages.slice(_from, _from + 10);
                _pages.push("...");
              } else {
                _pages = _pages.slice(_from, _pages.length);
              }
              return (
                <>
                  {_pages.map((page, idx) => {
                    if (page === "...") return <li>...</li>;
                    let _q;
                    if(categoryName =="전체" || !categoryName ||categoryName ==null ){
                      const param = { page: page+1, search: search ? search : "" };
                        _q = queryString.stringify(param);
                      }else if(categoryName ){
                        const param = {category: categoryName ?categoryName:"", page: page+1, search: search ? search : "" };
                     _q = queryString.stringify(param);
                      }
                    // const _q = ""


                    //const  _q = `?page=${page +1}`
                    return (
                      <li
                        className={
                          // "page-item " +
                          (currentpage === page + 1 ? "active" : "")
                        }
                        key={idx}
                      >
                        <Link>
                        <div
                         onClick={() => {
                          try{
                              changeurl(`${base_url}/?${_q}`);
                              setcurrentpage(page + 1);
                        } catch(error){
                              console.log(error);
                        }
                      }}
                        >
                          {page + 1}
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </>
              );
            })()}
            {next ? (
              <>
                <li
                  onClick={() => {
                    changeurl(next);
                    setcurrentpage(currentpage + 1);
                  }}
                  disabled={!next}
                >
                  <Link>&gt;</Link>
                </li>
                <li
                  onClick={() => {
                    let _q;
                    if(categoryName =="전체" || !categoryName||categoryName ==null  ){
                      const queryParams = {
                        page: lastPage,
                        search: search ? search : "",
                      };
                     _q = queryString.stringify(
                          queryParams
                        )
                    }else if(categoryName) {
                     const queryParams = {
                      category: categoryName? categoryName :"" ,
                      page: lastPage,
                      search: search ? search : "",
                    };
                   _q = queryString.stringify(
                        queryParams
                      )
                    }
                    // const _q = ""
                    const lastpageurl = `${base_url}/?${_q}`;
                    changeurl(lastpageurl);
                    setcurrentpage(lastPage);
                  }}
                  disabled={!next}
                >
                  <Link>&raquo;</Link>
                </li>
              </>
            ) : (
              <>
                <li >
                  <Link >&gt;</Link>
                </li>
                <li
                 className="page-link"
                  onClick={() => {
                    let _q

                    if(categoryName =="전체" ||  !categoryName ||categoryName ==null ){
                      const queryParams = {
                       page: lastPage,
                        search: search ? search : "",
                      };
                       _q = queryString.stringify(
                          queryParams
                        )

                    }else if(categoryName){
                      const queryParams = {
                      category: categoryName? categoryName :"" ,
                      page: lastPage,
                      search: search ? search : "",
                    };
                     _q = queryString.stringify(
                        queryParams
                      )

                    }
                    // const _q = ""
                    const lastpageurl = `${base_url}/?${_q}`;
                    changeurl(lastpageurl);
                    setcurrentpage(lastPage);
                  }}
                >
                  <Link>&raquo;</Link>
                </li>
              </>
            )}
          </>
        ) : null}
      </ul>
    </div>
  );
};

export default LongPagination;
