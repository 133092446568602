import React, { Component, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ModalComponent from "../elements/common/ModalComponent";
import ScrollTop from "../elements/common/ScrollTop";


const VspaceFloor = () => {
  const [firstModal, setFirstModal] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [thirdModal, setThirdModal] = useState(false);
  return (
    <React.Fragment>
      <PageHelmet pageTitle="V-SPACE-Floor" />
      {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}

      {/* Start Breadcrump Area */}
      <Breadcrumb title={"층별안내도"} />
      {/* End Breadcrump Area */}

      {/* Start Service Area */}
      <div className="vspace-floor bg_color--1">
        <div className="container ">
             <img src={require("../../public/assets/images/about/whitethirdsquare.png")}className="img-third" 
               onClick={()=>setThirdModal(true)}/>
             <img src={require("../../public/assets/images/about/blacksquare.png")} className="img-second" 
               onClick={()=>setSecondModal(true)}/>
              <img src={require("../../public/assets/images/about/whitesquare.png")} className="img-first"
                onClick={()=>setFirstModal(true)}
                />
              <img src={require("../../public/assets/images/about/vspacefloor.png")} className="floorimg" />
              {/* <div
                className="section-title text-center mb--30"
                style={{ backgroundColor: "#343433" }}
              >
                     <img
                  src={require("../../public/assets/images/about/fourthfloor.png")}
                />
                <img
                  src={require("../../public/assets/images/about/thirdfloor.png")}
                  onClick={()=>setThirdModal(true)}
                />
                <img
                  src={require("../../public/assets/images/about/secondfloor.png")}
                  onClick={()=>setSecondModal(true)}
                />
                <img
                  src={require("../../public/assets/images/about/firstfloor.png")}
                  onClick={()=>setFirstModal(true)}
                 />
              </div> */}
          </div>
          {/* <Button variant="primary" onClick={handleImgClick}>
        Launch demo modal
      </Button> */}
          <ModalComponent show={firstModal} onHide={()=>setFirstModal(false)} imgUrl="firstfloor.png" floorNum={1}/>
          <ModalComponent show={secondModal} onHide={()=>setSecondModal(false)} imgUrl="secondfloor.png" floorNum={2} />
          <ModalComponent show={thirdModal} onHide={()=>setThirdModal(false)} imgUrl="thirdfloor.png" floorNum={3} />

      </div>
      {/* End Service Area */}

      {/* Start Back To Top */}
       <ScrollTop/>
      {/* End Back To Top */}
    </React.Fragment>
  );
};

export default VspaceFloor;
