import React, { useEffect, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import Pagination from "./common/Pagination";
import EquipmentsList from "./list/EquipmentsList";
import axiosInstance from "../axios/axiosInstance";
import TabSelect from "./common/TabSelect";
import qs from "qs";
import ScrollTop from "./common/ScrollTop";
import LongPagination from "./blog/LongPagination";
import WholeSearch from "./blog/WholeSearch";
import WSearch from "./blog/WSearch";

const Tools = () => {
  const [Equip, setEquip] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState({});
  const [category, setCategory]= useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const [previous, setPrevious] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [baseurl, setbaseurl] = useState("/worktools/items/");
  const [next, setNext] = useState(null);
  const postPerPage = 6;
  const [categoryId, setCategoryId]= useState("");
  // const categories = [
  //   { name: "전동공구", value: "전동공구" },
  //   { name: "수공구", value: "수공구" },
  // ];
  const EqCategory = async () => {
    try{  const response = await axiosInstance.get(`/worktools/categories/`)
    const categories = response.data;
    const categoryAll = {name:"전체", id: null};
    const categoriesWithAll = [categoryAll, ...categories];
    setCategory(categoriesWithAll);
    setSelectedItem(categoriesWithAll?.[0]);
}catch(error){
 console.log(error)
}
  }
      // const _query = category ? `?category=${category.value}` : "";
      //  console.log(_query);
      //  await axiosInstance.get(`/worktools/categories/`).then((response) => {
      //    setCategory(response.data);
      //    setSelectedItem(response.data?.[0])
      //  });
      // };
    // console.log(category)

  const EqList = async (category, page = 1) => {
    const _query = {}
    if(category) _query.category = category.id
    if(page) _query.page = page
    const _querystring = qs.stringify(_query)
    setCategoryId(category.id)
    // const _querystring = category && category.id !== null ? `?category=${category.id}`: ""; 
    await axiosInstance.get(`/worktools/items/${_querystring && `?` + _querystring}`).then((response) => {
      setEquip(response.data.results);
      setNext(response.data.next);
      setPrevious(response.data.previous);
      setTotalCount(response.data.count);
      setIsLoading(false);
    });
  };
  useEffect(() => {
    EqList(selectedItem);
  }, [selectedItem]);
  useEffect(() => {
    EqCategory();
  },[])

  const handleFetch = async (url) => {
    const result = await axiosInstance.get(url);
   try{ setEquip(result.data.results);
    setNext(result.data.next);
    setPrevious(result.data.previous);
    setTotalCount(result.data.count);
      setIsLoading(false);
   }catch(error){
    console.log(error)
   }
  };
  // const paginate = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  //   EqList(selectedItem, pageNumber)
  //   console.log("page", pageNumber);
  // };
  // const handleFetch = async (url) => {
  //   const result = await axiosInstance.get(url);
  //  try{ setEquip(result.data.results);
  //   setNext(result.data.next);
  
    // const itemsWithReservationCheck = [];
    //     for (const item of result.data.results) {
    //         const reservationResult = await axiosInstance.get(`/educations/reservation-check/${item.id}`);
    //         const newItem = {
    //             ...item,
    //             reservationResult: reservationResult.data, // 예약 확인 결과를 추가합니다.
    //           };
    //           itemsWithReservationCheck.push(newItem);
    //   };
    //   setReserveData(itemsWithReservationCheck);
  //     setIsLoading(false);
  //  }catch(error){
  //   console.log(error)
  //  }
  // };
  // useEffect(() => {
  //   // EduList();
  //   handleFetch(baseurl);
  // }, [baseurl]);

  return (
    <React.Fragment>
      <PageHelmet pageTitle="Blog" />

      <Breadcrumb title={'공구 현황'}  />


      <div className="bg_color--1 mt--30 mb--25">
        <div className="container plr--80 plr_sm--30 plr_md--30">
          <TabSelect items={category} onItemSelect={(item) => setSelectedItem(item)} selectedItem={selectedItem} />
        </div>
      </div>

      {/* Start Blog Area */}
      <div className="rn-blog-area pb--100 bg_color--1">
        <div className="container">
          <div className="row mt--60">
            <div className="col-lg-12">
              <EquipmentsList items={Equip} boardUrl={"/tools-details"} />
              {/* <Pagination
                totalPosts={totalcount}
                postsPerPage={postPerPage}
                paginate={paginate}
                currentPage={currentPage}
              /> */}

        {/* <WSearch setItems={setEquip} baseurl={baseurl} onChange={setSearch}/> */}

               <LongPagination
            search={search}
            items={Equip}
            previous={previous}
            base_url={baseurl}
            changeurl={handleFetch}
            setcurrentpage={setCurrentPage}
            totalcount={totalcount}
            perpage={6}
            next={next}
            categoryName={categoryId}
            currentpage={currentPage}
          />
          <WholeSearch setItems={setEquip} setNext={setNext} setPrevious={setPrevious} setTotalCount={setTotalCount} baseurl={baseurl}  onChange={setSearch}/>

            </div>
          </div>
        </div>
      </div>
      {/* End Blog Area */}

      {/* Start Back To Top */}
       <ScrollTop/>
      {/* End Back To Top */}
    </React.Fragment>
  );
};

export default Tools;
