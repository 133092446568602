import React, { useEffect, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import Pagination from "./common/Pagination";
import EquipmentsList from "./list/EquipmentsList";
import axiosInstance from "../axios/axiosInstance";
import TabSelect from "./common/TabSelect";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollTop from "./common/ScrollTop";
import WSearch from "./blog/WSearch";
import LongPagination from "./blog/LongPagination";
import WholeSearch from "./blog/WholeSearch";
const Equipments = () => {
  const [Equip, setEquip] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const postPerPage = 6;
  const [category, setCategory] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [baseurl, setbaseurl] = useState("/equips/items/");
  const [search, setSearch] = useState("");

  const [currentpage, setcurrentpage] = useState(1);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [totalcount, setTotalCount] = useState(0);
  const [categoryName, setCategoryName] = useState("");

  const EqCategory = async () => {
    //   const _query = category ? `?category=${category.value}` : "";
    // console.log(_query);
    try {
      const response = await axiosInstance.get(`/equips/categories/`);
      const categories = response.data;
      const categoryAll = { name: "전체", id: null };
      const categoriesWithAll = [categoryAll, ...categories];
      setCategory(categoriesWithAll);
      setSelectedItem(categoriesWithAll?.[0]);
    } catch (error) {
      console.log(error);
    }
    // .then((response) => {
    // setCategory(response.data);
    // setSelectedItem(response.data?.[0])
    // });
  };
  
  const EqList = async (category) => {
    const _query = category && category.name !== "전체" ? `?category=${category.name}` : "";
    await axiosInstance.get(`/equips/items/${_query}`).then((response) => {
      setEquip(response.data.results);
      setNext(response.data.next);
      setPrevious(response.data.previous);
      setTotalCount(response.data.count);
      setIsLoading(false);
      setCategoryName(category.name);
    });
  };
  console.log(category)

  useEffect(() => {
    EqList(selectedItem);
  }, [selectedItem]);

  useEffect(() => {
    EqCategory();
  },[])
  const handleFetch = async (url) => {
    const result = await axiosInstance.get(url);
   try{ setEquip(result.data.results);
    setNext(result.data.next);
    setPrevious(result.data.previous);
    setTotalCount(result.data.count);
      setIsLoading(false);
   }catch(error){
    console.log(error)
   }
  };
  useEffect(()=>{handleFetch(baseurl)}, [baseurl])

  // const lastPostIndex = currentPage * postPerPage;
  // const firstPostIndex = lastPostIndex - postPerPage;
  // const currentEquip = Equip.slice(firstPostIndex, lastPostIndex);
  console.log()
  return (
    <React.Fragment>
      <PageHelmet pageTitle="euipments reservation" />

      <Breadcrumb title="장비예약" />

      <div className="bg_color--1 mt--30 mb--25">
        <div className="container plr--80 plr_sm--30 plr_md--30">
          <TabSelect items={category} onItemSelect={(item) => setSelectedItem(item)} selectedItem={selectedItem} />
        </div>
      </div>

      {/* Start Blog Area */}
      <div className="rn-blog-area pb--120 bg_color--1">
        <div className="container">
          <div className="row mt--60">
            <div className="col-lg-12">

              <EquipmentsList items={Equip} boardUrl={"/equipments-details"} isLoading={isLoading}/>
              <LongPagination
            search={search}
            items={Equip}
            categoryName={categoryName}
            previous={previous}
            base_url={baseurl}
            changeurl={handleFetch}
            setcurrentpage={setcurrentpage}
            totalcount={totalcount}
            perpage={6}
            next={next}
            currentpage={currentpage}
          />
         <WholeSearch setItems={setEquip} setNext={setNext} setPrevious={setPrevious} setTotalCount={setTotalCount} baseurl={baseurl}  onChange={setSearch}/>

            </div>
          </div>
        </div>
      </div>
      {/* End Blog Area */}

      {/* Start Back To Top */}
      <ScrollTop />
      {/* End Back To Top */}
    </React.Fragment>
  );
};

export default Equipments;
