import React, { useEffect, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiCheck } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import useResize from "../hooks/useResize";
import axiosInstance from "../axios/axiosInstance";
import { useRecoilValue } from "recoil";
import { isLoginSelector } from "../recoil/User";
import { usePostDatas } from "../hooks/useApi";
import BreadcrumbGray from "./common/BreadcrumbGray";
import ScrollTop from "./common/ScrollTop";
import moment from "moment";
import Breadcrumb from "./common/Breadcrumb";

const MentoringDetails = () => {
  const [check, setCheck] = useState(false);
  const [guidCheck, setGuidCheck] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [timeTables, setTimeTables] = useState([]);
  const [mentoring, setMentoring] = useState([]);
  const [number, setNumber] = useState("");
  const [description, setDescription] = useState("");
  const [teamName, setTeamName] = useState("개인 및 팀");
  const [selectedLabels, setSelectedLabels] = useState([]);
  const navigate = useNavigate();
  const { date } = useParams();
  const isLogin = useRecoilValue(isLoginSelector);
  const postDatas = usePostDatas("/mentorings/reservation/", `/mentoring-confirm`, "mentoring");
  const windowWidth = useResize();
  const handleTimeClick = (time) => {
    setSelectedTime(time);
  };
  const handleBelongChange = (e) => {
    setSelectedOption(e.target.value);
  };
  // console.log(selectedLabels)

  const chkHandler = async (e) => {
    if (!isLogin) {
      window.alert("로그인이 필요합니다");
      window.location = "/login";
      return;
    }
    e.preventDefault();
    if (!selectedTime) {
      alert("예약시간을 선택해주시길 바랍니다.");
      return;
    }
    if (!teamName) {
      alert("팀을 선택해주시길 바랍니다.");
      return;
    }
    if (!number) {
      alert("신청인원(참여인원)을 입력해주시길 바랍니다.");
      return;
    }
    if (selectedLabels.length === 0) {
      alert("희망분야를 선택해주시길 바랍니다.");
      return;
    }
    if (!description) {
      alert("개발개요를 입력해주시길 바랍니다.");
      return;
    }
    if (!check) {
      alert("개인정보 수집 및 이용 동의를 선택해주시길 바랍니다.");
      return;
    }
    if (!guidCheck) {
      alert("안내사항에 동의해주실 바랍니다.");
      return;
    }

    // navigate("/mentoring");
    const info = {
      mentoring_id: 1,
      reservation_date: date,
      reservation_time_id: selectedTime.id,
      num_people: number,
      team_name: teamName,
      target_field: selectedLabels,
      descript: description,
    };
    await postDatas(info);
  };
  const mentoringList = async () => {
    let res = await axiosInstance.get(`/mentorings/items/1`);
    setMentoring(res.data);
    // setTimeTables(res.data.time_table)
    res = await axiosInstance.get(`/mentorings/time-table/?mentoring_id=${1}&reservation_date=${moment(date).format("YYYY-MM-DD")}`);
    console.log("setTimeTables", res.data);
    setTimeTables(res.data.time_tables);
  };

  const selectTeamOption = (e) => {
    setTeamName(e.target.value);
  };

  const handleCheckboxChange = (label) => {
    if (selectedLabels.includes(label)) {
      setSelectedLabels(selectedLabels.filter((item) => item !== label));
    } else {
      setSelectedLabels([...selectedLabels, label]);
    }
  };
  useEffect(() => {
    if (date) mentoringList();
  }, [date]);

  return (
    <React.Fragment>
      <PageHelmet pageTitle="Mentoring Details" />
      <Breadcrumb title="멘토링 및 제작지원 신청" />

      <div className="rn-portfolio-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <form method="post" onSubmit={chkHandler}>
                <div className="portfolio-details">
                  <div className="row row--35 mb--50">
                    <div className="col-lg-6">
                      <div className="inner">
                        <div className="section-title">
                          <h2 className="title">V-SPACE 멘토링 및 제작지원 신청하기</h2>
                          {/* <p className="description mt--30">메쉬믹서(3D스캔) 교육에 참가하기 위한 기본 프로필을 작성합니다.<br/>
작성 내용은 V-SPACE에서 규정하는 정보이용에만 활용됩니다.</p> */}
                          {/* <p className="description mt--30">
                      설명
                      </p> */}
                          {/* <p className="description">
                        * 해당 교육은 신청전 3D스캐너 온라인교육을 선행학습
                        하시는 것을 추천드립니다.
                      </p> */}
                          {/* <table className="application-table">
                          <thead>
                            </thead>
                          <tbody>
                            <tr>
                              <td>예약일</td>
                              <td colSpan={2}>{date}</td>
                            </tr>
                            <tr>
                              <td>예약시간</td>
                              <td colSpan={2}>
                              {timeTables.map((time, key) => {
                                  return (
                                    <button
                                    className={`time-btn 
                                    ${time.is_reserved ? "disabled": ""}
                                    ${!time.is_reserved && selectedTime === time
                                      ? "selected"
                                      : ""
                                  }`}
                                      key={key}
                                      type="button"
                                      onClick={() => handleTimeClick(time)}
                                    > 
                                      {time.start_time.slice(0,5)}~{time.end_time.slice(0,5)}
                                    </button>
                                  );
                                })}
                              </td>
                            </tr>
                            <tr>
                              <td>팀명 및 참여인원<br/>(업체명 기입 가능)</td>
                              <td>
                                <select  onChange={selectTeamOption}>
                                  <option value="개인 및 팀">개인 및 팀</option>
                                  <option value="기업">기업</option>
                                </select>
                              </td>
                              <td>
                                <input type="text" onChange={(e)=>setNumber(e.target.value)}/> 명
                              </td>
                            </tr>
                            <tr>
                              <td rowSpan={2}>희망분야선택 <br/>(중복선택 가능)</td>
                              <td>제품 개발</td>
                              <td>제품양산</td>
                            </tr>
                            <tr>
                              <td> 
                                <div className="d-flex"><input type="checkbox" onChange={() => handleCheckboxChange('설계 및 모델링')}/><label>설계 및 모델링</label></div>
                                <div className="d-flex"> <input type="checkbox"onChange={() => handleCheckboxChange('외형제작')} /><label>외형제작</label></div>
                              </td>
                              <td>
                              <div className="d-flex"><input type="checkbox" onChange={() => handleCheckboxChange('워킹목업')}/> <label>워킹목업</label></div>
                              <div className="d-flex"><input type="checkbox"  onChange={() => handleCheckboxChange('금형사출')}/><label>금형사출 </label></div>
                              <div className="d-flex"><input type="checkbox"onChange={() => handleCheckboxChange('양산 연게')} />  <label>양산 연게</label></div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                개발개요<br/>
                                (담당자 배정을 위한 필수 내용입니다)
                              </td>
                              <td colSpan={2}>
                                <textarea rows={4} cols={50}onChange={(e)=> setDescription(e.target.value)}></textarea>
                              </td>
                            </tr>
                          </tbody>
                        </table> */}

                          <ul className="list-style--1 mt--30">
                            <li>
                              <FiCheck />
                              예약일자 : {date}
                            </li>
                            <li>
                              <FiCheck />
                              예약시간 : <br />
                              <div className="mt--10 plr--10">
                                {timeTables.map((time, key) => {
                                  return (
                                    <button
                                      className={`time-btn 
                                    ${time.is_reserved ? "btn-disabled" : ""}
                                    ${!time.is_reserved && selectedTime === time ? "selected" : ""}`}
                                      style={time.is_reserved ? { cursor: "not-allowed", background: "#ddd" } : { cursor: "pointer" }}
                                      key={key}
                                      type="button"
                                      onClick={() => {
                                        if (!time.is_reserved) handleTimeClick(time);
                                      }}
                                    >
                                      {time.start_time.slice(0, 5)}~{time.end_time.slice(0, 5)}
                                    </button>
                                  );
                                })}
                              </div>
                            </li>
                            <li>
                              <FiCheck />팀 :
                              <select onChange={selectTeamOption}>
                                <option value="개인 및 팀">개인 및 팀</option>
                                <option value="기업">기업</option>
                              </select>
                            </li>
                            <li>
                              <FiCheck />
                              신청인원(참여인원) :
                              <input type="text" onChange={(e) => setNumber(e.target.value)} className="member-input" />명
                            </li>
                            <li>
                              <FiCheck /> 희망분야(중복선택가능):
                              {windowWidth > 777 ? (
                                <div className="d-flex align-items-center justify-content-around mt--10 plr--10">
                                  <input type="checkbox" className="check_hope" onChange={() => handleCheckboxChange("설계 및 모델링")} />
                                  <label>설계 및 모델링</label>
                                  <input type="checkbox" className="check_hope" onChange={() => handleCheckboxChange("외형제작")} />
                                  <label>외형제작</label>
                                  <input type="checkbox" className="check_hope" onChange={() => handleCheckboxChange("워킹목업")} />
                                  <label>워킹목업</label>
                                  <input type="checkbox" className="check_hope" onChange={() => handleCheckboxChange("금형사출")} />
                                  <label>금형사출 </label>
                                  <input type="checkbox" className="check_hope" onChange={() => handleCheckboxChange("양산 연계")} />
                                  <label>양산 연계</label>
                                </div>
                              ) : windowWidth > 500 ? (
                                <>
                                  <div className="d-flex align-items-center mt--10 plr--10">
                                    <input type="checkbox" className="check_hope" onChange={() => handleCheckboxChange("설계 및 모델링")} />
                                    <label>설계 및 모델링</label>
                                    <input type="checkbox" className="check_hope" onChange={() => handleCheckboxChange("외형제작")} />

                                    <label>외형제작</label>
                                    <input type="checkbox" className="check_hope" onChange={() => handleCheckboxChange("워킹목업")} />
                                    <label>워킹목업</label>
                                    <input type="checkbox" className="check_hope" onChange={() => handleCheckboxChange("금형사출")} />
                                    <label>금형사출 </label>
                                  </div>
                                  <div className="d-flex align-items-center plr--10">
                                    <input type="checkbox" className="check_hope" onChange={() => handleCheckboxChange("양산 연계")} />
                                    <label>양산 연계</label>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="d-flex align-items-center mt--10 plr--10">
                                    <input type="checkbox" className="check_hope" onChange={() => handleCheckboxChange("설계 및 모델링")} />
                                    <label>설계 및 모델링</label>
                                    <input type="checkbox" className="check_hope" onChange={() => handleCheckboxChange("외형제작")} />

                                    <label>외형제작</label>
                                  </div>
                                  <div className="d-flex align-items-center plr--10">
                                    <input type="checkbox" className="check_hope" onChange={() => handleCheckboxChange("워킹목업")} />
                                    <label>워킹목업</label>
                                    <input type="checkbox" className="check_hope" onChange={() => handleCheckboxChange("금형사출")} />
                                    <label>금형사출 </label>
                                    <input type="checkbox" className="check_hope" onChange={() => handleCheckboxChange("양산 연계")} />
                                    <label>양산 연계</label>
                                  </div>
                                </>
                              )}
                            </li>
                            <li>
                              <FiCheck /> 개발개요(담당자 배정을 위한 필수 내용입니다)
                              <textarea className="mt--10 plr--10" rows={4} cols={50} onChange={(e) => setDescription(e.target.value)} placeholder="필수입력"></textarea>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="portfolio-details-accordion mt--100 mt_md--40 mt_sm--40">
                        <div className="inner">
                          <div className="section-title">
                            <div className="d-flex align-items-center">
                              <h5>개인정보 수집 이용 동의</h5>
                              <div className="ml--10">
                                <input type="checkbox" className="chk" onChange={(e) => setCheck(e.target.checked)} />
                              </div>
                            </div>
                            <p className="description">
                              - 항목 : 성명, 소속, 직위, 휴대전화, 작업내용, 이메일
                              <br />
                              - 개인 정보 제공 기관 : 창업진흥원, 부산대학교 산학협력단
                              <br />- 수집목적 : 프로그램 신청 및 운영, 사업보고 보유기간 : 사업종료 후 5년
                            </p>
                            {/* <div className="d-flex align-items-center">
                          <h5>사진촬영 및 이용 동의</h5>
                          <div className="ml--10">
                            <input
                              type="checkbox"
                              className="chk"
                              onChange={(e) => setPhotoCheck(e.target.checked)}
                            />
                          </div>
                        </div>
                        <p className="description">
                          교육 중 사진촬영 및 이용(홍보, 증빙자료 등)에 대해
                          동의합니다
                        </p> */}

                            <div className="d-flex align-items-center">
                              <h5>안내사항 동의</h5>
                              <div className="ml--10">
                                <input type="checkbox" className="chk" onChange={(e) => setGuidCheck(e.target.checked)} />
                              </div>
                            </div>
                            <p className="description">
                              - 홈페이지를 통해 해당 예약건 확인 후 담당자가 연락을 드립니다. <br />
                              - 교내 무료 주차는 지원되지 않으며, 주차 규정은 부산대학교 주차관리 규정에 따릅니다.
                              <br />
                            </p>
                          </div>
                        </div>
                        <div className="portfolio-details-btn mt--30 d-flex  justify-content-end">
                          <button className="btn-default btn-border" type="submit">
                            신청하기
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Start Back To Top */}
      <ScrollTop />
      {/* End Back To Top */}
    </React.Fragment>
  );
};

export default MentoringDetails;
