import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EditorToolbar, { modules, formats } from "../../elements/blog/EditorToolbar";
import React from "react";
// import ImageResize  from "quill-image-resize"; 

const QuillEditor = ({value, onChange , placeholder}) => {

  // if (!Quill.register("modules/imageResize")) {
  //   Quill.register("modules/imageResize", ImageResize);
  // }
  return (
    <div>
     <EditorToolbar/>
      <ReactQuill
        theme="snow" 
        modules={modules}
        formats={formats}
        value={value}
        onChange={onChange}
        className="reactquill"
        // placeholder="내용을 입력해주세요"
      />
    </div>
  );
};

export default QuillEditor;
