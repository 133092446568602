// import axios from "axios";
// import axiosInstance from "../../../axios";
import React, {  useEffect, useState } from "react";
import { useNavigate ,Link} from "react-router-dom";
import axiosInstance from "../../axios/axiosInstance";
import { TokenAtom } from "../../recoil/User";
import { useRecoilState } from "recoil";
const Register = () => {
  const [id, setId] = useState("");
  const [passwd, setPasswd] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [department, setDepartment] = useState("");
  const [interest, setInterest] = useState("");
  const [repasswd, setRepasswd] = useState("");
  //유효성 검사
  const [pwValid, setPwValid] = useState(false)
  const [EmailValid, setEmailValid] = useState(false);
  const [rank, setRank]=useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [others, setOthers] =useState("");
  const navigate = useNavigate();
  const [classification, setClassification]=useState([]);
  const [selectedId, setSelectedId] = useState(null); 
  const [accessToken, setAccessToken] = useRecoilState(TokenAtom);


  async function HandleForm(e) {
    e.preventDefault();

      const info ={
        user_id: id,
        email: email,
        password: passwd,
        username: username,
        company_name: rank,
        classification_id: selectedOption,
        department: department,
        interest: interest,
        phone_num: phone,
      };

      let profileInfo ={
        username: username,
        company_name: rank,
        classification_id: selectedOption,
        department: department,
        interest: interest,
        phone_num: phone,
      }
      await axiosInstance
        .post("/users/register/", info)
        .then((response) => {
          alert("회원가입이 완료 되었습니다");
          window.location="/login";
        }).catch((err) =>{
          if(err.response.status ===400){
            var errMsg = JSON.stringify(err.response.data).split('"')
            alert(errMsg[errMsg.length-2])
          }
          console.log(err)
        }
  
        )

  }

  const handlePw = (e) =>{
    setPasswd(e.target.value);
    if (passwd.length < 7) {
      return setPwValid(false);
    }
    setPwValid(true);
  }

  const handleOptionChange= (e)=>{
    setSelectedOption(e.target.value);
  }

  console.log(selectedOption)
  const handleEmail= (e) =>{
    setEmail(e.target.value);
    const regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    if(regex.test(email.trim())){
      setEmailValid(true);
    }else{
      setEmailValid(false);
    }
  }


 const classificationList = async () => {
  await axiosInstance.get(`/users/classifications/`).then((res) => {
    // console.log(res)
    setClassification(res.data.data);
  });
};
   useEffect(()=>{
    classificationList();
   },[])
  return (
    <div className="signInWrap">
     <div className="signIn-box">
       <div className="signIn-container">
        <h2 className="signHead">회원가입</h2>
           <form method="post" onSubmit={HandleForm} autoComplete="off"> 
           <input
              type="text"
              name="id"
              value={id}
              placeholder="아이디"
              onChange={(e)=> setId(e.target.value)}
              required
            />
           
            <input 
              type="password" 
              name="passwd" 
              value={passwd}
              onChange={handlePw}
              required
              placeholder="비밀번호"/>
              <div className="errorMessage">
                {
                  !pwValid && passwd.length>0 && (
                    <div>8자 이상 입력해주세요</div>
                  )
                }
              </div>
            <input
              type="password"
              name="repasswd"
              placeholder="비밀번호확인"
              value={repasswd}
              required
              onChange={(e)=> setRepasswd(e.target.value)}
            />
            <div className="errorMessage">
                {
                   passwd !==repasswd && repasswd.length>0 && (<div>비밀번호가 일치하지않습니다</div>
                  )
                }
              </div>
            <input
              type="text"
              name="username"
              placeholder="이름"
              required
              onChange={(e) => setUsername(e.target.value)}
            />
            {/* <input
              type="text"
              name="department"
              placeholder="소속(학과) ex)부산대학교 기계공학부"
              required
              onChange={(e) => setDepartment(e.target.value)}
            /> */}
          <select required onChange={handleOptionChange} className="select-belong">    
            <option value="" disabled selected hidden >소속을 선택해주세요</option>
            {classification.map((item) => {
                return(
                  <option key={item.id} value={item.id}>{item.name}</option>
                )
            })}
            </select>
            {/* <select required onChange={handleOptionChange} className="select-belong">
               <option value="" disabled selected hidden >소속을 선택해주세요</option>
              <option value="부산대학교">부산대학교</option>
              <option value="초중고등학생">초중고등학생</option>
              <option value="대학교(부산대학교외)">대학교(부산대학교외)</option>
              <option value="(예비)창업기업">(예비)창업기업</option>
              <option value="일반기업">일반기업</option>
              <option value="기타">기타</option>
            </select> */}
            <input 
            type="text"
            name="department"
             onChange={(e)=>setDepartment(e.target.value)}
              placeholder="소속을 꼭 상세하게 기입해주세요(ex)기업명, 학교명과 학과)"/>
            <input
              type="text"
              name="rank"
              placeholder="직급 ex)학생, 대기, 기타"
              required
              onChange={(e) => setRank(e.target.value)}
            />
            <input
              type="text"
              name="phone"
              placeholder="전화번호(번호만 입력해주세요) ex)01012345678"
              required
              onChange={(e) => setPhone(e.target.value)}
            />
            <input
              type="text"
              name="email"
              placeholder="이메일"
              required
              onChange={handleEmail}
            />
              <div className="errorMessage">
                {
                  (!EmailValid && email.length>0) && (
                    <div>이메일형식으로 입력해주세요</div>
                  )
                }
              </div>
            <input
              type="text"
              name="interest"
              placeholder="관심분야"
              onChange={(e) => setInterest(e.target.value)}
            />
            <button className="registerBtn"> 가입 </button>
            <Link to="/login">
              <strong>계정이 있으신가요?</strong>
            </Link>
          </form>
        </div>
       </div>
    </div>
  );
};

export default Register;
