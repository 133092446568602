// import queryString from "query-string";
import React, { useEffect } from "react";
const queryString = require('querystring');

const Pagination = (props) => {
  const {
    search,
    notices,
    previous,
    base_url,
    changeurl,
    setcurrentpage,
    totalcount,
    perpage,
    next,
    currentpage,
    ...other
  } = props;
  const lastPage = Math.ceil(totalcount / perpage);

  return (
    <nav aria-label="Page navigation example">
    <ul className="pagination">
       {notices && notices.length ?
      <>
        {previous ? (
          <>
            <li className="page-item">
            <div className="page-link"
             onClick={() => {
              const param = { page: 1, search };
            const _q = queryString.stringify(param);
            changeurl(`${base_url}/?${ _q}`);
              setcurrentpage(1);
            }}
            disabled={!previous}>
         &laquo;
            </div>
          </li>
          <li className="page-item"
            onClick={() => {
              changeurl(previous);
              setcurrentpage(currentpage - 1);
            }}
            disabled={!previous}
          >
            <div className="page-link">
            &lt;
            </div>
          </li>
          </>
        ) : (
          <>
          <li className="page-item">
          <div className="page-link"
           onClick={() => {
             const param = { page: 1, search };
            const _q = queryString.stringify(param);
            changeurl(`${base_url}/?${ _q}`);
            setcurrentpage(1);
          }}
          disabled={!previous}>
         &laquo;
          </div>
        </li>
        <li className="page-item disabled"
        >
          <div className="page-link">
          &lt;
          </div>
        </li>
        </>                  )}
          {(() => {
            let _pages = [
              ...Array(Math.floor(totalcount / perpage)).keys(),
            ];
            if (Math.floor(totalcount / perpage) < totalcount / perpage) {
              _pages.push(_pages.length);
            }
            let _from = Math.floor((currentpage - 1) / 10) * 10;
            if (_pages.length > _from + 10) {
              _pages = _pages.slice(_from, _from + 10);
              _pages.push("...");
            } else {
              _pages = _pages.slice(_from, _pages.length);
            }
             return (
              <>
                {_pages.map((page, idx) => {
                  if (page === "...") return <li>...</li>;
                  
                  const param = { page: page + 1, search };
                  const _q = queryString.stringify(param);
                  
                  //const  _q = `?page=${page +1}`
                  return (
                    <li
                      className={
                        "page-item " + (currentpage === page + 1 ? "active" : "")
                      }
                      key={idx}
                    >
                      <div
                        className="page-link"
                        onClick={() => {
                          // const seturl =  new URL(baseurl);
                          // seturl.searchParams.set("page", page + 1);
                          // const modifiedUrl = seturl.toString(); 
                          try{
                              changeurl(`${base_url}/?${_q}`);
                              setcurrentpage(page + 1);
                         //let modifiedUrl;
                        //   if (page === 0) {
                       //     modifiedUrl = `${base_url}/`;
                        //   }else {
                        //     const url = new URL(base_url);
                        //     url.searchParams.set("page", page + 1);
                        //     modifiedUrl = url.toString();
                        //   }

                        // changeurl(
                        //      modifiedUrl
                        //   ); 
                          setcurrentpage(page + 1);
                        } catch(error){
                              console.log(error);
                        }
                      }}
                      >
                        {page + 1}
                      </div>
                    </li>
                  );
                })}
              </>
            );
          })()}
        {next ? (
          <>
            <li className="page-item"
            onClick={() => {
              changeurl(next);
              setcurrentpage(currentpage + 1);
            }}
            disabled={!next}
          >
            <div className="page-link">
            &gt;
            </div>
          </li>
          <li className="page-item"
            onClick={() => {
              const queryParams = {page: lastPage, search};
              const lastpageurl = `${base_url}/?${queryString.stringify(queryParams)}`
              changeurl(lastpageurl);
              setcurrentpage(lastPage);
            }}
            disabled={!next}
          >
            <div className="page-link">
            &raquo;
            </div>
          </li>
          </>
        ) : (
          <>
          <li className="page-item disabled">
            <div className="page-link">
            &gt;
            </div>
          </li>
          <li className="page-item"
        onClick={() => {
          const queryParams = {page: lastPage, search};
          const lastpageurl = `${base_url}/?${queryString.stringify(queryParams)}`
          changeurl(lastpageurl);
          setcurrentpage(lastPage);
        }}
          >
            <div className="page-link">
            &raquo;
            </div>
          </li>
          </>
        )}
      </>: null}  
      </ul>
  </nav>
  );
};

export default Pagination;
