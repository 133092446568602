import React, { Component } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import ScrollTop from './common/ScrollTop';

 class error404 extends Component {
    
    render() {
        return (
            <>
                {/* Start Page Error  */}
                <div className="error-page-inner bg_color--4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                {/* <img src="/assets/images/logo/logo_white.png"/> */}

                                    <h1 className="title theme-gradient">
                                    404
                                        {/* <img src="/assets/images/logo/log_white.png"/> */}
                                    </h1>
                                    <h3 className="sub-title">찾을수 없는 페이지입니다.</h3>
                                    <span>요청하신 페이지가 사라졌거나 잘못된 경로를 이용하셨어요</span>
                                    <div className="error-button">
                                        <a className="btn-default" href="/">Back To Homepage</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Error  */}

               <ScrollTop/>
            </>
        )
    }
}
export default error404;
