// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";
// Create Import File
import "./index.scss";

// Common Layout
// import Layout from "./element/common/App";

// import PageScrollTop from './element/PageScrollTop';

// Home layout
import MainDemo from "./home/MainDemo";
import Error from "./elements/error404";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

//newone
import Navbar from "../src/component/header/Navbar";
import Checkout from "./blocks/Checkout";
import VspaceAbout from "./elements/VspaceAbout";
import Equipments from "./elements/Equipments";
import EquipmentssDetail from "./elements/EquipmentsDetail";
import Map from "./blocks/Map";
import PrivacyPolicy from "./blocks/PrivacyPolicy";
import Education from "./elements/Education";
import EducationDetail from "./elements/EducationDetail";
import Tools from "./elements/Tools";
import ToolsDetail from "./elements/ToolsDetail";
import Mentoring from "./elements/Mentoring";
import Tour from "./elements/Tour";
import Space from "./elements/Space";
import SpaceDetail from "./elements/SpaceDetail";
import MentoringDetails from "./elements/MentoringDetails";
import TourDetails from "./elements/TourDetails";
import VspaceFloor from "./blocks/VspaceFloor";
import ReservationTableDetail from "./blocks/login/mypageTab/ReservationTableDetail";
//confirm
import EquipmentsConfirm from "./elements/confirm/EquipmentsConfirm";
import SpaceConfirm from "./elements/confirm/SpaceConfirm";
import TourConfirm from "./elements/confirm/TourConfirm";
import EducationConfirm from "./elements/confirm/EducationConfirm";
import MentoringConfirm from "./elements/confirm/MentoringConfirm";
import OnlineEuipConfirm from "./elements/confirm/OnlineEuipConfirm";
import EventConfirm from "./elements/confirm/EventConfirm";

//login register mypage
import Login from "./blocks/login/Login";
import Register from "./blocks/login/Register";
import Registerconfirm from "./blocks/login/Registerconfirm";
import FindId from "./blocks/login/FindId";
import Mypage from "./blocks/login/Mypage";

//board
import Board from "./blocks/boards/board/Board";
import BoardDetail from "./blocks/boards/board/BoardDetail";
import BoardUpdate from "./blocks/boards/board/BoardUpdate";
import BoardInsert from "./blocks/boards/board/BoardInsert";

//gallery
import Gallery from "./blocks/boards/gallery/Gallery";
import GalleryDetail from "./blocks/boards/gallery/GalleryDetail";
import GalleryUpdate from "./blocks/boards/gallery/GalleryUpdate";
import GalleryInsert from "./blocks/boards/gallery/GalleyInsert";

//notice
import Notice from "./blocks/boards/notice/Notice";
import NoticeDetail from "./blocks/boards/notice/NoticeDetail";
import NoticeUpdate from "./blocks/boards/notice/NoticeUpdate";
import NoticeInesrt from "./blocks/boards/notice/NoticeInsert";

//qnaboard
import QnaBoard from "./blocks/boards/qnaboard/QNABoard";
import QnaBoardDetail from "./blocks/boards/qnaboard/QNABoardDetail";
import QnaBoardUpdate from "./blocks/boards/qnaboard/QNABoardUpdate";
import QnaBoardInsert from "./blocks/boards/qnaboard/QNABoardInsert";

//reference
import Reference from "./blocks/boards/reference/Reference";
import ReferenceDetail from "./blocks/boards/reference/ReferenceDetail";
import ReferenceUpdate from "./blocks/boards/reference/ReferenceUpdate";
import ReferenceInsert from "./blocks/boards/reference/ReferenceInsert";

import { RecoilRoot } from "recoil";
import VspaceTeam from "./blocks/VspaceTeam";
import Email from "./blocks/Email";
import EquipStatus from "./elements/EquipStatus";

//Utiliz
import OnlineEquipList from "./elements/OnlineEquipList";
import EventList from "./elements/EventList";
import OnlineEquipListDetail from "./elements/OnlineEquipListDetail";
import EventListDetail from "./elements/EventListDetail";
import ProgramUtilize from "./elements/use/ProgramUtilize";
import FacilityUtilize from "./elements/use/FacilityUtilize";
import EquipmentUtilize from "./elements/use/EquipmentUtilize";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        {/* <PageScrollTop> */}
        <RecoilRoot>
          <Routes>
            <Route element={<Navbar />}>
              {/* <Route exact path={`${process.env.PUBLIC_URL}/`} element={Demo}/> */}
              <Route exact path={"/"} element={<MainDemo />} />
              {/* <Route exact path={`${process.env.PUBLIC_URL}/dark-main-demo`} element={DarkMainDemo}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/startup`} element={Startup}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/paralax`} element={Paralax}/> */}

              {/* new */}
              <Route exact path={`${process.env.PUBLIC_URL}/vspaceAbout`} element={<VspaceAbout />} />
              <Route exact path={`${process.env.PUBLIC_URL}/vspaceFloor`} element={<VspaceFloor />} />
              <Route exact path={`${process.env.PUBLIC_URL}/vspaceTeam`} element={<VspaceTeam />} />
              <Route exact path={`${process.env.PUBLIC_URL}/equipments`} element={<Equipments />} />
              <Route exact path={`${process.env.PUBLIC_URL}/equipments-details/:id`} element={<EquipmentssDetail />} />
              <Route exact path={`${process.env.PUBLIC_URL}/checkout`} element={<Checkout />} />
              <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<Login />} />
              <Route exact path={`${process.env.PUBLIC_URL}/register`} element={<Register />} />
              <Route exact path={`${process.env.PUBLIC_URL}/map`} element={<Map />} />
              <Route exact path={`${process.env.PUBLIC_URL}/findid`} element={<FindId />} />
              <Route exact path={`${process.env.PUBLIC_URL}/mypage`} element={<Mypage />} />
              <Route exact path={`${process.env.PUBLIC_URL}/mypage-reservation/:id`} element={<ReservationTableDetail />} />
              <Route exact path={`${process.env.PUBLIC_URL}/registerconfirm`} element={<Registerconfirm />} />
              <Route exact path={`${process.env.PUBLIC_URL}/privacy`} element={<PrivacyPolicy />} />
              <Route exact path={`${process.env.PUBLIC_URL}/emailPrivacy`} element={<Email />} />
              <Route exact path={`${process.env.PUBLIC_URL}/education`} element={<Education />} />
              <Route exact path={`${process.env.PUBLIC_URL}/education-detail/:id`} element={<EducationDetail />} />
              <Route exact path={`${process.env.PUBLIC_URL}/tools`} element={<Tools />} />
              <Route exact path={`${process.env.PUBLIC_URL}/tools-details/:id`} element={<ToolsDetail />} />
              <Route exact path={`${process.env.PUBLIC_URL}/mentoring`} element={<Mentoring />} />
              <Route exact path={`${process.env.PUBLIC_URL}/mentoring-details/:date`} element={<MentoringDetails />} />
              <Route exact path={`${process.env.PUBLIC_URL}/tour`} element={<Tour />} />
              <Route exact path={`${process.env.PUBLIC_URL}/tour-details/:date`} element={<TourDetails />} />
              <Route exact path={`${process.env.PUBLIC_URL}/space`} element={<Space />} />
              <Route exact path={`${process.env.PUBLIC_URL}/space-details/:id`} element={<SpaceDetail />} />
              <Route exact path={`${process.env.PUBLIC_URL}/equipstatus`} element={<EquipStatus />} />
              <Route exact path={`${process.env.PUBLIC_URL}/online-equip`} element={<OnlineEquipList />} />
              <Route exact path={`${process.env.PUBLIC_URL}/event-participate`} element={<EventList />} />
              <Route exact path={`${process.env.PUBLIC_URL}/online-equip-detail/:id`} element={<OnlineEquipListDetail />} />
              <Route exact path={`${process.env.PUBLIC_URL}/event-participate-detail/:id`} element={<EventListDetail />} />

              {/* confirm */}
              <Route exact path={`${process.env.PUBLIC_URL}/space-confirm/:id`} element={<SpaceConfirm />} />
              <Route exact path={`${process.env.PUBLIC_URL}/equipments-confirm/:id`} element={<EquipmentsConfirm />} />
              <Route exact path={`${process.env.PUBLIC_URL}/tour-confirm/:id`} element={<TourConfirm />} />
              <Route exact path={`${process.env.PUBLIC_URL}/education-confirm/:id`} element={<EducationConfirm />} />
              <Route exact path={`${process.env.PUBLIC_URL}/online-equip-confirm/:id`} element={<OnlineEuipConfirm />} />
              <Route exact path={`${process.env.PUBLIC_URL}/mentoring-confirm/:id`} element={<MentoringConfirm />} />
              <Route exact path={`${process.env.PUBLIC_URL}/event-confirm/:id`} element={<EventConfirm />} />

              {/* boards */}
              <Route exact path={`${process.env.PUBLIC_URL}/board`} element={<Board />} />
              <Route exact path={`${process.env.PUBLIC_URL}/board/detail/:id`} element={<BoardDetail />} />
              <Route exact path={`${process.env.PUBLIC_URL}/board/update/:id`} element={<BoardUpdate />} />
              <Route exact path={`${process.env.PUBLIC_URL}/board/insert`} element={<BoardInsert />} />
              <Route exact path={`${process.env.PUBLIC_URL}/notice`} element={<Notice />} />
              <Route exact path={`${process.env.PUBLIC_URL}/notice/detail/:id`} element={<NoticeDetail />} />
              <Route exact path={`${process.env.PUBLIC_URL}/notice/update/:id`} element={<NoticeUpdate />} />
              <Route exact path={`${process.env.PUBLIC_URL}/notice/insert`} element={<NoticeInesrt />} />
              <Route exact path={`${process.env.PUBLIC_URL}/gallery`} element={<Gallery />} />
              <Route exact path={`${process.env.PUBLIC_URL}/gallery/detail/:id`} element={<GalleryDetail />} />
              <Route exact path={`${process.env.PUBLIC_URL}/gallery/update/:id`} element={<GalleryUpdate />} />
              <Route exact path={`${process.env.PUBLIC_URL}/gallery/insert`} element={<GalleryInsert />} />
              <Route exact path={`${process.env.PUBLIC_URL}/qnaboard`} element={<QnaBoard />} />
              <Route exact path={`${process.env.PUBLIC_URL}/qnaboard/detail/:id`} element={<QnaBoardDetail />} />
              <Route exact path={`${process.env.PUBLIC_URL}/qnaboard/update/:id`} element={<QnaBoardUpdate />} />
              <Route exact path={`${process.env.PUBLIC_URL}/qnaboard/insert`} element={<QnaBoardInsert />} />
              <Route exact path={`${process.env.PUBLIC_URL}/reference`} element={<Reference />} />
              <Route exact path={`${process.env.PUBLIC_URL}/reference/detail/:id`} element={<ReferenceDetail />} />
              <Route exact path={`${process.env.PUBLIC_URL}/reference/update/:id`} element={<ReferenceUpdate />} />
              <Route exact path={`${process.env.PUBLIC_URL}/reference/insert`} element={<ReferenceInsert />} />

              {/* Utilize Layot */}
              <Route exact path={`${process.env.PUBLIC_URL}/facility-utilize`} element={<FacilityUtilize />} />
              <Route exact path={`${process.env.PUBLIC_URL}/equip-utilize`} element={<EquipmentUtilize />} />
              <Route exact path={`${process.env.PUBLIC_URL}/program-utilize`} element={<ProgramUtilize />} />

              {/* <Route path={`${process.env.PUBLIC_URL}/404`} element={<error404/>}/> */}
              <Route path="*" element={<Error />} />
            </Route>
          </Routes>
        </RecoilRoot>
        {/* </PageScrollTop> */}
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
