import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className='privacy-container  ptb--100'>
      <div className='privacy plr--80 plr_sm--30 plr_md--30'>
          <h4>개인정보 수집 이용</h4>
          <br/>
          <h5>:::: V-SPACE 개인정보취급방침 ::::</h5>
          <br/>
          <p>
              V-SPACE은(는) 이용자들의 개인정보보호를 매우 중요시하며, 이용자가
              회사의 서비스를 이용함과 동시에 온라인상에서 회사에 제공한
              개인정보가 보호 받을 수 있도록 최선을 다하고 있습니다.<br /> 이에
              V-SPACE는 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및
              정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할
              관련 법규상의 개인정보보호 규정 및 정보통신부가 제정한
              개인정보보호지침을 준수하고 있습니다. <br />V-SPACE는 개인정보
              취급방침을 통하여 이용자들이 제공하는 개인정보가 어떠한 용도와
              방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가
              취해지고 있는지 알려 드립니다. <br /><br />V-SPACE는 개인정보 취급방침을
              홈페이지 첫 화면에 공개함으로써 이용자들이 언제나 용이하게 보실 수
              있도록 조치하고 있습니다. <br /><br />회사의 개인정보 취급방침은 정부의 법률
              및 지침 변경이나 회사의 내부 방침 변경 등으로 인하여 수시로 변경될
              수 있고, 이에 따른 개인정보 취급방침의 지속적인 개선을 위하여
              필요한 절차를 정하고 있습니다.<br /> 그리고 개인정보 취급방침을 개정하는
              경우나 개인정보 취급방침 변경될 경우 V-SPACE 홈페이지의 첫페이지의
              개인정보취급방침을 통해 고지하고 있습니다.<br /> 이용자들께서는 사이트
              방문 시 수시로 확인하시기 바랍니다. <br /><br />
              1. 개인정보의 수집목적 및이용목적<br /><br />
               "개인정보"라 함은 생존하는 개인에 관한 정보로서 당해
              정보에 포함되어 있는 성명, 주민등록번호 등의 사항에 의하여 당해
              개인을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수
              없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)를
              말합니다. <br /><br />대부분의 서비스는 별도의 사용자 등록이 없이 언제든지
              사용할 수 있습니다.<br /> 그러나 V-SPACE는 회원서비스를 통하여
              이용자들에게 맞춤식 서비스를 비롯한 보다 더 향상된 양질의 서비스를
              제공하기 위하여 이용자 개인의 정보를 수집하고 있습니다.<br /><br /> V-SPACE는
              이용자의 사전 동의 없이는 이용자의 개인 정보를 공개하지 않으며,
              수집된 정보는 아래와같이 이용하고 있습니다. <br /><br />첫째, 이용자들이
              제공한 개인정보를 바탕으로 보다 더 유용한 서비스를 개발할 수
              있습니다. <br />V-SPACE는 신규 서비스개발이나 컨텐츠의 확충 시에 기존
              이용자들이 회사에 제공한 개인정보를 바탕으로 개발해야 할 서비스의
              우선 순위를 보다 더 효율적으로 정하고, V-SPACE는 이용자들이 필요로
              할 컨텐츠를 합리적으로 선택하여 제공할 수 있습니다.<br /><br /> 둘째, 수집하는
              개인정보 항목과 수집 및 이용목적은 다음과 같습니다. <br />- 성명 ,
              아이디, 비밀번호, 주민등록번호 : 회원제 서비스 이용에 따른 본인
              확인 절차에 이용 <br />- 이메일주소, 전화번호 : 고지사항 전달, 불만처리
              등을 위한 원활한 의사소통 경로의 확보, 새로운 서비스 및 신상품이나
              이벤트 정보 등의 안내 <br />- 은행계좌정보, 신용카드정보 : 서비스 및 부가
              서비스 이용에 대한 요금 결제 <br />- 주소, 전화번호 : 청구서, 물품배송시
              정확한 배송지의 확보<br /> - 기타 선택항목 : 개인맞춤 서비스를 제공하기
              위한 자료 <br />- IP Address, : 불량회원의 부정 이용 방지와 비인가 사용
              방지 <br /><br />
              2. 수집하는 개인정보 항목 및 수집방법<br/><br/> 
              V-SPACE는 이용자들이
              회원서비스를 이용하기 위해 회원으로 가입하실 때 서비스 제공을 위한
              필수적인 정보들을 온라인상에서 입력 받고 있습니다.<br /><br /> 회원 가입 시에
              받는 필수적인 정보는 이름, 주민등록번호, 이메일 주소 등입니다.<br />
              또한 양질의 서비스 제공을 위하여 이용자들이 선택적으로 입력할 수
              있는 사항으로서 전화번호 등을 입력 받고 있습니다. <br /><br />또한 V-SPACE
              홈페이지 내에서의 설문조사나 이벤트 행사 시 통계분석이나 경품제공
              등을 위해 선별적으로 개인정보 입력을 요청할 수 있습니다.<br /> 그러나,
              이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및
              민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록,
              건강상태 및 성생활 등)는 수집하지 않으며 부득이하게 수집해야 할
              경우 이용자들의 사전동의를 반드시 구할 것입니다. <br />그리고, 어떤
              경우에라도 입력하신 정보를 이용자들에게 사전에 밝힌 목적 이외에
              다른 목적으로는사용하지 않으며 외부로 유출하지 않습니다. <br /><br />3.
              수집하는 개인정보의 보유 및 이용기간 <br /><br />이용자가 V-SPACE 홈페이지
              회원으로서 회사에 제공하는 서비스를 이용하는 동안 V-SPACE는
              이용자들의 개인정보를 계속적으로 보유하며 서비스 제공 등을 위해
              이용합니다. <br />다만, 홈페이지 하단 개인정보 취급방침의 "6. 이용자
              자신의 개인정보관리(열람,정정,삭제 등)에 관한 사항" 에서 설명한
              절차와 방법에 따라 회원 본인이 직접 삭제하거나 수정한 정보,
              가입해지를 요청한 경우에는 재생할 수 없는 방법에 의하여
              디스크에서완전히 삭제하며 추후 열람이나 이용이 불가능한 상태로
              처리됩니다. <br /><br />그리고 "1. 수집하는 개인정보 항목 및 수집방법"에서와
              같이 일시적인 목적 (설문조사, 이벤트,본인확인 등)으로 입력 받은
              개인정보는 그 목적이 달성된 이후에는 동일한 방법으로 사후 재생이
              불가능한 상태로 처리됩니다.<br /><br /> 귀하의 개인정보는 다음과 같이
              개인정보의 수집목적 또는 제공받은 목적이 달성되면 파기하는 것을
              원칙으로 합니다. <br />다만, V-SPACE는 불량 회원의 부정한 이용의 재발을
              방지하기 위해, 이용계약 해지일로부터 1년간 해당 회원의
              주민등록번호를 보유할 수 있습니다. <br />그리고 상법, 전자상거래
              등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할
              필요가 있는 경우 V-SPACE는 관계법령에서 정한 일정한 기간 동안
              회원정보를 보관합니다. <br />이 경우 V-SPACE는 V-SPACE는 보관하는 정보를
              그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.<br /><br /> - 계약
              또는 청약철회 등에 관한 기록 : 5년 <br />- 대금결제 및 재화 등의 공급에
              관한 기록 : 5년<br /> - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년<br /><br />
              V-SPACE는 귀중한 회원의 개인정보를 안전하게 처리하며, 유출의
              방지를 위하여 다음과 같은 방법을통하여 개인정보를 파기합니다. <br /><br />-
              종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
              파기합니다. <br />- 전자적 파일 형태로 저장된 개인정보는 기록을 재생할
              수 없는 기술적 방법을 사용하여 삭제합니다
            </p>
           </div>
    </div>
  )
}

export default PrivacyPolicy
