import React, { useState } from "react";
import axiosInstance from "../../axios/axiosInstance";

const FindId = () => {
  const [findUsername, setFindUsername] = useState("");
  const [findEmail, setFindEmail] = useState("");
  const [findId, setFindId] = useState("");
  const [clickedTab, setClickedTab] = useState(0);
  const handleIDForm = async (e) => {
    e.preventDefault();
    await axiosInstance
      .get(
        `/users/find-user-id/${findEmail}/?name=${findUsername}`
      )
      .then((res) => {
        if (res.data.result === false) {
          window.alert("없는 이메일입니다. 다시 입력바랍니다.");
        } else if (res.data.result === true) {
          window.alert("이메일로 아이디를 전송하였습니다.");
        }
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };
  const handleSecretForm = async (e) => {
    e.preventDefault();
    await axiosInstance
    .post(
      `/users/reset-password/${findEmail}/${findId}/`, 
    )
    .then((res) => {
      if (res.data.valid === false) {
        window.alert("없는 이메일입니다. 다시 입력하시길 바랍니다.");
      } 
      else if (res.status === 200) {
        window.alert("임시비밀번호가 메일로 전송되었습니다.");
      }
    })
    .catch((error) => {
      alert(error.response.data.message);
    });
  };


  const selectMenuHandler = (index) => {
    setClickedTab(index);
  };
  return (
    <div className="findid-wrap pt--150 pb--60">
      <div className="findIdTab-wrap">
        <div className="findIdTab">
          <div className="findIdTitle">
            <div
              className={clickedTab === 0 ? "tabtitle active-tab" : "tabtitle"}
              onClick={() => selectMenuHandler(0)}
            >
              아이디 찾기
            </div>
            <div
              className={clickedTab === 1 ? "tabtitle active-tab" : "tabtitle"}
              onClick={() => selectMenuHandler(1)}
            >
              비밀번호 찾기
            </div>
            {/* <ul className='findIdtitle'>
              <li onClick={()=>{selectMenuHandler(0)}}><span>아이디찾기</span></li>
              <li onClick={()=>{selectMenuHandler(1)}}><span>비밀번호찾기</span></li>
            </ul> */}
          </div>
          <div
            className={
              clickedTab === 0
                ? "findIdContent active-content"
                : "findIdContent"
            }
          >
            <form method="post" onSubmit={handleIDForm}>
              <div className="login-control">
                <input
                  type="text"
                  value={findUsername}
                  onChange={(e) => setFindUsername(e.target.value)}
                  //onChange={handleId}
                  placeholder="성명"
                  required
                />
                {/* <div className="errorMessage">
                {
                !idEmailValid && id.length>0 && (
                  <div>이메일형식으로 입력해주세요</div>
                )
                }
              </div> */}
              </div>
              <div className="login-control">
                <input
                  type="email"
                  value={findEmail}
                  //onChange={handlePw}
                  onChange={(e) => setFindEmail(e.target.value)}
                  placeholder="이메일"
                  required
                />
              </div>
              <div className="text-center">
                <input type="submit" className="confirmbtn" value="확 인" />
              </div>
            </form>
          </div>
          <div
            className={
              clickedTab === 1
                ? "findIdContent active-content"
                : "findIdContent"
            }
          >
            <form method="post" onSubmit={handleSecretForm}>
              <div className="login-control">
                <input
                  type="text"
                  value={findId}
                  //onChange={handlePw}
                  onChange={(e) => setFindId(e.target.value)}
                  placeholder="아이디"
                  required
                />
              </div>

              <div className="login-control">
                <input
                  type="email"
                  value={findEmail}
                  //onChange={handlePw}
                  onChange={(e) => setFindEmail(e.target.value)}
                  placeholder="이메일"
                  required
                />
              </div>
              <div className="text-center">
                <input type="submit" className="confirmbtn" value="확 인" />
              </div>
            </form>
          </div>
        </div>
        <div className="findIdfooter">
        <h5>임시비밀번호 발송메일을 받지 못했다면?</h5>
        <p>1. daum(hanmail) 메일은 수신불가합니다.</p>
        <p>2. 구글(gmail) 메일은 스팸함에서 확인가능합니다.</p>
        <p>3. 대학교 메일은 보안문제로 수신불가할수 있습니다.</p>
        </div>
      </div>
    </div>
  );
};

export default FindId;
