import React from 'react'

const Email = () => {
  return (
    <div className='privacy-container  ptb--100'> 
    <div className='privacy plr--80 plr_sm--30 plr_md--30'>
        <h4>이메일무단수집거부</h4>
        <br/>
        <h5>:::: V-SPACE 이메일무단수집거부 ::::</h5>
        <br/>
        <p>
         『정보통신망 이용촉진 및 정보보호 등에 관한 법률』를 준수해주시기 바랍니다. 
         <br/><br/>
         본 홈페이지는 회원에게 무차별적으로 발송하는 타사의 메일을 차단하기 위해, 본 웹사이트에 게시된 이메일 주소가 전자우편 수집 프로그램이나 그 밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며, 이를 위반 시 정보통신망법에 의해 형사처벌됨을 유념하시기 바랍니다.
         <br/><br/>
         ▣ 정보통신망법 제50조의2 (전자우편주소의 무단 수집행위 등 금지) 
        <br/><br/>
        누구든지 전자우편주소의 수집을 거부하는 의사가 명시된 인터넷 홈페이지에서 자동으로 전자우편 주소를 수집하는 프로그램 그 밖의 기술적 장치를 이용하여 전자우편주소를 수집하여서는 아니 된다. 누구든지 제1항의 규정을 위한하여 수집된 전자우편주소를 판매ㆍ유통하여서는 아니 된다. 
        <br/><br/> 누구든지 제1항 및 제2항의 규정에 의하여 수집ㆍ판매 및 유통이 금지된 전자우편주소임을 알고 이를 정보전송에 이용하여서는 아니 된다.
        <br/><br/> ※ 만일, 위와 같은 기술적 장치를 사용한 이메일 주소 무단수집 피해를 당하신 경우 불법스팸대응센터 전용전화(1336)나 홈페이지(www.spamcop.or.kr)의 신고창을 통하여 신고하여 주시기 바랍니다.
        <br/><br/>
        </p>
         </div>
  </div>
  )
}

export default Email
