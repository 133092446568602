import React, { useEffect, useState } from "react";
import ReservationTable from "./ReservationTable";
import axiosInstance, { axiosFormInstance } from "../../../axios/axiosInstance";
import moment from "moment/moment";

const ReservationManage = () => {
  const [clickedTab, setClickedTab] = useState(0);
  const [facilityData, setFacilityData] = useState([]);
  const [EquipData, setEquipData] = useState([]);
  const [ToolData, setToolData] = useState([]);
  const [OnlineData, setOnlineData] = useState([]);
  const [EduData, setEduData] = useState([]);
  const [EventData, setEventData] = useState([]);
  const [mentoringData, setMentoringData] = useState([]);
  const [tourData, setTourData] = useState([]);
  const [file, setFile] = useState(null);
  const [reservedData, setReservedData] = useState(null);
  const [firstModal, setFirstModal] = useState(false);
  const [currentDate, setCurrentDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [selectedDataId, setSelectedDataId] = useState(0);

  const selectMenuHandler = (index) => {
    setClickedTab(index);
  };
  const getData = async (url, setData) => {
    await axiosInstance
      .get(`${url}`)
      .then((res) => setData(res.data))
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    refetch();
  }, []);
  const refetch = () => {
    getData("educations/reservations/", setEduData);
    getData("equips/reservations/", setEquipData);
    getData("mentorings/reservations/", setMentoringData);
    getData("tours/reservations/", setTourData);
    getData("facilities/rentals/", setFacilityData);
    getData("worktools/rentals/", setToolData);
    getData("eventedu/reservations/", setEventData);
    getData("equipedu/reservations/", setOnlineData);
  };
  const workHandler = async (id) => {
    const formData = new FormData();
    formData.append("project_post_image", file);
    formData.append("reservation_date", reservedData);

    await axiosFormInstance
      .put(`/equips/reservations/${id}/`, formData)
      .then((res) => {
        if (res.status === 200) {
          alert("등록되었습니다");
          setFirstModal(false);
          setFile(null);
          window.location.reload();
        }
      })
      .catch((err) => {
        if (err) {
          alert("등록되지 않았습니다. 관리자에게 문의하시길바랍니다");
        }
      });
  };

  const extendDay = async (id) => {
    await axiosInstance
      .put(`/worktools/rentals/${id}/`, { state: "연장요청" })
      .then((res) => {
        if (res.status === 200) {
          alert("연장요청되었습니다.");
          window.location.reload();
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <div className="d-flex flex-wrap text-center ">
        <div
          className={
            clickedTab === 0 ? "reservaton-tab active-tab" : "reservaton-tab"
          }
          onClick={() => selectMenuHandler(0)}
        >
          <p>장비</p>
        </div>
        <div
          className={
            clickedTab === 1 ? "reservaton-tab active-tab" : "reservaton-tab"
          }
          onClick={() => selectMenuHandler(1)}
        >
          시설
        </div>
        <div
          className={
            clickedTab === 2 ? "reservaton-tab active-tab" : "reservaton-tab"
          }
          onClick={() => selectMenuHandler(2)}
        >
          공구
        </div>
        {/* <div
          className={
            clickedTab === 3 ? "reservaton-tab active-tab" : "reservaton-tab"
          }
          onClick={() => selectMenuHandler(3)}
        >
          온라인 장비교육
        </div> */}
        <div
          className={
            clickedTab === 4 ? "reservaton-tab active-tab" : "reservaton-tab"
          }
          onClick={() => selectMenuHandler(4)}
        >
          교육프로그램
        </div>
        <div
          className={
            clickedTab === 5 ? "reservaton-tab active-tab" : "reservaton-tab"
          }
          onClick={() => selectMenuHandler(5)}
        >
          행사 참가
        </div>
        <div
          className={
            clickedTab === 6 ? "reservaton-tab active-tab" : "reservaton-tab"
          }
          onClick={() => selectMenuHandler(6)}
        >
          멘토링 및 제작 지원
        </div>
        <div
          className={
            clickedTab === 7 ? "reservaton-tab active-tab" : "reservaton-tab"
          }
          onClick={() => selectMenuHandler(7)}
        >
          투어
        </div>
      </div>
      <ReservationTable
        clickedTab={clickedTab}
        num={0}
        titlename="장비"
        datas={EquipData}
        onDelete={() => refetch()}
        setFile={setFile}
        workHandler={workHandler}
        setReservedData={setReservedData}
        firstModal={firstModal}
        setFirstModal={setFirstModal}
        currentDate={currentDate}
        setSelectedDataId={setSelectedDataId}
        selectedDataId={selectedDataId}
      />
      <ReservationTable
        clickedTab={clickedTab}
        num={1}
        titlename="시설"
        datas={facilityData}
        onDelete={() => refetch()}
        setFile={setFile}
        workHandler={workHandler}
        setReservedData={setReservedData}
        firstModal={firstModal}
        setFirstModal={setFirstModal}
        currentDate={currentDate}
        setSelectedDataId={setSelectedDataId}
        selectedDataId={selectedDataId}
      />
      <ReservationTable
        clickedTab={clickedTab}
        num={2}
        titlename="공구"
        datas={ToolData}
        onDelete={() => refetch()}
        setFile={setFile}
        extendDay={extendDay}
        currentDate={currentDate}
      />
      {/* <ReservationTable
        clickedTab={clickedTab}
        num={3}
        titlename="온라인장비교육"
        datas={OnlineData}
        onDelete={() => refetch()}
        setFile={setFile}
        currentDate={currentDate}
        setSelectedDataId={setSelectedDataId}
        selectedDataId={selectedDataId}
      /> */}
      <ReservationTable
        clickedTab={clickedTab}
        num={4}
        titlename="교육프로그램"
        datas={EduData}
        onDelete={() => refetch()}
        setFile={setFile}
        currentDate={currentDate}
        setSelectedDataId={setSelectedDataId}
        selectedDataId={selectedDataId}
      />
      <ReservationTable
        clickedTab={clickedTab}
        num={5}
        titlename="행사"
        datas={EventData}
        onDelete={() => refetch()}
        setFile={setFile}
        currentDate={currentDate}
        setSelectedDataId={setSelectedDataId}
        selectedDataId={selectedDataId}
      />
      <ReservationTable
        clickedTab={clickedTab}
        num={6}
        titlename="멘토링"
        datas={mentoringData}
        onDelete={() => refetch()}
        setFile={setFile}
        currentDate={currentDate}
        setSelectedDataId={setSelectedDataId}
        selectedDataId={selectedDataId}
      />
      <ReservationTable
        clickedTab={clickedTab}
        num={7}
        titlename="투어"
        datas={tourData}
        onDelete={() => refetch()}
        setFile={setFile}
        currentDate={currentDate}
        setSelectedDataId={setSelectedDataId}
        selectedDataId={selectedDataId}
      />
    </>
  );
};

export default ReservationManage;
