import React, { Component } from "react";
import { Link } from 'react-router-dom';
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
const logoUrl = <img src="/assets/images/logo/logo-light.png" alt="Digital Agency" />;

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/'},
]

class Footer extends Component{
    render(){
        return(
            <React.Fragment>
                <footer className="footer-area footer-style-01 bg_color--6">
                    {/* Start Call to Action Area  */} 
                    <div className="im-call-to-action-area ptb--20">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt--20 ">
                                    <div className="inner text-white">
                                       <h4 className="text-white">부산대학교</h4>
                                       <p className="mb--0 text-white">부산광역시 금정구 부산대학로 63번길 2 부산대학교 기계관(303동) 208호 V-SPACE</p> 
                                         <span style={{marginLeft: 0}}>Tel. 051-510-3261</span> <span>Fax. 051-510-0787</span> <span>Email.<a href="mailto:pnuvspace@gmail.com"> pnuvspace@gmail.com</a></span>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt--25 mb--10">
                                    <div className="inner text-white text-center ">
                                       <span><Link to="/privacy">개인정보처리방침</Link></span> | <span><Link to="/emailPrivacy">이메일무단수집거부</Link></span> | <span><a href="mailto:pnuvspace@gmail.com">CONTACT</a></span>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                <p className="text-white"><Link to="/">PNU V-Space</Link> © COPYRIGHT 2023</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;