import React, { Component, useEffect, useState } from "react";
import PageHelmet from "../../component/common/Helmet";
import { FiClock, FiUser, FiMessageCircle, FiHeart } from "react-icons/fi";
import { Link, useLocation, useParams } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp, FiCalendar, FiCheckSquare } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../axios/axiosInstance";
import { useGetDatas } from "../../hooks/useApi";


const SpaceConfirm = () => {
  const [Check, setCheck] = useState(false);
  const navigate = useNavigate();
  const [guidCheck, setGuidCheck] = useState(false);
  const {id} = useParams();
  const data = useGetDatas('/facilities/rentals', id);
  //   const confirmSubmit = async (e) => {
  //   e.preventDefault();
  //   if (!Check) {
  //     window.alert("개인정보 수집 이용에 동의해 주시길 바랍니다.");
  //   } else if(!guidCheck){
  //     window.alert("안내사항에 동의해 주시길 바랍니다.");
  //    }else{
  //     navigate(`/space`)
  //    }
  // };
  const handleNavigate = () =>{
    navigate("/space")
  }

  console.log(data)
  return (
    <React.Fragment>

      <PageHelmet pageTitle="Equipments Details" />


      {/* Start Equipments Details */}
      <div className="rn-blog-details pt--100 pb--20 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  <div className="blog-single-list-wrapper">
                    <div className="row">
                      <div className="col-lg-12 col-sm-12 content">
                        <h4 className="title">V-SPACE 시설 예약</h4>
                        <ul className="list-style">
                          <li>시설 : {data?.facility?.name}</li>
                          <li>예약 날짜 : {data?.rental_date} </li>
                          <li>예약 시간 : {data?.rental_time?.start_time?.slice(0,5)}~ {data?.rental_time?.end_time?.slice(0,5)}</li>
                        </ul>
                        {/* <h4 className="title">V-SPACE 시설 안내</h4> */}
                      {/* <form method="post" onSubmit={confirmSubmit}> */}
                        <div className="section-title">
                          {/* <div className="d-flex align-items-center"> */}
                            <h5>개인정보 수집 이용 동의</h5>
                            {/* <div className="ml--10">
                              <input
                                type="checkbox"
                                className="chk"
                                onChange={(e) => setCheck(e.target.checked)}
                              />          </div> */}
                          {/* </div> */}
                          <p className="description">
                            - 항목 : 성명, 소속, 직위, 휴대전화, 작업내용, 이메일
                            <br />
                            - 개인 정보 제공 기관 :  창업진흥원, 부산대학교 산학협력단 
                            <br/>- 수집목적 : 프로그램 신청 및 운영, 사업보고 <br />-
                            보유기간 : 사업종료 후 5년
                          </p>
                          {/* <div className="d-flex align-items-center">
                            <h5>사진촬영 및 이용 동의</h5>
                            <div className="ml--10">
                              <input
                                type="checkbox"
                                className="chk"
                                onChange={(e) => setPhotoCheck(e.target.checked)}
                              />
                            </div>
                          </div> */}

                          {/* <div className="d-flex align-items-center"> */}
                            <h5>안내사항</h5>
                            {/* <div className="ml--10">
                            <input
                                type="checkbox"
                                className="chk"
                                onChange={(e) => setGuidCheck
                                  (e.target.checked)}
                              /> 
                            </div>
                          </div> */}
                          <p className="description">
                            - 홈페이지를 통해 사용승인 결과를 확인 후 사용이 가능합니다. <br/>
                            - 사용승인 확인 이후에도 관리자 판단하에 예약이 취소 될 수 있습니다.<br/>
                            - 공간에 비치된 장비가 필요할 시 현장에서 대여 후 사용하여야 합니다.<br/>
                            - 이용자 부주의로 인한 시설 및 장비 파손 시 원상복구하여야 합니다.<br/>
                          </p>
                          <h5>예약확인 및 취소</h5>
                          <p className="description">
                           - 마이페이지에서 예약확인 및 취소 가능합니다.
                          </p>
                        </div>
                        <div className="blog-btn d-flex justify-content-end mt-30">
                            <button className="btn-default" onClick={handleNavigate}>
                              확인하기
                            </button>
                          </div>
                       {/* </form> */}
                      </div>
                    </div>
                  </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Blog Details */}
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </React.Fragment>
  );
};

export default SpaceConfirm;
