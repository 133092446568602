import React, { useEffect, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiCheck } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import useResize from "../hooks/useResize";
import axiosInstance from "../axios/axiosInstance";
import { isLoginSelector } from "../recoil/User";
import { useRecoilValue } from "recoil";
import { usePostDatas } from "../hooks/useApi";
import BreadcrumbGray from "./common/BreadcrumbGray";
import ScrollTop from "./common/ScrollTop";
import moment from "moment";
import Breadcrumb from "./common/Breadcrumb";

const TourDetails = () => {
  const [check, setCheck] = useState(false);
  const [guidCheck, setGuidCheck] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [purposeOption, setPurposeOption] = useState("장비 및 공간활용");
  const [interestOption, setInterestOption] = useState("메이커스페이스 공간");
  const [tour, setTour] = useState([]);
  const [timeTables, setTimeTables] = useState([]);
  const [number, setNumber] = useState("");
  const [tourPurpose, setTourPurpose] = useState("");
  const [inquiry, setInquiry] = useState("");
  const [otherInterest, setOtherInterest] = useState("");
  const isLogin = useRecoilValue(isLoginSelector);
  const navigate = useNavigate();
  const { date } = useParams();
  const windowWidth = useResize();
  const postDatas = usePostDatas("/tours/reservation/", `/tour-confirm`, "tour");
  const handleTimeClick = (time) => {
    setSelectedTime(time);
  };
  console.log(timeTables);
  const handleInterest = (e) => {
    setInterestOption(e.target.value);
  };
  const handlePurpose = (e) => {
    setPurposeOption(e.target.value);
  };

  const chkHandler = async (e) => {
    e.preventDefault();
    if (!isLogin) {
      window.alert("로그인이 필요합니다");
      window.location = "/login";
      return;
    }
    if (selectedTime === null || selectedTime === "") {
      alert("시간을 선택해주시길 바랍니다.");
      return;
    }
    if (number.trim() == "") {
      alert("참여인원을 입력해 주시길 바랍니다.");
      return;
    }
    if (!check) {
      alert("개인정보수집이용에 동의해주실 바랍니다.");
      return null;
    } else if (!guidCheck) {
      alert("안내사항에 동의해주실 바랍니다.");
    } else {
      const Purpose = purposeOption === "기타" ? tourPurpose : purposeOption;
      const TargetValue = interestOption === "기타" ? otherInterest : interestOption;

      const info = {
        tour_id: 1,
        reservation_date: date,
        reservation_time_id: selectedTime.id,
        num_people: number,
        target_field: TargetValue,
        purpose: Purpose,
        inquiry_content: inquiry,
      };
      await postDatas(info);
    }
  };
  const tourList = async () => {
    let res = await axiosInstance.get(`/tours/items/1`);
    setTour(res.data);
    // setTimeTables(res.data.time_table);
    res = await axiosInstance.get(`/tours/time-table/?tour_id=${1}&reservation_date=${moment(date).format("YYYY-MM-DD")}`);
    setTimeTables(res.data.time_tables);
  };

  useEffect(() => {
    if (date) tourList();
  }, [date]);

  return (
    <React.Fragment>
      <PageHelmet pageTitle="Tour Details" />
      <Breadcrumb title="V-SPACE 투어" />

      {/* Start Portfolio Details */}
      <div className="rn-portfolio-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <form method="post" onSubmit={chkHandler}>
                <div className="portfolio-details">
                  <div className="row row--35 mb--50">
                    <div className="col-lg-6">
                      <div className="inner">
                        <div className="section-title">
                          <h2 className="title">V-SPACE 투어 신청하기</h2>
                          <ul className="list-style--1 mt--30">
                            <li>
                              <FiCheck />
                              예약일자 : {date}
                            </li>
                            <li>
                              <FiCheck />
                              예약시간 : <br />
                              <div className="mt--10 plr--10">
                                {timeTables.map((time, key) => {
                                  return (
                                    <button
                                      className={`time-btn 
                                    ${time.is_reserved ? "disabled" : ""}
                                    ${!time.is_reserved && selectedTime === time ? "selected" : ""}`}
                                      style={time.is_reserved ? { cursor: "not-allowed", background: "#ddd" } : { cursor: "pointer" }}
                                      key={key}
                                      type="button"
                                      onClick={() => {
                                        if (!time.is_reserved) handleTimeClick(time);
                                      }}
                                    >
                                      {time.start_time.slice(0, 5)}~{time.end_time.slice(0, 5)}
                                    </button>
                                  );
                                })}
                              </div>
                            </li>
                            <li>
                              <FiCheck />
                              투어목적 :
                              <select value={purposeOption} onChange={handlePurpose}>
                                <option value="장비 및 공간활용">장비 및 공간활용</option>
                                <option value="견학">견학</option>
                                <option value="메이커스페이스 동향 파악">메이커스페이스 동향 파악</option>
                                <option value="기타">기타</option>
                              </select>
                              {purposeOption === "기타" ? <input type="text" onChange={(e) => setTourPurpose(e.target.value)} className="others-input" /> : <></>}
                            </li>
                            <li>
                              <FiCheck />
                              참여인원 :
                              <input type="text" onChange={(e) => setNumber(e.target.value)} className="member-input" />명
                            </li>
                            <li>
                              <FiCheck />
                              주요관심사 :
                              <select value={interestOption} onChange={handleInterest}>
                                <option value="메이커스페이스 공간">메이커스페이스 공간</option>
                                <option value="3D프린터">3D프린터</option>
                                <option value="레이저조각기">레이저조각기</option>
                                <option value="납땜시설">납땜시설</option>
                                <option value="CNC">CNC</option>
                                <option value="메탈실">메탈실</option>
                                <option value="스튜디오">스튜디오</option>
                                <option value="후가공실">후가공실</option>
                                <option value="목공실">목공실</option>
                                <option value="기타">기타</option>
                              </select>
                              {interestOption == "기타" ? <input type="text" onChange={(e) => setOtherInterest(e.target.value)} className="others-input" /> : <></>}
                            </li>
                            <li>
                              <FiCheck />
                              문의 또는 요청 사항 (내용이 있을 경우 기입)
                              <textarea className="mt--10 plr--10" rows={4} cols={50} onChange={(e) => setInquiry(e.target.value)}></textarea>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="portfolio-details-accordion mt--100 mt_md--40 mt_sm--40">
                        <div className="inner">
                          <div className="section-title">
                            <div className="d-flex align-items-center">
                              <h5>개인정보 수집 이용 동의</h5>
                              <div className="ml--10">
                                <input type="checkbox" className="chk" onChange={(e) => setCheck(e.target.checked)} />
                              </div>
                            </div>
                            <p className="description">
                              - 항목 : 성명, 소속, 직위, 휴대전화, 작업내용, 이메일
                              <br />
                              - 개인 정보 제공 기관 : 창업진흥원, 부산대학교 산학협력단
                              <br />- 수집목적 : 프로그램 신청 및 운영, 사업보고 보유기간 : 사업종료 후 5년
                            </p>
                            {/* <div className="d-flex align-items-center">
                          <h5>사진촬영 및 이용 동의</h5>
                          <div className="ml--10">
                            <input
                              type="checkbox"
                              className="chk"
                              onChange={(e) => setPhotoCheck(e.target.checked)}
                            />
                          </div>
                        </div>
                        <p className="description">
                          교육 중 사진촬영 및 이용(홍보, 증빙자료 등)에 대해
                          동의합니다
                        </p> */}

                            <div className="d-flex align-items-center">
                              <h5>안내사항 동의</h5>
                              <div className="ml--10">
                                <input type="checkbox" className="chk" onChange={(e) => setGuidCheck(e.target.checked)} />
                              </div>
                            </div>
                            <p className="description">
                              - 홈페이지를 통해 해당 예약건 확인 후 담당자가 연락을 드릴 수 있습니다. <br />
                              - 이용자 부주의로 인한 시설 및 장비 파손 시 원상복구하여야 합니다.
                              <br />
                              - 직원의 안내에 따르지 않아 발생한 사고에 대해서는 책임을 지지 않습니다.
                              <br />
                              - 교내 무료 주차는 지원되지 않으며, 주차규정은 부산대학교 주차관리규정을 따릅니다.
                              <br />
                            </p>
                          </div>
                        </div>
                        <div className="portfolio-details-btn mt--30 d-flex  justify-content-end">
                          <button className="btn-default btn-border" type="submit">
                            신청하기
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Details */}

      {/* Start Back To Top */}
      <ScrollTop />
      {/* End Back To Top */}
    </React.Fragment>
  );
};

export default TourDetails;
