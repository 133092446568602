import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FiCalendar } from "react-icons/fi";
import axiosInstance from "../axios/axiosInstance";
import useResize from "../hooks/useResize";
import Daypicker from "./datePicker/Daypicker";
import { usePostDatas } from "../hooks/useApi";
import { useRecoilValue } from "recoil";
import { isLoginSelector } from "../recoil/User";
import moment from "moment/moment";
import ScrollTop from "./common/ScrollTop";

const SpaceDetail = () => {
  const [privateCheck, setPrivateCheck] = useState(false);
  const [Space, setSpace] = useState([]);
  const [selectedOption, setSelectedOption] = useState("교육");
  const [timeTables, setTimeTables] = useState([]);
  const [selectedTimeTable, setSelectedTimeTable] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [otherPurpose, setOtherPurpose] = useState("");
  const [member, setMember] = useState(1);
  const [title, setTitle] = useState("");
  const [host, setHost] = useState("");
  const [description, setDescription] = useState("");
  const { id } = useParams();
  const windowWidth = useResize();
  const postDatas = usePostDatas("/facilities/rental/", `/space-confirm`, "facility");
  const isLogin = useRecoilValue(isLoginSelector);
  const spaceSubmit = async (e) => {
    e.preventDefault();
    const spacePurpose = selectedOption === "기타" ? otherPurpose : selectedOption;
    if (!isLogin) {
      window.alert("로그인이 필요합니다");
      window.location = "/login";
      return;
    }
    if (title.trim() === "") {
      window.alert("교육/행사명을 입력해주시길 바랍니다");
      return;
    }
    if (host.trim() === "") {
      window.alert("주관을 입력해주시길 바랍니다");
      return;
    }
    if (description.trim() === "") {
      window.alert("세부내용을 입력해주시길 바랍니다");
      return;
    }
    if (startDate === null || startDate === "") {
      window.alert("날짜를 선택해주시길 바랍니다");
      return;
    }
    if (selectedTimeTable === null || selectedTimeTable === "") {
      window.alert("시간을 선택해주시길 바랍니다");
      return;
    }
    if (!privateCheck) {
      window.alert("개인정보 수집 이용에 동의해 주시길 바랍니다.");
      return;
    }
    const info = {
      facility_id: id,
      rental_date: moment(startDate).format("YYYY-MM-DD"),
      rental_time_id: selectedTimeTable.id,
      purpose: spacePurpose,
      num_users: member,
      title: title,
      descript: description,
      host: host,
    };

    await postDatas(info);
    //handleShow();
    // if (!Check) {
    //   alert("장비대여 필수 동영상을 보셨나요?.");
    // } else {
    //  navigate("/checkout");
    // }
  };

  // const EquipDetail = async () => {
  //   await axiosInstance.get(`/equips/items/${equipId}`).then((res) => {
  //     setEquip(res.data);
  //   });
  // };
  const EquipEducationList = async () => {
    await axiosInstance.get(`/facilities/items/${id}`).then((res) => {
      setSpace(res.data);
      setTimeTables(res.data.time_table);
    });
  };
  useEffect(() => {
    // EquipDetail();
    EquipEducationList();
  }, []);

  console.log(timeTables);
  const handleOption = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleTimeTableClick = (timetable) => {
    setSelectedTimeTable(timetable);
  };

  return (
    <React.Fragment>
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="text-center">
            시설 예약 전 사용목적을 작성해주시길 바랍니다
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* Start Equipments Details */}
      <div className="rn-blog-details ptb--70 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  <div className="blog-single-list-wrapper">
                    
                     {Space.image && Space.image.length>0 ? <div className="thumbnail w-100 text-center col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center pb--60 mt--20">
                     <div className="row">
                        <img
                          src={Space.image}
                          alt="Eq Images"
                          // className="img-fluid"
                        />
                        {/* <span>파우더를 이용한 3D프린터</span> */}
                      </div> </div>:null}
                   

                    <div className="row">
                      <div className="col-lg-12 col-sm-12 content mb--20 mt--35">
                        <h4 className="title">{Space.name}</h4>
                        <ul className="list-style">
                          <li>시설 설명</li>
                          <div
                            style={{
                              whiteSpace: "pre-wrap",
                              paddingLeft: 10,
                              marginTop: 10,
                            }}
                          >
                            {Space.descript}
                          </div>
                        </ul>
                        <h4 className="title">안내사항</h4>
                        <p className="description">
                          - 홈페이지를 통해 사용승인 결과를 확인 후 사용이 가능합니다. <br />
                          - 사용승인 확인 이후에도 관리자 판단하에 예약이 취소 될 수 있습니다.
                          <br />
                          - 공간에 비치된 장비가 필요할 시 현장에서 대여 후 사용하여야 합니다.
                          <br />
                          - 이용자 부주의로 인한 시설 및 장비 파손 시 원상복구하여야 합니다.
                          <br />
                        </p>
                        <h4 className="title">
                          V-SPACE 시설예약
                          <FiCalendar size={25} style={{ marginBottom: 3, marginLeft: 8, color: "gray" }} />
                        </h4>

                        {/* {windowWidth > 1000 ? (
                          <form method="post" onSubmit={spaceSubmit}>
                          <table className="space-table">
                            <thead>
                              <tr>
                                <td>교육목적</td>
                                <td className="secondTd">
                                  <select
                                    value={selectedOption}
                                    onChange={handleOption}
                                  >
                                    <option value="교육">교육</option>
                                    <option value="제작">제작</option>
                                    <option value="행사">행사</option>
                                    <option value="기타">기타</option>
                                  </select>
                                </td>
                                {selectedOption === "기타" ? (
                                  <td>
                                    <input
                                      type="text"
                                      onChange={(e) =>
                                        setOtherPurpose(e.target.value)
                                      }
                                    />
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>사용인원</td>
                                <td className="d-flex">
                                  <input
                                    type="text"
                                    value={member}
                                    onChange={(e) => setMember(e.target.value)}
                                  />
                                  <label className="ml--10">명</label>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td rowSpan={3}>사용목적</td>
                                <td>교육/행사명</td>
                                <td>
                                  <input
                                    type="text"
                                    onChange={(e) => setTitle(e.target.value)}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>주관</td>
                                <td>
                                  <input
                                    type="text"
                                    onChange={(e) => setHost(e.target.value)}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  세부내용
                                  <br />
                                  (상세히 작성)
                                </td>
                                <td>
                                  <textarea
                                    row={4}
                                    cols={50}
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>예약날짜</td>
                                <td>
                                    <Daypicker
                                      startDate={startDate}
                                      onChange={(date) => {
                                        setStartDate(date);
                                        setSelectedTimeTable(null);
                                        if (!date) {
                                          setTimeTables([]);
                                        }
                                        if (isLogin && date) {
                                          axiosInstance
                                            .get(
                                              `/facilities/reserved-time/?facility_id=${id}&rental_date=${moment(
                                                date
                                              ).format("YYYY-MM-DD")}`
                                            )
                                            .then((res) => {
                                              console.log(res);
                                              setTimeTables(
                                                res.data.time_tables
                                              );
                                            })
                                            .catch((error) => {
                                              console.log(error);
                                            });
                                        }
                                      }}
                                    />
                                </td>
                              </tr>
                              <tr>
                                <td>예약시간</td>
                                <td colSpan={3}>  
                                  <div className="d-flex flex-wrap">
                                  {timeTables.map((timetable, index) => {
                                        return (
                                          <button
                                            className={`time-btn 
                                    ${timetable.is_reserved ? "disabled" : ""}
                                    ${
                                      !timetable.is_reserved &&
                                      selectedTimeTable === timetable
                                        ? "selected"
                                        : ""
                                    }`}
                                            key={index}
                                            onClick={() => {
                                              if (timetable.is_reserved) {
                                                window.alert(
                                                  "이미 예약된 시간입니다."
                                                );
                                              } else {
                                                handleTimeTableClick(timetable);
                                              }
                                            }}
                                            type="button"
                                          >
                                            {timetable.start_time.slice(0, 5)}~
                                            {timetable.end_time.slice(0, 5)}
                                          </button>
                                        );
                                      })}
                                  </div>
                                  </td>
                              </tr>
                            </tbody>
                          </table>
                           <div className="blog-btn d-flex justify-content-end mt--30">
                           <button className="btn-default" type="submit">
                             예약하기
                           </button>
                         </div>
                         </form>

                        ) : ( */}
                        <form method="post" onSubmit={spaceSubmit}>
                          <table className="space-sm-table">
                            <thead>
                              <tr>
                                <td>사용목적</td>
                                <td>
                                  <select value={selectedOption} onChange={handleOption}>
                                    <option value="교육">교육</option>
                                    <option value="제작">제작</option>
                                    <option value="행사">행사</option>
                                    <option value="기타">기타</option>
                                  </select>
                                </td>
                                {selectedOption === "기타" ? (
                                  <td>
                                    <input type="text" onChange={(e) => setOtherPurpose(e.target.value)} />
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>사용인원</td>
                                <td className="d-flex" colSpan={2}>
                                  <input type="text" onChange={(e) => setMember(e.target.value)} value={member} />
                                  <label className="ml--10">명</label>
                                </td>
                              </tr>
                              {/* <tr>
                                <td colSpan={3} className="text-center">시설 사용 목적</td>
                              </tr> */}
                              <tr>
                                <td>교육/행사명</td>
                                <td colSpan={2}>
                                  <input type="text" onChange={(e) => setTitle(e.target.value)} placeholder="필수입력" />
                                </td>
                              </tr>
                              <tr>
                                <td>주관</td>
                                <td colSpan={2}>
                                  <input type="text" onChange={(e) => setHost(e.target.value)} placeholder="필수입력" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  세부내용
                                  <br />
                                  (상세히 작성)
                                </td>
                                <td colSpan={2}>
                                  <textarea row={4} cols={50} onChange={(e) => setDescription(e.target.value)} placeholder="필수입력" />
                                </td>
                              </tr>
                              <tr>
                                <td>예약날짜</td>
                                <td>
                                  <Daypicker
                                    startDate={startDate}
                                    onChange={(date) => {
                                      setStartDate(date);
                                      setSelectedTimeTable(null);
                                      if (!date) {
                                        setTimeTables([]);
                                      }
                                      if (isLogin && date) {
                                        axiosInstance
                                          .get(`/facilities/reserved-time/?facility_id=${id}&rental_date=${moment(date).format("YYYY-MM-DD")}`)
                                          .then((res) => {
                                            console.log(res);
                                            setTimeTables(res.data.time_tables);
                                          })
                                          .catch((error) => {
                                            console.log(error);
                                          });
                                      }
                                    }}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>예약시간</td>
                                <td colSpan={3}>
                                  <div className="d-flex flex-wrap">
                                    {timeTables.map((timetable, index) => {
                                      return (
                                        <button
                                          className={`time-btn 
                                    ${timetable.is_reserved ? "disabled" : ""}
                                    ${!timetable.is_reserved && selectedTimeTable === timetable ? "selected" : ""}`}
                                          key={index}
                                          onClick={() => {
                                            if (timetable.is_reserved) {
                                              window.alert("이미 예약된 시간입니다.");
                                            } else {
                                              handleTimeTableClick(timetable);
                                            }
                                          }}
                                          type="button"
                                        >
                                          {timetable.start_time.slice(0, 5)}~{timetable.end_time.slice(0, 5)}
                                        </button>
                                      );
                                    })}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="d-flex align-items-center">
                            <h4 className="title mt--30">개인정보 수집 이용 동의</h4>
                          </div>
                          <p className="description mb--0">
                            - 항목 : 성명, 소속, 직위, 휴대전화, 작업내용, 이메일
                            <br />
                            - 개인 정보 제공 기관 : 창업진흥원, 부산대학교 산학협력단
                            <br />- 수집목적 : 프로그램 신청 및 운영, 사업보고
                            <br />- 보유기간 : 사업종료 후 5년
                          </p>
                          <div className="ml--10">
                            <span style={{ fontWeight: 700 }}>동의하기</span>
                            <input type="checkbox" className="chk" onChange={(e) => setPrivateCheck(e.target.checked)} style={{ position: "relative", top: "13px", left: "10px" }} />
                          </div>
                          <div className="blog-btn d-flex justify-content-end mt--30">
                            <button className="btn-default" type="submit">
                              예약하기
                            </button>
                          </div>
                        </form>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Blog Details */}

      {/* Start Back To Top */}
      <ScrollTop />
      {/* End Back To Top */}
    </React.Fragment>
  );
};

export default SpaceDetail;
