import React, { Component } from "react";
import { Link } from "react-router-dom";

class Breadcrumb extends Component {
  render() {
    const { title, parent, subtitle } = this.props;
    return (
      <React.Fragment>
        <div className="breadcrumb-area rn-bg-color ptb--85 pb_md--80 pb_sm--80 bg_image bg_image--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-inner pt--100">
                  <h2 className="title" style={{ fontFamily: "font-EB", fontWeight: 900 }}>
                    {title}
                  </h2>
                  <span>{subtitle}</span>
                  {/* <ul className="page-list">
                                     <li className="rn-breadcrumb-item active">{subtitle}</li> */}
                  {/* <li className="rn-breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}`}>Home</Link></li>
                                        {parent? <li className="rn-breadcrumb-item">{parent}</li>:''}
                                        <li className="rn-breadcrumb-item active">{title}</li> */}
                  {/* </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Breadcrumb;
