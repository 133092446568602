import React from 'react'

const Checkout = () => {
  return (
    <div className='pt--300 pb--200 text-center'>
     장비가 예약되었습니다.
    </div>
  )
}

export default Checkout
