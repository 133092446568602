import React, { Component } from "react";

class Brand extends Component {
  render() {
    const { branstyle } = this.props;

    const liStyle = {
      width: "20%",
      margin: "15px 10px",
      textAlign: "center",
    };

    const imgStyle = {
      display: "block",
      margin: "0 auto",
      width: "90%",
    };

    return (
      <React.Fragment>
        <ul className={`brand-list ${branstyle}`}>
          {/* <li>
            <img src="/assets/images/logo/busanMELogo.png" alt="부산대기계공학부" />
          </li>
          <li>
            <img src="/assets/images/logo/MinistryOfSME.png" alt="중소벤처기업부" />
          </li>
          <li>
            <img src="/assets/images/logo/busan.png" alt="부산광역시" />
          </li> */}
          <li style={liStyle}>
            {/*부산대 기계공학부 로고 원형이라 비율 안맞는 부분 확인부탁드립니다. */}
            <img src="/assets/images/logo/logo_1.png" alt="부산대기계공학부" style={{ display: "block", margin: "0 auto", width: "50%" }} />
            {/* <img src="/assets/images/logo/busanMELogo.png" alt="부산대기계공학부" /> */}
          </li>
          <li style={liStyle}>
            <img src="/assets/images/logo/logo_2.png" alt="부산광역시교육청" style={imgStyle} />
          </li>
          <li style={liStyle}>
            <img src="/assets/images/logo/logo_3.png" alt="부산광역시" style={imgStyle} />
          </li>
          <li style={liStyle}>
            <img src="/assets/images/logo/logo_4.png" alt="부산대학교" style={imgStyle} />
          </li>

          <li style={liStyle}>
            <img src="/assets/images/logo/logo_5.png" alt="창업진흥원" style={imgStyle} />
          </li>
          <li style={liStyle}>
            <img src="/assets/images/logo/logo_6.png" alt="LG전자" style={imgStyle} />
          </li>
          <li style={liStyle}>
            <img src="/assets/images/logo/logo_7.png" alt="중소벤처기업부" style={imgStyle} />
          </li>
          {/* <li style={liStyle}>
            <img src="/assets/images/logo/logo_8.png" alt="v-space" style={imgStyle} />
          </li> */}
        </ul>
      </React.Fragment>
    );
  }
}
export default Brand;
